import React from "react";

import GuestFeedback from "./guest_feedback";
import Reply from "./reply";

function Regular({ review }) {
  return (
    <div>
      <GuestFeedback review={review} />
      <Reply review={review} />
    </div>
  );
}

export default Regular;
