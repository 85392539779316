import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

import Flexbox from "components/flexbox";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import {
  AIRBNB_NOTIFICATION,
  ALTERATION_REQUEST_TYPE,
  getIsEventClickable,
  INQUIRY_REQUEST_TYPE,
  NEW_MESSAGE_EVENT_TYPE,
  RESERVATION_REQUEST_TYPE,
  SYNC_ERROR_EVENT_TYPE,
} from "./common";
import EventsListItem from "./events_list_item";
import EventsListItemAirbnbEvent from "./events_list_item_airbnb_event";
import EventsListItemAlterationRequests from "./events_list_item_alteration_request";
import EventsListItemInquiry from "./events_list_item_inquiry";
import EventsListItemNewMessage from "./events_list_item_new_message";
import EventsListItemReservationRequests from "./events_list_item_reservation_request";
import EventsListItemSyncError from "./events_list_item_sync_error";

function EventsListTimeLineItem({ attributes }) {
  const navigate = useNavigate();
  const { event } = attributes;
  const { userAppRoutes } = useRouting();

  const onClickItem = () => {
    const isClickableItem = getIsEventClickable(attributes?.event);
    const bookingId = attributes?.payload?.booking_id;
    const logOrEventId = attributes?.payload?.log_id || attributes?.payload?.channel_event_id;
    const liveFeedEventId = attributes?.id;
    const messageThreadId = attributes?.payload?.message_thread_id;

    if (!isClickableItem) {
      return null;
    }

    if (attributes.event === AIRBNB_NOTIFICATION) {
      return window.open(attributes.payload.cta_url, "_blank");
    }

    if (attributes.event === ALTERATION_REQUEST_TYPE) {
      return navigate(
        pathBuilder(userAppRoutes.dashboard.liveFeedEventView, { eventId: liveFeedEventId }),
      );
    }

    if (attributes.event === NEW_MESSAGE_EVENT_TYPE) {
      return navigate(pathBuilder(userAppRoutes.messages.dialog, { threadId: messageThreadId }));
    }

    if (attributes.event === INQUIRY_REQUEST_TYPE) {
      return navigate(pathBuilder(userAppRoutes.messages.dialog, { threadId: messageThreadId }));
    }

    if (bookingId) {
      return navigate(pathBuilder(userAppRoutes.dashboard.bookingView, { bookingId }));
    }

    if (logOrEventId) {
      return navigate(
        pathBuilder(userAppRoutes.dashboard.channelEventView, { actionId: logOrEventId }),
      );
    }

    if (liveFeedEventId) {
      return navigate(
        pathBuilder(userAppRoutes.dashboard.liveFeedEventView, { eventId: liveFeedEventId }),
      );
    }

    return null;
  };

  const content = useMemo(() => {
    switch (event) {
      case SYNC_ERROR_EVENT_TYPE:
        return <EventsListItemSyncError item={attributes} />;

      case NEW_MESSAGE_EVENT_TYPE:
        return <EventsListItemNewMessage item={attributes} />;

      case RESERVATION_REQUEST_TYPE:
        return <EventsListItemReservationRequests item={attributes} />;

      case ALTERATION_REQUEST_TYPE:
        return <EventsListItemAlterationRequests item={attributes} />;

      case INQUIRY_REQUEST_TYPE:
        return <EventsListItemInquiry item={attributes} />;

      case AIRBNB_NOTIFICATION:
        return <EventsListItemAirbnbEvent item={attributes} />;

      default:
        return <EventsListItem item={attributes} />;
    }
  }, [event, attributes]);

  return (
    <Flexbox justifyContent="space-between" onClick={onClickItem}>
      {content}
    </Flexbox>
  );
}

EventsListTimeLineItem.propTypes = {
  attributes: PropTypes.shape({}),
};

export default EventsListTimeLineItem;
