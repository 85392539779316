import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import List from "components/list";

import { formatToLocal } from "utils/dates";
import prettifyJson from "utils/prettify_json";
import prettifyXml from "utils/prettify_xml";

import styles from "./channel_events.module.css";

const isXML = (data) => {
  return typeof data === "string" && data.trim().startsWith("<");
};

export default function ChannelEventsLog({ log }) {
  const { t } = useTranslation();
  const isErrorsPresent = Boolean(log.data.errors?.length);

  const prettify = useCallback(
    (data) => {
      try {
        if (isXML(data)) {
          return prettifyXml(data);
        }

        return prettifyJson(data);
      } catch (_error) {
        return t("channel_events_page:channel_events_view_dialog:unsupported_response_error");
      }
    },
    [t],
  );

  const nestedPanelItems = [{
    key: `${log.data.query_id}_${log.data.finished_at}_request`,
    label: t("channel_events_page:channel_events_view_dialog:request"),
    children: (<pre className={styles.log}>{prettify(log.data.request)}</pre>),
  }, {
    key: `${log.data.query_id}_${log.data.finished_at}_response`,
    label: t("channel_events_page:channel_events_view_dialog:response"),
    children: (<pre className={styles.log}>{prettify(log.data.response)}</pre>),
  }];

  const items = [{
    label: log.data.method,
    extra: isErrorsPresent && <ExclamationCircleOutlined className={styles.errorIcon} />,
    children: (
      <>
        <List>
          <List.Item
            term={t("channel_events_page:channel_events_view_dialog:method")}
            description={log.data.method}
          />
          <List.Item
            term={t("channel_events_page:channel_events_view_dialog:started_at")}
            description={formatToLocal(log.data.started_at, t("formats:iso_date_with_miliseconds"))}
          />
          <List.Item
            term={t("channel_events_page:channel_events_view_dialog:finished_at")}
            description={formatToLocal(
              log.data.finished_at,
              t("formats:iso_date_with_miliseconds"),
            )}
          />
        </List>
        <Collapse items={nestedPanelItems} />
      </>
    ),
  }];

  return (
    <Collapse items={items} />
  );
}
