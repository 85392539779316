import React from "react";
import { DatePicker } from "antd";

import styles from "./range_picker.module.css";

export default function RangePicker({
  isMobile,
  value,
  defaultValue,
  disabledDate,
  onChange,
  dataCy,
}) {
  const datePickerMobileProps = isMobile
    ? {
      showTime: true,
      format: "YYYY/MM/DD",
      popupClassName: styles.datePickerMobileDropdown,
    }
    : {};

  return (
    <DatePicker.RangePicker
      data-cy={dataCy}
      value={value}
      defaultValue={defaultValue}
      className={styles.datePicker}
      disabledDate={disabledDate}
      onChange={onChange}
      {...datePickerMobileProps}
    />
  );
}
