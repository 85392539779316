import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import classnames from "classnames";

import styles from "./mapping_row.module.css";

export default function MapingRowDeleteButton({ disabled, visible, onClick }) {
  if (!visible) {
    return null;
  }

  const className = classnames(styles.deleteButton, {
    [styles.deleteButtonDisabled]: disabled,
  });

  return (
    <a // eslint-disable-line jsx-a11y/anchor-is-valid
      className={className}
      onClick={() => {
        if (disabled) {
          return false;
        }

        onClick();

        return false;
      }}
    >
      <DeleteOutlined />
    </a>
  );
}
