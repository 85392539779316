import React from "react";
import { useTranslation } from "react-i18next";

import LinkButton from "components/forms/buttons/link_button";

export default function ChannelSettings({ missingContent, onChannelSettingsNavigateClick }) {
  const { t } = useTranslation();

  return (
    <LinkButton onClick={() => onChannelSettingsNavigateClick(missingContent.field)}>
      {t(`channels_page:form:missing_content:${missingContent.code}`, { field: t(`channels_page:form:missing_content:${missingContent.field}`) })}
    </LinkButton>
  );
}
