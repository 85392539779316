import React, { Component } from "react";
import PropTypes from "prop-types";
import { DownOutlined, EditOutlined, FieldTimeOutlined, ReloadOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Space } from "antd";
import withMediaQuery from "hooks/use_media_query/hoc";

import APP_MODES from "config/constants/app_modes";

import RestrictionFilter from "./restriction_filter";
import SaveButton from "./save_button";

import styles from "./inventory_filters.module.css";

const Option = Select.Option;

const FULL_TOOLBAR_BREAKPOINT = 1200;

class InventoryFilters extends Component {
  static propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    onResetChanges: PropTypes.func,
    onSaveChanges: PropTypes.func,
    onToggleShowLog: PropTypes.func,
    hasChanges: PropTypes.bool,
    filters: PropTypes.object,
    roomTypes: PropTypes.array,
    ratePlanNames: PropTypes.object,
    saveChangesInProgress: PropTypes.bool,
    minStayType: PropTypes.string,
  };

  static filterOptions = (input, option) => {
    const searchValue = input.toLocaleLowerCase();
    const optionValue = option.props.children.toLocaleLowerCase();
    return optionValue.indexOf(searchValue) !== -1;
  };

  handleChange(field) {
    return (value) => this.setState({ [field]: value });
  }

  renderRestrictionFilter() {
    const { onChange, filters, minStayType } = this.props;

    return <RestrictionFilter onChange={onChange} filters={filters} minStayType={minStayType} />;
  }

  renderRoomFilter() {
    const { t, filters, onChange, roomTypes } = this.props;

    return (
      <div className={styles.filter__selectContainer}>
        <Select
          data-cy="rooms_filter"
          mode="multiple"
          defaultValue={filters.selectedRooms}
          value={filters.selectedRooms}
          placeholder={t("inventory_page:filters:rooms")}
          onChange={onChange("selectedRooms")}
          allowClear
          maxTagCount={0}
          maxTagPlaceholder={`${filters.selectedRooms.length} Rooms selected`}
          filterOption={InventoryFilters.filterOptions}
        >
          {roomTypes.map((roomType) => (
            <Option key={roomType.id} value={roomType.id}>
              {roomType.title}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  checkIfRateHasChannel = (rateName) => {
    const { ratePlanNames, ratePlans } = this.props;

    return ratePlans.find((rate) => !rate.channel_id && rate.id === ratePlanNames[rateName][0]);
  };

  renderRateFilter() {
    const { t, onChange, filters, ratePlanNames } = this.props;

    const selectableRatePlanNames = Object.keys(ratePlanNames).filter(this.checkIfRateHasChannel);

    return (
      <div className={styles.filter__selectContainer}>
        <Select
          data-cy="rates_filter"
          mode="multiple"
          defaultValue={filters.selectedRates}
          value={filters.selectedRates}
          placeholder={t("inventory_page:filters:rates")}
          onChange={onChange("selectedRates")}
          allowClear
          maxTagCount={0}
          maxTagPlaceholder={`${filters.selectedRates.length} Rates selected`}
          filterOption={InventoryFilters.filterOptions}
        >
          {selectableRatePlanNames.map((ratePlan) => (
            <Option key={ratePlan} value={ratePlan}>
              {ratePlan}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  render() {
    const {
      t,
      appMode,
      fullToolbarBreakpoint,
      hasChanges,
      saveChangesInProgress,
      onResetChanges,
      onSaveChanges,
      onOpenBulkUpdate,
      onToggleShowLog,
      onSettingClick,
    } = this.props;

    const isHeadlessMode = appMode === APP_MODES.HEADLESS;

    const actionsMenu = [{
      label: (<><EditOutlined /> {t("general:action:bulk_update")}</>),
      key: "bulk_update",
      onClick: onOpenBulkUpdate,
    }];

    if (!isHeadlessMode) {
      actionsMenu.push({
        label: (<><FieldTimeOutlined /> {t("general:action:show_logs")}</>),
        key: "show_logs",
        onClick: onToggleShowLog,
      });
    }

    actionsMenu.push({
      label: (<><SettingOutlined /> {t("general:action:settings")}</>),
      key: "settings",
      onClick: onSettingClick,
    });

    return (
      <div className={styles.filters}>
        <div className={styles.filters__leftSection}>
          <div className={styles.filters__leftSection__content}>
            {this.renderRestrictionFilter()}
            {this.renderRoomFilter()}
            {this.renderRateFilter()}
          </div>
        </div>
        <div className={styles.filters__rightSection}>
          <SaveButton
            disabled={!hasChanges}
            loading={saveChangesInProgress}
            iconOnly={!fullToolbarBreakpoint}
            onClick={onSaveChanges}
          />

          <Button
            data-cy="reset_changes_button"
            disabled={!hasChanges}
            className={styles.filters__resetButton}
            onClick={onResetChanges}
          >
            <ReloadOutlined />{fullToolbarBreakpoint && t("general:action:reset_changes")}
          </Button>

          <Dropdown menu={{ items: actionsMenu }}>
            <Button>
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default withMediaQuery({
  fullToolbarBreakpoint: { minWidth: FULL_TOOLBAR_BREAKPOINT },
})(InventoryFilters);
