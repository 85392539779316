import React from "react";

import styles from "./drawer_form_wrapper.module.css";

export default function ({ className, withActions = false, children }) {
  return (
    <div
      className={`${styles.wrapper} ${className || ""} ${
        withActions ? styles.wrapperWithButton : ""
      }`}
    >
      {children}
    </div>
  );
}
