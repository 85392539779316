import React from "react";

import ContentContainer from "../../../../_common/content_container";

import Airbnb from "./by_ota/airbnb";
import BookingCom from "./by_ota/booking_com";

const FEEDBACK_COMPONENTS = {
  AirBNB: Airbnb,
  BookingCom,
};

export default function GuestFeedback({ review }) {
  const ota = review.attributes.ota;

  const FeedbackComponent = FEEDBACK_COMPONENTS[ota];

  if (!FeedbackComponent) {
    return null;
  }

  return (
    <ContentContainer quotes border>
      <FeedbackComponent review={review} />
    </ContentContainer>
  );
}
