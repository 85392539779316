import React from "react";

import styles from "./error_message.module.css";

export default function PropertiesErrorMessage({ icon, text }) {
  return (
    <div className={styles.errorMessage}>
      <img src={icon} alt="Error" />
      {text}
    </div>
  );
}
