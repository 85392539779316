import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getActiveProperty, getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import DataLoader from "components/data_loader";
import GlobalComponents from "components/global_components";
import AppLayout from "components/layouts/app_layout";
import Header from "components/page/header";

import useRouting from "routing/use_routing";

export default function Layout() {
  const propertiesOptions = useSelector(getPropertiesOptions);
  const activeProperty = useSelector(getActiveProperty);
  const appMode = useSelector(getAppMode);
  const { userAppRoutes } = useRouting();

  const { search } = useLocation();

  const isOnPropertiesPage = useMatch({ path: userAppRoutes.properties._path, end: false });
  const isOnProfilePage = useMatch({ path: userAppRoutes.userProfile._path, end: false });
  const isOnQueryErrorPage = useMatch({ path: userAppRoutes.queryErrorPage._path, end: false });
  const isAdminRootPage = useMatch({ path: userAppRoutes.admin._path, end: false });
  const hasEmptyProperties = propertiesOptions?.length === 0;
  const hasHeadlessModeErrors = appMode === APP_MODES.HEADLESS && !activeProperty;

  const urlParams = new URLSearchParams(search);
  const routeToRedirectTo = urlParams.get("redirect_to");

  if (hasHeadlessModeErrors && !isOnQueryErrorPage) {
    return <Navigate to={userAppRoutes.queryErrorPage._path} />;
  }

  if (hasEmptyProperties && !isOnPropertiesPage && !isOnProfilePage) {
    return <Navigate to={userAppRoutes.properties._path} />;
  }

  if (routeToRedirectTo) {
    return <Navigate to={routeToRedirectTo} />;
  }

  if (isAdminRootPage && isAdminRootPage.isExact) {
    return <Navigate to={userAppRoutes.admin.users._path} />;
  }

  return (
    <>
      <DataLoader />
      <AppLayout>
        <GlobalComponents />
        <Header />
        <Outlet />
      </AppLayout>
    </>
  );
}
