import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CalendarOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Tooltip } from "antd";

import appModes from "config/constants/app_modes";
import READ_ONLY_RESTRICTIONS from "config/constants/inventory/read_only_restrictions";
import { features } from "config/feature_flags";

import RateViewDrawer from "drawers/rate_view_drawer";
import RoomFormDrawerControlled from "drawers/room_form_drawer_controlled";
import StateChangesDrawer from "drawers/state_changes_drawer";

import getRestrictionValue from "components/inventory/utils/get_restriction_value";
import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import { format } from "utils/dates";
import dayjs from "utils/dayjs";
import extractPerPersonRates from "utils/extract_per_person_rates";
import getArrayDays from "utils/get_array_days";

import InventoryTableRatePlan from "./components/inventory_table_rate_plan";

import styles from "./inventory_table.module.css";

const HEADER_OFFSET = 160;
const HEADER_HEADLESS_OFFSET = 48;

class InventoryTable extends Component {
  static propTypes = {
    ari: PropTypes.object,
    availability: PropTypes.object,
    t: PropTypes.func,
    dates: PropTypes.array,
    ratePlans: PropTypes.array,
    filters: PropTypes.object,
    roomTypes: PropTypes.array,
    onScrollDates: PropTypes.func,
    currentDate: PropTypes.object,
    changes: PropTypes.object,
    onValueClick: PropTypes.func,
    onChangeDate: PropTypes.func,
    defaultCurrentDate: PropTypes.instanceOf(Date),
    channels: PropTypes.object,
    minStayType: PropTypes.string,
    isShowLogEnabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      ratePlanId: null,
      restriction: null,
      datePickerIsVisible: false,
      ratesWithVisibleChannels: [],
      rateDrawer: {
        visible: false,
        isEditable: false,
        ratePlanId: null,
        roomTypeId: null,
      },
      roomDrawer: {
        visible: false,
        roomTypeId: null,
      },
      stateChangesDrawer: {
        visible: false,
        ratePlanId: null,
        date: null,
        restriction: null,
      },
    };
  }

  static getValue(storage, ratePlanId, date, restriction) {
    return storage && storage[ratePlanId] && storage[ratePlanId][date]
      ? storage[ratePlanId][date][restriction]
      : undefined;
  }

  static getAvailability(storage, roomTypeId, date) {
    return storage && storage[roomTypeId] ? storage[roomTypeId][date] : undefined;
  }

  static isStopSell(restriction, ari, ratePlanId, date) {
    let isStopSell = null;

    if (
      restriction === "rate"
      && InventoryTable.getValue(ari, ratePlanId, date, "stop_sell") === true
    ) {
      isStopSell = styles.table__value__stop_sell;
    }

    return isStopSell;
  }

  static isSold(value) {
    return value === 0 ? styles.table__value__sold : null;
  }

  static isChanged(value) {
    return typeof value !== "undefined" ? styles.table__value__changed : null;
  }

  static isSelected(id, requestedRestriction, date, state) {
    const { startDate, endDate, restriction, ratePlanId } = state;

    return id === ratePlanId
      && requestedRestriction === restriction
      && date >= startDate
      && date <= endDate
      ? styles.table__value__selected
      : null;
  }

  static isWeekend(date) {
    const weekday = Number(format(date, "E"));
    return weekday === 6 || weekday === 7 ? styles.table__weekend : null;
  }

  static getFinalValue(ariValue, changedValue) {
    let value;

    if (changedValue !== undefined) {
      value = changedValue;
    } else if (changedValue === undefined && ariValue !== undefined) {
      value = ariValue;
    }

    return value;
  }

  onMouseDown = (ratePlanId, restriction, date) => {
    const { ratePlansById, isShowLogEnabled, readOnlyAvailability } = this.props;

    /* eslint-disable consistent-return */
    return (e) => {
      const ratePlan = ratePlansById[ratePlanId];

      if (restriction === "availability" && readOnlyAvailability) {
        return false;
      }

      if (this.isInherit(ratePlan, restriction)) {
        return false;
      }

      if (isShowLogEnabled) {
        return false;
      }

      if (e.button === 0) {
        this.setState({
          ratePlanId,
          restriction,
          startDate: date,
        });
      }
    };
  };

  getRestrictionsByRatePlanId = (ratePlanId) => {
    const { ari } = this.props;
    return ari[ratePlanId];
  };

  getAvailabilityByRatePlanId = (ratePlanId) => {
    const { availability } = this.props;
    const availabilityByRatePlanId = availability[ratePlanId] || {};

    return Object.entries(availabilityByRatePlanId).reduce((acc, [key, val]) => {
      acc = { [key]: { availability: val }, ...acc };
      return acc;
    }, {});
  };

  getModalValues = (ratePlanId, restriction, startDate, endDate) => {
    const restrictions = this.getRestrictionsByRatePlanId(ratePlanId);
    const availability = this.getAvailabilityByRatePlanId(ratePlanId);
    const days = getArrayDays(startDate, endDate);

    const restrictionsAndAvailabilityValue = { ...restrictions, ...availability };

    const values = days.reduce((acc, date) => {
      acc.push(restrictionsAndAvailabilityValue[date][restriction]);
      return acc;
    }, []);

    return values;
  };

  onMouseUp = (ratePlanId, restriction, date, _value, isRoom) => {
    const { ratePlansById, onValueClick, isShowLogEnabled } = this.props;

    /* eslint-disable consistent-return */
    return () => {
      const { startDate, endDate } = this.state;

      const ratePlan = ratePlansById[ratePlanId];

      if (isShowLogEnabled) {
        const stateChangesDrawer = {
          date,
          restriction,
          visible: true,
          ratePlanId: isRoom ? null : ratePlanId,
          roomTypeId: isRoom ? ratePlanId : null,
        };

        this.setState({ stateChangesDrawer });

        this.props.onLogShown();

        return false;
      }

      if (this.isInherit(ratePlan, restriction)) {
        return false;
      }

      if (ratePlan && ratePlan.ui_read_only) {
        return false;
      }

      const values = this.getModalValues(ratePlanId, restriction, startDate, endDate || date);

      if (ratePlanId && restriction && startDate) {
        onValueClick(ratePlanId, restriction, [startDate, endDate || date], values)();

        this.setState({
          startDate: null,
          endDate: null,
          ratePlanId: null,
          restriction: null,
        });
      }
    };
  };

  isInherit = (ratePlan, restriction) => {
    let inheritRestrictionName;

    // Hack for RoomType availability level.
    // TODO: Remove after refactoring
    if (!ratePlan) {
      return false;
    }

    // Stop sell in channel rate plans should be overridable
    if (ratePlan.channel_id && restriction === "stop_sell_manual") {
      return false;
    }

    // Required to convert virtual names into real
    // stop_sell_manual can be checked by stop_sell
    switch (restriction) {
      case "stop_sell_manual":
        inheritRestrictionName = "stop_sell";
        break;

      default:
        inheritRestrictionName = restriction;
        break;
    }

    // Rate Plan availability is always derived
    if (inheritRestrictionName === "availability") {
      return true;
    }

    return ratePlan[`inherit_${inheritRestrictionName}`];
  };

  onMouseMove = (date) => {
    return () => {
      const { startDate, endDate } = this.state;

      if (startDate && endDate !== date) {
        this.setState({ endDate: date });
      }
    };
  };

  openStateChanges = (ratePlanId, roomTypeId, date, restriction) => {
    return () => {
      const stateChangesDrawer = {
        ratePlanId,
        roomTypeId,
        date,
        restriction,
        visible: true,
      };

      this.setState({ stateChangesDrawer });
    };
  };

  onOpenDatePicker = () => {
    this.setState({ datePickerIsVisible: true });
  };

  onBackDropClick = () => {
    this.setState({ datePickerIsVisible: false });
  };

  onChangeDate = (e) => {
    const { onChangeDate } = this.props;
    this.setState({ datePickerIsVisible: false });
    onChangeDate(e.toDate());
  };

  toggleChannelsVisibility = (ratePlanId) => {
    return (event) => {
      event.preventDefault();
      let { ratesWithVisibleChannels } = this.state;

      if (ratesWithVisibleChannels.indexOf(ratePlanId) === -1) {
        ratesWithVisibleChannels.push(ratePlanId);
      } else {
        ratesWithVisibleChannels = ratesWithVisibleChannels.filter((el) => el !== ratePlanId);
      }

      this.setState({ ratesWithVisibleChannels });
    };
  };

  renderValues(ratePlanId, restriction) {
    const { ari, dates, changes, minStayType, isShowLogEnabled } = this.props;
    if (restriction === "rate_availability") {
      restriction = "availability";
    }
    if (restriction === "min_stay" && minStayType === "arrival") {
      restriction = "min_stay_arrival";
    }
    if (restriction === "min_stay" && minStayType === "through") {
      restriction = "min_stay_through";
    }

    const cells = dates.map((date) => {
      const ariValue = InventoryTable.getValue(ari, ratePlanId, date, restriction);
      const changedValue = InventoryTable.getValue(changes, ratePlanId, date, restriction);
      const value = InventoryTable.getFinalValue(ariValue, changedValue);
      const isStopSell = InventoryTable.isStopSell(restriction, ari, ratePlanId, date);
      const isChanged = InventoryTable.isChanged(changedValue);
      const isSelected = InventoryTable.isSelected(ratePlanId, restriction, date, this.state);
      const isWeekend = InventoryTable.isWeekend(date);
      const showLogClass = isShowLogEnabled ? styles.showLogCursor : null;

      return this.renderValueCell(
        date,
        value,
        restriction,
        ratePlanId,
        [styles.table__value, isChanged, isSelected, isWeekend, isStopSell, showLogClass].join(" "),
      );
    });

    return (
      <div data-cy="inventory_table_values_row" className={styles.table__valuesCell}>
        {cells}
      </div>
    );
  }

  renderAvailability(roomTypeId) {
    const { availability, dates, changes, isShowLogEnabled } = this.props;

    const cells = dates.map((date) => {
      const ariValue = InventoryTable.getAvailability(availability, roomTypeId, date);
      const changedValue = InventoryTable.getValue(changes, roomTypeId, date, "availability");
      const value = InventoryTable.getFinalValue(ariValue, changedValue);
      const isSold = InventoryTable.isSold(value);
      const isChanged = InventoryTable.isChanged(changedValue);
      const isSelected = InventoryTable.isSelected(roomTypeId, "availability", date, this.state);
      const isWeekend = InventoryTable.isWeekend(date);
      const showLogClass = isShowLogEnabled ? styles.showLogCursor : null;
      const isReadOnly = this.props.readOnlyAvailability ? styles.readOnly : null;

      return this.renderValueCell(
        date,
        value,
        "availability",
        roomTypeId,
        [styles.table__value, isSold, isChanged, isSelected, isWeekend, showLogClass, isReadOnly].join(" "),
        true,
      );
    });

    return (
      <div data-cy="inventory_table_values_row" className={styles.table__valuesCell}>
        {cells}
      </div>
    );
  }

  renderValueCell(date, value, restriction, id, classes, isRoom) {
    const isLoaded = typeof value !== "undefined";
    const restrictionsValue = getRestrictionValue(restriction, value);

    return (
      <div
        data-cy="inventory_table_cell"
        key={`${date}-${restriction}-${id}`}
        className={classes}
        onMouseDown={this.onMouseDown(id, restriction, date)}
        onMouseUp={this.onMouseUp(id, restriction, date, value, isRoom)}
        onMouseMove={this.onMouseMove(date)}
      >
        {isLoaded && <span className={styles.table__valueSpan}>{restrictionsValue}</span>}
        {!isLoaded && <span className="ant-skeleton-title">***</span>}
      </div>
    );
  }

  handleRateDrawerOpen = (ratePlanId, roomTypeId, isRatePlanEditable) => () => {
    const rateDrawer = {
      ratePlanId,
      roomTypeId,
      isEditable: isRatePlanEditable,
      visible: true,
    };

    this.setState({ rateDrawer });
  };

  handleRateDrawerClose = () => {
    const rateDrawer = {
      ratePlanId: null,
      roomTypeId: null,
      isEditable: false,
      visible: false,
    };

    this.setState({ rateDrawer });
  };

  handleRoomDrawerOpen = (roomTypeId) => () => {
    const roomDrawer = {
      roomTypeId,
      visible: true,
    };

    this.setState({ roomDrawer });
  };

  handleRoomDrawerClose = () => {
    const roomDrawer = {
      roomTypeId: null,
      visible: false,
    };

    this.setState({ roomDrawer });
  };

  handleStateChangesDrawerClose = () => {
    const stateChangesDrawer = {
      ratePlanId: null,
      roomTypeId: null,
      date: null,
      restriction: null,
      visible: false,
    };

    this.setState({ stateChangesDrawer });
  };

  renderRatePlan = (ratePlan, roomTypeId) => {
    const { t, filters, activeFeatureFlags, channels, minStayType } = this.props;
    const { ratesWithVisibleChannels } = this.state;
    const { selectedRestrictions } = filters;

    const isRatePlanEditable = !ratePlan.channel_id;
    const ratePlanToViewId = ratePlan.is_option ? ratePlan.parent_rate_plan_id : ratePlan.id;
    const isRateViewEnabled = activeFeatureFlags[features.RATE_PLAN_QUICK_VIEW];

    return (
      <React.Fragment key={ratePlan.id}>
        {selectedRestrictions
          .filter((restriction) => restriction !== "availability")
          .map((restriction, index) => {
            let isInherit = this.isInherit(ratePlan, restriction);

            if (restriction === "min_stay" && minStayType === "arrival") {
              isInherit = ratePlan.inherit_min_stay_arrival;
            }
            if (restriction === "min_stay" && minStayType === "through") {
              isInherit = ratePlan.inherit_min_stay_through;
            }

            const classes = [index === 0 ? styles.table__rateRow : styles.table__subRateRow];
            const UIreadOnly = ratePlan.ui_read_only;

            if (isInherit || READ_ONLY_RESTRICTIONS.includes(restriction) || UIreadOnly) {
              classes.push(styles.table__inheritRow);
            }

            if (ratePlan.channel_id) {
              classes.push(styles.table__channelRateRow);
            }

            return (
              <tr key={`${ratePlan.id}__${restriction}`} className={classes.join(" ")}>
                {index === 0 && (
                  <td
                    rowSpan={selectedRestrictions.length - 1 || 1}
                    className={styles.table__rateNameCell}
                  >
                    <InventoryTableRatePlan
                      ratePlan={ratePlan}
                      channel={channels[ratePlan.channel_id] || null}
                      onToggleChannelsList={this.toggleChannelsVisibility}
                      onClick={
                        isRateViewEnabled
                        && this.handleRateDrawerOpen(ratePlanToViewId, roomTypeId, isRatePlanEditable)
                      }
                    />
                  </td>
                )}
                <td
                  data-cy="inventory_table_restriction_name"
                  className={styles.table__restrictionCell}
                >
                  <Tooltip placement="right" title={t(`general:restrictions:${restriction}`)}>
                    {t(`general:restrictions_abbr:${restriction}`)}
                  </Tooltip>
                  {isInherit && (
                    <small className={styles.table__rowLabel}>
                      <Tooltip placement="right" title={this.getTooltipText(ratePlan)}>
                        <LinkOutlined />
                      </Tooltip>
                    </small>
                  )}
                </td>
                <td>{this.renderValues(ratePlan.id, restriction)}</td>
              </tr>
            );
          })}
        {ratesWithVisibleChannels.indexOf(ratePlan.id) !== -1
          && this.renderChannelRates(ratePlan.id, roomTypeId)}
      </React.Fragment>
    );
  };

  compareRates = (a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }

    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }

    return a.occupancy - b.occupancy;
  };

  renderChannelRates(ratePlanId, roomTypeId) {
    const { ratePlans } = this.props;

    return ratePlans
      .filter((ratePlan) => {
        return ratePlan.channel_id && ratePlan.parent_rate_plan_id === ratePlanId;
      })
      .sort(this.compareRates)
      .map((rate) => this.renderRatePlan(rate, roomTypeId));
  }

  renderRates(roomTypeId) {
    const { ratePlans, filters } = this.props;
    const { selectedRates } = filters;

    return ratePlans
      .filter((ratePlan) => {
        return (
          ratePlan.room_type_id === roomTypeId
          && (selectedRates.length ? selectedRates.indexOf(ratePlan.title) !== -1 : true)
          && !ratePlan.channel_id
        );
      })
      .sort(this.compareRates)
      .map((rate) => this.renderRatePlan(rate, roomTypeId));
  }

  getTooltipText(ratePlan) {
    const { roomTypesById, ratePlansById } = this.props;
    if (ratePlan.parent_rate_plan_id && ratePlansById[ratePlan.parent_rate_plan_id]) {
      return (
        <>
          Rates derived from
          <br />
          {ratePlansById[ratePlan.parent_rate_plan_id].title}
          &nbsp; ({ratePlansById[ratePlan.parent_rate_plan_id].occupancy}
          &nbsp; Persons ) &nbsp;/&nbsp;
          {roomTypesById[ratePlansById[ratePlan.parent_rate_plan_id].room_type_id].title}
        </>
      );
    }
    return null;
  }

  renderRows() {
    const { roomTypes, filters, t, isLoadingRoomTypes } = this.props;

    if (isLoadingRoomTypes) {
      return <tr><td><Loading className={styles.table__loading} /></td></tr>;
    }

    if (!roomTypes.length) {
      return (
        <tr>
          <td>
            <NoDataPlaceholder
              emptyMessage={t("inventory_page:empty_property_message")}
              type="button"
              createMessageText={t("general:create_first_before")}
              createMessageActionText={t("general:create_first_link")}
              onClick={this.handleRoomDrawerOpen(null)}
              className={styles.table__empty}
            />
          </td>
        </tr>
      );
    }

    return roomTypes
      .filter((roomType) => {
        let res = true;

        if (filters.selectedRooms.length > 0) {
          res = filters.selectedRooms.indexOf(roomType.id) !== -1;
        }

        return res;
      })
      .map((roomType) => {
        return (
          <React.Fragment key={roomType.id}>
            <tr className={styles.table__roomTypeRow}>
              <td className={styles.table__roomTypeCell}>
                <Button
                  className={styles.table__roomLink}
                  type="link"
                  onClick={this.handleRoomDrawerOpen(roomType.id)}
                >
                  {roomType.title}
                </Button>
              </td>
              <td
                data-cy="inventory_table_restriction_name"
                className={styles.table__restrictionCell}
              >
                <Tooltip placement="right" title={t("general:restrictions:availability")}>
                  {t("general:restrictions_abbr:availability")}
                </Tooltip>
              </td>
              <td className={styles.table__roomTypeValues}>
                {this.renderAvailability(roomType.id)}
              </td>
            </tr>
            {this.renderRates(roomType.id)}
          </React.Fragment>
        );
      });
  }

  static renderDates(dates) {
    return dates.map((date) => {
      const [wDay, weekDay, dateNumber, month] = format(date, "E ddd DD MMM").split(" ");
      const weekendClass = wDay === "6" || wDay === "7" ? styles.table__header__dateColumn_weekend : null;

      return (
        <div
          key={`${date}-date-column`}
          className={[styles.table__header__dateColumn, weekendClass].join(" ")}
        >
          <small>{weekDay}</small>
          {dateNumber}
          <small>{month}</small>
        </div>
      );
    });
  }

  disabledDate = (date) => {
    const { defaultCurrentDate } = this.props;

    return date.isBefore(defaultCurrentDate, "day");
  };

  render() {
    const {
      dates,
      onScrollDates,
      currentDate,
      activeFeatureFlags,
      defaultCurrentDate,
      appMode,
      activeProperty,
    } = this.props;
    const { datePickerIsVisible, rateDrawer, roomDrawer, stateChangesDrawer } = this.state;
    const currentDateValue = dayjs(currentDate);
    const offset = appMode === appModes.HEADLESS ? HEADER_HEADLESS_OFFSET : HEADER_OFFSET;

    const isToday = dayjs(defaultCurrentDate).isSame(currentDateValue, "day");

    return (
      <>
        <div className={styles.table__container} style={{ height: window.innerHeight - offset }}>
          <div data-cy="dates_container" className={styles.table__header}>
            <div className={styles.table__header__currentDate}>
              <a
                className={styles.table__header__prevDateButton}
                href="#prev"
                onClick={onScrollDates(-1)}
                disabled={isToday}
              >
                <ArrowLeftOutlined />
              </a>
              <DatePicker
                value={currentDateValue}
                open={datePickerIsVisible}
                onChange={this.onChangeDate}
                disabledDate={this.disabledDate}
                popupClassName={styles.table__calendarDropdown}
              />
              <span onClick={this.onOpenDatePicker}>
                {format(currentDate, "DD MMM YYYY")}
                &nbsp;
                <CalendarOutlined />
              </span>
              <a
                className={styles.table__header__nextDateButton}
                href="#next"
                onClick={onScrollDates(1)}
              >
                <ArrowRightOutlined />
              </a>
            </div>
            {InventoryTable.renderDates(dates)}
          </div>
          <table data-cy="inventory_table" className={styles.table}>
            <tbody>{this.renderRows()}</tbody>
          </table>
          {datePickerIsVisible && (
            <div className={styles.backdrop} onClick={this.onBackDropClick} />
          )}
        </div>
        {activeFeatureFlags[features.RATE_PLAN_QUICK_VIEW] && (
          <RateViewDrawer
            visible={rateDrawer.visible}
            isEditable={rateDrawer.isEditable}
            ratePlanId={rateDrawer.ratePlanId}
            roomTypeId={rateDrawer.roomTypeId}
            onClose={this.handleRateDrawerClose}
          />
        )}
        <RoomFormDrawerControlled
          visible={roomDrawer.visible}
          roomTypeId={roomDrawer.roomTypeId}
          onClose={this.handleRoomDrawerClose}
        />
        <StateChangesDrawer
          visible={stateChangesDrawer.visible}
          ratePlanId={stateChangesDrawer.ratePlanId}
          roomTypeId={stateChangesDrawer.roomTypeId}
          date={stateChangesDrawer.date}
          restriction={stateChangesDrawer.restriction}
          propertyId={activeProperty}
          onClose={this.handleStateChangesDrawerClose}
        />
      </>
    );
  }
}

const mapStateToProps = ({ ratePlans, session }) => {
  const { activeFeatureFlags, activeProperty } = session;

  return {
    appMode: session.appMode,
    activeFeatureFlags,
    activeProperty,
    ratePlansById: extractPerPersonRates(ratePlans),
    readOnlyAvailability: session.readOnlyAvailability,
  };
};

export default connect(mapStateToProps)(InventoryTable);
