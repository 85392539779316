import React, { Component } from "react";
import { Widget } from "@uploadcare/react-widget";
import uploadcareTabEffects from "uploadcare-widget-tab-effects";

import config from "config";

import styles from "./uploadcare_loader.module.css";

const DEFAULT_HANDLER = () => {};

class UploadcareLoader extends Component {
  state = {
    selectedFile: null,
  };

  handleChange = (selectedFile) => {
    const { onChange = DEFAULT_HANDLER, resetAfterLoad = true } = this.props;

    if (!selectedFile) {
      return;
    }

    onChange(selectedFile);

    // Section below is required to allow reset of uploadcare widget value, because it doesn't have any interface to do it out of the box
    if (resetAfterLoad) {
      // uploadcare uses hooks to set widget value from the props, so to keep it null we have to change the state twice
      this.setState({ selectedFile: "" }, () => {
        this.setState({ selectedFile: null });
      });
    }
  };

  handleSelect = (uploadInfo) => {
    const { onFileSelect = DEFAULT_HANDLER } = this.props;

    // this check is required to avoid callback calls when we reset the state
    if (!uploadInfo) {
      return;
    }

    const { files } = uploadInfo;
    const fileInfo = files ? files() : uploadInfo;

    onFileSelect(fileInfo);
  };

  render() {
    const { inputRef, id, multiple } = this.props;
    const { selectedFile } = this.state;

    return (
      <div className={styles.loaderButton}>
        <Widget
          ref={inputRef}
          publicKey={config.UPLOADCARE_PUBLIC_KEY}
          id={id}
          multiple={multiple}
          imagesOnly
          customTabs={{ preview: uploadcareTabEffects }}
          previewStep
          cdnBase={config.UPLOADCARE_CDN_BASE}
          onChange={this.handleChange}
          onFileSelect={this.handleSelect}
          value={selectedFile}
        />
      </div>
    );
  }
}

export default UploadcareLoader;
