import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Empty, Form, Modal, notification } from "antd";
import { Formik } from "formik";
import store from "store";

import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikSelect from "components/forms/inputs/formik/form_select";
import Loading from "components/loading";

const { Channels } = store;

export default function (props) {
  const { t } = useTranslation();
  const channelId = props.form.values.id;
  const [opportunities, setOpportunities] = useState(null);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [value, _setValue] = useState({});
  const [opportunityFormLoading, setOpportunityFormLoading] = useState(false);
  const formRef = React.createRef();

  const loadOpportunities = useCallback(() => {
    Channels.airbnbGetOpportunities(channelId).then((data) => {
      setOpportunities(data.opportunities);
    });
  }, [channelId]);

  useEffect(() => {
    loadOpportunities();
  }, [loadOpportunities]);

  if (!opportunities) {
    return <Loading />;
  }

  const install = (opportunity) => {
    return () => {
      setSelectedOpportunity(opportunity);
    };
  };

  const onInstall = () => {
    const attrs = {
      opportunity: {
        opportunity: selectedOpportunity.id,
        listing_ids: formRef.current.values.listing_ids,
        input_fields: formRef.current.values.input_fields,
      },
    };

    setOpportunityFormLoading(true);
    Channels.airbnbInstallOpportunity(channelId, attrs)
      .then((_) => {
        notification.success({
          message: t("channels_page:form:opportunities:messages:success"),
          description: t("channels_page:form:opportunities:messages:success"),
        });
        setSelectedOpportunity(null);
        loadOpportunities();
      })
      .catch((error) => {
        notification.error({
          message: t("channels_page:form:opportunities:messages:error"),
          description:
            error.errors?.error_message
            || t("channels_page:form:opportunities:messages:unknown_error"),
        });
      })
      .finally(() => {
        setOpportunityFormLoading(false);
      });
  };

  const onCancelInstallation = () => {
    setSelectedOpportunity(null);
  };

  const generateModalContent = (opportunity) => {
    return (
      <Formik initialValues={value} innerRef={formRef}>
        <Form>
          <FormikSelect
            key="listing_ids"
            name="listing_ids"
            placeholder={t("channels_page:form:opportunities:modal:applicable_for")}
            label={t("channels_page:form:opportunities:modal:applicable_for")}
            mode="multiple"
            options={props.mappingOptions.listing_id_dictionary.values.map((listing) => {
              return {
                value: listing.id,
                representation: listing.title,
              };
            })}
          />

          {opportunity.input_fields.map((inputField) => {
            switch (inputField.value_constraint.value_type) {
              case "PERCENTAGE":
                return (
                  <FormikFormInput
                    key={inputField.key}
                    name={`input_fields[${inputField.key.replace(".", "__")}]`}
                    placeholder={inputField.key_label}
                    label={inputField.key_label}
                    type="number"
                    min={inputField.value_constraint.min_value}
                    max={inputField.value_constraint.max_value}
                  />
                );
              case "INTEGER":
                return (
                  <FormikFormInput
                    key={inputField.key}
                    name={`input_fields[${inputField.key.replace(".", "__")}]`}
                    placeholder={inputField.key_label}
                    label={inputField.key_label}
                    type="number"
                    min={inputField.value_constraint.min_value}
                    max={inputField.value_constraint.max_value}
                  />
                );
              case "OPTION":
                return (
                  <FormikSelect
                    key={inputField.key}
                    name={`input_fields[${inputField.key.replace(".", "__")}]`}
                    placeholder={inputField.key_label || inputField.key}
                    label={inputField.key_label || inputField.key}
                    options={inputField.value_constraint.options.map((option) => {
                      return { value: option, representation: option };
                    })}
                  />
                );
              default:
                return null;
            }
          })}
        </Form>
      </Formik>
    );
  };

  const renderModal = () => {
    if (!selectedOpportunity) {
      return null;
    }

    return (
      (
        <Modal
          title={t("channels_page:form:opportunities:modal:title")}
          open="true"
          onOk={onInstall}
          onCancel={onCancelInstallation}
          okText={t("channels_page:form:opportunities:install")}
          cancelText={t("channels_page:form:opportunities:modal:cancel")}
          okButtonProps={{ loading: opportunityFormLoading }}
        >
          {generateModalContent(selectedOpportunity)}
        </Modal>
      )
    );
  };

  if (!opportunities?.length) {
    return <Empty />;
  }

  const items = opportunities?.map((opportunity) => ({
    key: opportunity.id,
    label: opportunity.title,
    children: (
      <>
        <p>{opportunity.description}</p>
        <div className="text-right">
          {opportunity.activation_modes.indexOf("API") !== -1 && (
            <Button type="primary" onClick={install(opportunity)}>
              {t("channels_page:form:opportunities:install")}
            </Button>
          )}
          &nbsp;
          {opportunity.activation_modes.indexOf("MANUAL") !== -1 && (
            <a href={opportunity.activation_url} target="_blank" rel="noreferrer">
              {t("channels_page:form:opportunities:manual_link")}
            </a>
          )}
        </div>
      </>
    ),
  }));

  return (
    <>
      <Collapse items={items} />
      {renderModal()}
    </>
  );
}
