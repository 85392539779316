import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Form } from "antd";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormCheckbox from "components/forms/inputs/formik/form_checkbox";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import FormikTextareaInput from "components/forms/inputs/formik/form_textarea";

import withRouter from "routing/with_router";

import events from "./events";
import WebhookTest from "./test";

import styles from "styles/form_in_drawer.module.css";

class WebhookForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    event_mask: yup.string().trim().required(errorMessages.required()),
    callback_url: yup.string().trim().required(errorMessages.required()),
    property_id: yup.string().trim().required(errorMessages.required()),
    is_active: yup.boolean(),
    send_data: yup.boolean(),
    request_params: yup.object().typeError("Should be a valid JSON").nullable(),
    headers: yup.object().typeError("Should be a valid JSON").nullable(),
  });

  getPropertyId = (props) => {
    if (props.params.propertyId) {
      return props.params.propertyId;
    }

    if (props.activeProperty) {
      return props.activeProperty;
    }

    return null;
  };

  DEFAULT_VALUE = {
    is_active: true,
    send_data: false,
    property_id: this.getPropertyId(this.props),
  };

  render() {
    const {
      t,
      componentRef,
      value,
      submitting,
      errors,
      onChange,
      onSubmit,
      propertyOptions,
    } = this.props;

    const options = propertyOptions.map((el) => ({
      value: el.id,
      representation: el.title,
    }));

    const eventOptions = events.map((el) => ({
      value: el,
      representation: t(`webhooks_crud:events:${el}`),
    }));

    return (
      <>
        <ChannexForm
          defaultValue={this.DEFAULT_VALUE}
          value={value}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit}>
              <FormikFormSelect
                mode="multiple"
                name="event_mask"
                options={eventOptions}
                placeholder={t("webhook_drawer:form:event_mask:placeholder")}
                label={t("webhook_drawer:form:event_mask:label")}
              />
              <FormikFormInput
                name="callback_url"
                placeholder={t("webhook_drawer:form:callback_url:placeholder")}
                label={t("webhook_drawer:form:callback_url:label")}
              />
              <FormikFormSelect
                showSearch
                options={options}
                name="property_id"
                placeholder={t("webhook_drawer:form:property_id:placeholder")}
                label={t("webhook_drawer:form:property_id:label")}
              />
              <FormikFormCheckbox
                name="is_active"
                placeholder={t("webhook_drawer:form:is_active:placeholder")}
                label={t("webhook_drawer:form:is_active:label")}
              />
              <FormikFormCheckbox
                name="send_data"
                placeholder={t("webhook_drawer:form:send_data:placeholder")}
                label={t("webhook_drawer:form:send_data:label")}
                extra={t("webhook_drawer:form:send_data:description")}
              />
              <FormikTextareaInput
                name="headers"
                placeholder={t("webhook_drawer:form:headers:placeholder")}
                label={t("webhook_drawer:form:headers:label")}
                extra={t("webhook_drawer:form:headers:description")}
              />
              <FormikTextareaInput
                name="request_params"
                placeholder={t("webhook_drawer:form:request_params:placeholder")}
                label={t("webhook_drawer:form:request_params:label")}
                extra={t("webhook_drawer:form:request_params:description")}
              />
            </Form>
          )}
        </ChannexForm>
        <div className={styles.spacer}>
          <WebhookTest model={value} />
        </div>
        <div className={styles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ properties: { options }, session: { activeProperty } }) => {
  return {
    activeProperty,
    propertyOptions: options || [],
  };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(WebhookForm)));
