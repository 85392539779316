class EventEmmiter {
  constructor() {
    this._type = "EventEmmiter";
  }

  bind(event, fct) {
    this._events = this._events || {};
    this._events[event] = this._events[event] || [];
    this._events[event].push(fct);
  }

  unbind(event, fct) {
    this._events = this._events || {};
    if (event in this._events === false) return;
    this._events[event].splice(this._events[event].indexOf(fct), 1);
  }

  trigger(...args) {
    const [event, ...restPayload] = args;
    this._events = this._events || {};
    if (event in this._events === false) return;
    for (let i = 0; i < this._events[event].length; i++) {
      this._events[event][i].apply(this, restPayload);
    }
  }
}

export default new EventEmmiter();
