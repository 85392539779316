import React, { Component } from "react";
import PropTypes from "prop-types";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";

import copyTextToClipboard from "utils/copy_text_to_clipboard";

import styles from "./copy_to_clipboard.module.css";

const RELEASE_DELAY = 150;
const DEFAULT_TYPE = "link";

class CopyToClipboard extends Component {
  static propTypes = {
    showIcon: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.any,
  };

  state = {
    copied: false,
  };

  onClick = () => {
    copyTextToClipboard(this.props.text);
    this.setState({
      copied: true,
    });

    setTimeout(this.onRelease, RELEASE_DELAY);
  };

  onRelease = () => {
    this.setState({
      copied: false,
    });
  };

  renderIcon = () => {
    const { showIcon = true } = this.props;
    const { copied } = this.state;

    if (!showIcon) {
      return null;
    }

    return copied ? <CheckOutlined /> : <CopyOutlined />;
  };

  render() {
    const { children, type = DEFAULT_TYPE } = this.props;

    return (
      <Button className={styles.button} type={type} onClick={this.onClick}>
        {children}
        {this.renderIcon()}
      </Button>
    );
  }
}

export default CopyToClipboard;
