import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import store from "store";

import parseApiErrors from "utils/parse_api_errors";

import UsersProfileFormView from "./user_profile_form_view";

const { Profiles } = store;

class UserProfileForm extends Component {
  state = {
    value: {},
    errors: {},
    loading: false,
  };

  formRef = createRef();

  componentDidMount() {
    this.resetForm();
  }

  resetForm = () => {
    const { user } = this.props;

    const value = {
      id: user.id,
      name: user.name,
      preferred_language: user.preferred_language,
    };

    this.setState({ value, errors: {} });
  };

  handleFormChange = (value) => {
    this.setState({ value });
  };

  handleSubmit = () => {
    const { value } = this.state;

    const payloadToSend = {
      id: value.id,
      name: value.name,
      preferred_language: value.preferred_language,
    };

    this.formRef.current
      .validate()
      .then(() => {
        this.setState({ loading: true });

        Profiles.update(payloadToSend)
          .then(() => {
            this.setState({ loading: false });

            notification.success({
              message: "Success",
              description: "User Profile Updated",
            });
          })
          .catch((payload) => {
            const errors = parseApiErrors(payload);

            this.setState({ loading: false, errors });
          });
      })
      .catch(() => {});
  };

  render() {
    const { value, errors, loading } = this.state;

    return (
      <UsersProfileFormView
        value={value}
        errors={errors}
        loading={loading}
        componentRef={this.formRef}
        onChange={this.handleFormChange}
        onReset={this.resetForm}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(UserProfileForm);
