import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutlined,
  CloudSyncOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Typography } from "antd";
import _ from "lodash";
import store from "store";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/form_input";
import Loading from "components/loading";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import FormSelect from "../../../components/forms/inputs/form_select";

import useApplicationAction from "./hooks/use_application_action";

import styles from "./shared.module.css";

const { Applications } = store;

const buildSettings = (roomTypes, ratePlans, installation) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    if (!acc[roomType.id]) {
      acc[roomType.id] = {
        code: null,
        title: roomType.title,
        ratePlans: {},
      };
    }

    acc[roomType.id].ratePlans = _.reduce(ratePlans[roomType.id], (accRP, ratePlan) => {
      if (!accRP[ratePlan.id]) {
        accRP[ratePlan.id] = {
          code: null,
          title: ratePlan.title,
          settings: {
            occupancy: ratePlan.occupancy,
            primary: ratePlan.parent_rate_plan_id === null,
          },
        };
      }

      return accRP;
    }, acc[roomType.id].ratePlans);

    return acc;
  }, installation.settings || { hotelCode: null, clientId: null, clientSecret: null });
};

const buildFieldNames = (roomTypes, ratePlans) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    acc.push(`rooms.${roomType.id}.code`);

    _.forEach(ratePlans[roomType.id], (ratePlan) => {
      acc.push(`rooms.${roomType.id}.ratePlans.${ratePlan.id}.code`);
    });

    return acc;
  }, ["hotelCode"]);
};

function MappingStatus({ isApaleoMappingLoaded, isChannexMappingLoaded, apaleoData, channexMapping }) {
  if (!isApaleoMappingLoaded || !isChannexMappingLoaded) {
    return null;
  }

  const apaleoRoomCodes = apaleoData.roomTypes.map((rt) => rt.id);
  const apaleoRatePlans = apaleoData.roomTypes.reduce((acc, rt) => {
    return acc.concat(rt.ratePlans.map((rp) => `${rt.id}${rp.id}`));
  }, []);
  const mappedApaleoRoomCodes = Object.values(channexMapping).map((r) => r.code).filter((c) => !!c);
  const mappedApaleoRatePlanCodes = Object.values(channexMapping).reduce((acc, room) => {
    if (!room.ratePlans) {
      return acc;
    }

    const apaleoRoomCode = room.code;

    return acc.concat(Object.values(room.ratePlans).map((rp) => rp.code).filter((c) => !!c).map((c) => {
      return `${apaleoRoomCode}${c}`;
    }));
  }, []);

  const unmappedApaleoRoomCodes = apaleoRoomCodes.filter((c) => !mappedApaleoRoomCodes.includes(c));
  const unmappedApaleoRatePlanCodes = apaleoRatePlans.filter((c) => !mappedApaleoRatePlanCodes.includes(c));

  return (
    <div>
      {unmappedApaleoRoomCodes.length > 0
        ? (
          <div>
            <ExclamationCircleOutlined style={{ color: "#faad14" }} /> <Typography.Text type="secondary">Unmapped Apaleo Rooms: {unmappedApaleoRoomCodes.length}</Typography.Text>
          </div>
        )
        : (
          <div>
            <CheckCircleOutlined style={{ color: "#52c41a" }} /> <Typography.Text type="secondary">All Apaleo Rooms mapped</Typography.Text>
          </div>
        )}
      {unmappedApaleoRatePlanCodes.length > 0
        ? (
          <div>
            <ExclamationCircleOutlined style={{ color: "#faad14" }} /> <Typography.Text type="secondary">Unmapped Apaleo Rate Plans: {unmappedApaleoRatePlanCodes.length}</Typography.Text>
          </div>
        )
        : (
          <div>
            <CheckCircleOutlined style={{ color: "#52c41a" }} /> <Typography.Text type="secondary">All Apaleo Rate Plans mapped</Typography.Text>
          </div>
        )}
    </div>
  );
}

export default function ApaleoSettings({ installation, onClose }) {
  const { propertyId } = installation;

  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [settings, setSettings] = useState(null);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    setError,
    watch,
    control,
    setValue,
  } = useForm();

  const values = watch();

  const { data, refetch: loadMappingDetails, isSuccess: isApaleoMappingLoaded, isFetching: isApaleoMappingLoading } = useApplicationAction({
    appCode: "apaleo",
    action: "mapping_details",
    query: { propertyId },
  }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const apaleoRoomCodes = data.roomTypes.map((rt) => rt.id);
    const apaleoRatePlans = data.roomTypes.reduce((acc, rt) => {
      return acc.concat(rt.ratePlans.map((rp) => `${rt.id}${rp.id}`));
    }, []);

    Object.entries(values).forEach(([roomTypeId, room]) => {
      if (!room.code) {
        return;
      }

      if (!apaleoRoomCodes.includes(room.code)) {
        setValue(`${roomTypeId}.code`, null);
      }

      Object.entries(room.ratePlans).forEach(([ratePlanId, ratePlan]) => {
        if (!ratePlan.code) {
          return;
        }

        const apaleoRatePlanCode = `${room.code}${ratePlan.code}`;

        if (!apaleoRatePlans.includes(apaleoRatePlanCode)) {
          setValue(`${roomTypeId}.ratePlans.${ratePlanId}.code`, null);
        }
      });
    }, []);
  }, [data, values, setValue]);

  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, groupResults: true });
  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });
  const [isChannexMappingLoaded, setIsChannexMappingLoaded] = useState(false);

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return;
    }

    const initialSettings = buildSettings(roomTypes, ratePlans, installation);

    if (settings === null) {
      setSettings(initialSettings);
    }

    reset(initialSettings);
    setIsChannexMappingLoaded(true);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading, installation, settings, reset]);

  const isLoadButtonEnabled = !!settings?.hotelCode && !!settings?.clientId && !!settings?.clientSecret;

  useEffect(() => {
    if (isLoadButtonEnabled) {
      loadMappingDetails();
    }
  }, [isLoadButtonEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const formFieldNames = useMemo(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return [];
    }

    return buildFieldNames(roomTypes, ratePlans);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading]);

  const submit = (settingsData) => {
    setSaveInProgress(true);

    Applications
      .update({ ...installation, settings: settingsData })
      .then(onClose)
      .catch((error) => {
        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, formFieldNames);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, value);
        });
      })
      .finally(() => setSaveInProgress(false));
  };

  if (isRatePlansLoading || isRoomTypesLoading) {
    return <Loading />;
  }

  const isMappingLoaded = isApaleoMappingLoaded && data;

  const availableApaleoRoomTypes = (roomTypeId) => {
    if (!isMappingLoaded) {
      return [];
    }

    const mappedCodeToRoomTypeId = values?.[roomTypeId]?.code;
    const usedCodes = Object.values(values).map((r) => r.code).filter((c) => !!c);

    const availableCodes = data.roomTypes
      .filter((r) => {
        return !(usedCodes.includes(r.id) && r.id !== mappedCodeToRoomTypeId);
      });

    return availableCodes.map(({ id, title }) => ({ value: id, representation: title }));
  };

  const roomRateOptions = (roomTypeId) => {
    if (!values?.[roomTypeId]?.code) {
      return [];
    }

    const mappedApaleoRoomCode = values[roomTypeId].code;
    const apaleoRoom = data.roomTypes.find((r) => r.id === mappedApaleoRoomCode);

    if (!apaleoRoom) {
      return [];
    }

    return (apaleoRoom?.ratePlans || []).map(({ id, title }) => ({ value: id, representation: title }));
  };

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors.global} />

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:apaleo:hotel_code")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.inputCol}>
            <Controller
              name="hotelCode"
              control={control}
              render={({ field }) => (
                <div style={{ display: "flex" }}>
                  <FormInput
                    style={{ flex: 1 }}
                    hookFormq
                    view="fullWidth"
                    placeholder={t("applications_page:apaleo:hotel_code")}
                    errors={errors.hotelCode}
                    {...field}
                  />
                  <div style={{ lineHeight: "40px" }}>
                    <Button
                      disabled={!isLoadButtonEnabled}
                      loading={isApaleoMappingLoading}
                      type="primary"
                      icon={isApaleoMappingLoading ? <LoadingOutlined /> : <CloudSyncOutlined />}
                      onClick={() => {
                        loadMappingDetails();
                      }}
                    >
                      Load data
                    </Button>
                  </div>
                </div>
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:apaleo:client_id")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.inputCol}>
            <Controller
              name="clientId"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  view="fullWidth"
                  placeholder={t("applications_page:apaleo:client_id")}
                  errors={errors.hotelCode}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:apaleo:client_secret")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.inputCol}>
            <Controller
              name="clientSecret"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  view="fullWidth"
                  placeholder={t("applications_page:apaleo:client_secret")}
                  errors={errors.hotelCode}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>

        <MappingStatus
          isApaleoMappingLoaded={isApaleoMappingLoaded}
          isChannexMappingLoaded={isChannexMappingLoaded}
          apaleoData={data}
          channexMapping={values}
        />

        {isMappingLoaded && (
          <>
            <Row className={styles.columnHeaders}>
              <Col span={11}>{t("applications_page:apaleo:channel_manager")}</Col>
              <Col span={1}>&rarr;</Col>
              <Col span={12}>{t("applications_page:apaleo:apaleo")}</Col>
            </Row>

            {roomTypes.map((roomType) => (
              <div key={roomType.id}>
                <Row>
                  <Col span={11} className={styles.roomTitle}>
                    {roomType.title}
                  </Col>
                  <Col span={1} className={styles.arrow}>
                    &rarr;
                  </Col>
                  <Col span={12} className={styles.inputCol}>
                    <Controller
                      name={`${roomType.id}.code`}
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          hookForm
                          allowClear
                          view="fullWidth"
                          placeholder={t("applications_page:apaleo:room_type_code")}
                          errors={errors[`${roomType.id}.code`]}
                          disabled={availableApaleoRoomTypes(roomType.id).length === 0}
                          options={availableApaleoRoomTypes(roomType.id)}
                          {...field}
                          onChange={(e) => {
                            Object.keys(values[roomType.id].ratePlans).forEach((rpId) => {
                              setValue(`${roomType.id}.ratePlans.${rpId}.code`, null);
                            });

                            field.onChange(e || null);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>

                {!ratePlans[roomType.id] && <Typography.Text type="secondary">No Rate Plan</Typography.Text>}

                {ratePlans[roomType.id] && ratePlans[roomType.id].map((ratePlan) => {
                  return (
                    <Row key={ratePlan.id}>
                      <Col span={11} className={styles.rateTitle}>
                        {ratePlan.title} ({ratePlan.occupancy})
                      </Col>
                      <Col span={1} className={styles.arrow}>
                        &rarr;
                      </Col>
                      <Col span={12} className={styles.inputCol}>
                        <Controller
                          name={`${roomType.id}.ratePlans.${ratePlan.id}.code`}
                          control={control}
                          render={({ field }) => (
                            <FormSelect
                              hookForm
                              allowClear
                              view="fullWidth"
                              placeholder={t("applications_page:apaleo:rate_plan_code")}
                              errors={errors[`${roomType.id}.ratePlans.${ratePlan.id}.code`]}
                              disabled={roomRateOptions(roomType.id).length === 0}
                              options={roomRateOptions(roomType.id)}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e || null);
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            ))}
          </>
        )}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={() => { clearErrors(); handleSubmit(submit)(); }} loading={saveInProgress}>
          {t("applications_page:apaleo:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
