import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import styles from "styles/view_list.module.css";

class PropertyView extends Component {
  static propTypes = {
    model: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t, model } = this.props;

    return (
      model && (
        <dl className={styles.list}>
          <dt className={styles.list__term}>{t("properties_page:form:title_label")}:</dt>
          <dd className={styles.list__description}>{model.title}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:zip_code_label")}:</dt>
          <dd className={styles.list__description}>{model.zip_code}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:country_label")}:</dt>
          <dd className={styles.list__description}>{model.country}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:currency_label")}:</dt>
          <dd className={styles.list__description}>{model.currency}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:state_label")}:</dt>
          <dd className={styles.list__description}>{model.state}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:city_label")}:</dt>
          <dd className={styles.list__description}>{model.city}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:address_label")}:</dt>
          <dd className={styles.list__description}>{model.address}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:email_label")}:</dt>
          <dd className={styles.list__description}>{model.email}</dd>
          <dt className={styles.list__term}>{t("properties_page:form:phone_label")}:</dt>
          <dd className={styles.list__description}>{model.phone}</dd>
        </dl>
      )
    );
  }
}

const mapStateToProps = ({ properties }, { propertyId }) => {
  return {
    model: properties.entities[propertyId],
  };
};

export default withTranslation()(connect(mapStateToProps)(PropertyView));
