import React, { useState } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getIsAppInstalled } from "store/storage/selectors/applications_selector";
import { getActiveProperty, getAppMode } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import appModes from "config/constants/app_modes";

import Application from "components/application";

import NoAppPlaceholder from "./no_app_placeholder";
import ReviewApp from "./review_app";

import styles from "./reviews_page.module.css";

const applicationParams = { applicationCode: appCodes.messages };

export default function ReviewsPage() {
  const [isMobile, setIsMobile] = useState(false);
  const activeProperty = useSelector(getActiveProperty);
  const appMode = useSelector(getAppMode);
  const appParams = { ...applicationParams };

  if (activeProperty) {
    appParams.propertyId = activeProperty;
  }

  const isInstalled = useSelector(getIsAppInstalled(appParams));
  if (!isInstalled) {
    return <NoAppPlaceholder />;
  }

  const handleMediaChange = (isMobileMode) => (matches) => matches && setIsMobile(isMobileMode);

  const containerClassName = classNames(styles.layout, {
    [styles.layout__headless]: appMode === appModes.HEADLESS,
    [styles.layout__mobile]: isMobile,
  });

  return (
    <>
      <Media query="(max-width: 930px)" onChange={handleMediaChange(true)} />
      <Media query="(min-width: 931px)" onChange={handleMediaChange(false)} />

      <Application className={containerClassName}>
        <ReviewApp isMobile={isMobile} />
      </Application>
    </>
  );
}
