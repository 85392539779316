import React, { Component } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Tag } from "antd";
import store from "store";

import ChannelManagementDrawerControlled from "drawers/channel_management_drawer_controlled";

import LinkButton from "components/forms/buttons/link_button";
import List from "components/list";
import Loading from "components/loading";

import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";

import ChannelEventsLogsList from "./channel_events_logs_list";

import styles from "./channel_events.module.css";

const { ChannelEvents } = store;

const diffStyles = {
  diffContainer: {
    fontSize: "10px",
  },
};

class ChannelEventsView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actionId: PropTypes.string.isRequired,
  };

  state = {
    loading: true,
    model: {},
    isChannelDrawerOpen: false,
  };

  t = (word) => {
    const { t } = this.props;
    return t(`channel_events_page:channel_events_view_dialog:${word}`);
  };

  componentDidMount() {
    const { location, history } = this.props;

    if (location.state && location.state.action) {
      this.setState({
        loading: false,
        model: location.state.action,
      });
      history.replace(); // TODO: why it is needed

      return;
    }

    this.loadAction();
  }

  loadAction = () => {
    const { actionId } = this.props;

    return ChannelEvents.find(actionId).then((issue) => {
      this.setState({
        loading: false,
        model: issue,
      });
    });
  };

  toggleChannelDrawerVisibility = () => {
    this.setState(({ isChannelDrawerOpen }) => ({ isChannelDrawerOpen: !isChannelDrawerOpen }));
  };

  renderTimestamps = () => {
    const { t } = this.props;
    const { model } = this.state;
    const {
      inserted_at,
      payload: { started_at, finished_at },
    } = model;

    if (!started_at || !finished_at) {
      return (
        <List.Item
          term={this.t("inserted_at")}
          description={formatToLocal(inserted_at, t("formats:iso_date_with_miliseconds"))}
        />
      );
    }

    return (
      <>
        {started_at && (
          <List.Item
            term={this.t("started_at")}
            description={formatToLocal(started_at, t("formats:iso_date_with_miliseconds"))}
          />
        )}
        {finished_at && (
          <List.Item
            term={this.t("finished_at")}
            description={formatToLocal(finished_at, t("formats:iso_date_with_miliseconds"))}
          />
        )}
      </>
    );
  };

  renderUserName = () => {
    const { model } = this.state;
    const { user } = model;

    if (user) {
      return (
        <List.Item
          term={this.t("user")}
          description={`${user.name} (${user.email})`}
        />
      );
    }

    return (
      <List.Item
        term={this.t("user")}
        description="System"
      />
    );
  };

  getResultTag = () => {
    const { model } = this.state;

    if (!model.payload.result) {
      return null;
    }

    return model.payload.result === "success" ? (
      <Tag color="green">Success</Tag>
    ) : (
      <Tag color="red">Failure</Tag>
    );
  };

  renderChannelLink = () => {
    const { model } = this.state;
    const { channel } = model;

    if (!channel) {
      return null;
    }

    return (
      <List.Item
        term={this.t("channel")}
        description={
          <LinkButton onClick={this.toggleChannelDrawerVisibility}>
            {channel.attributes.title}
          </LinkButton>
        }
      />
    );
  };

  renderMappingChanges = (model) => {
    if (model.name === "mapping_updated") {
      return (
        <div className={styles.diffContainer}>
          <ReactDiffViewer
            styles={diffStyles}
            disableWordDiff
            oldValue={JSON.stringify(model.payload.old_mappings, true, 2)}
            newValue={JSON.stringify(model.payload.new_mappings, true, 2)}
            splitView={false}
          />
        </div>
      );
    }

    return null;
  };

  renderSettingsChanges = (model) => {
    if (model.name === "channel_settings_updated") {
      return (
        <div className={styles.diffContainer}>
          <ReactDiffViewer
            styles={diffStyles}
            disableWordDiff
            oldValue={JSON.stringify(model.payload.old_settings, true, 2)}
            newValue={JSON.stringify(model.payload.new_settings, true, 2)}
            splitView={false}
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const { t, actionId } = this.props;
    const { loading, model, isChannelDrawerOpen } = this.state;
    const { payload, name, channel_id: channelId } = model;
    const { logs_count, execution_time, user } = payload || {};

    if (loading) {
      return <Loading />;
    }

    const logsSection = logs_count ? <ChannelEventsLogsList actionId={actionId} /> : null;
    const resultTag = this.getResultTag();
    const isExecutionTimePresent = execution_time !== undefined;

    return (
      <>
        <List>
          <List.Item term={this.t("event")} description={t(`issues:types:${name}`)} />
          {user && <List.Item term={this.t("user")} description={user.name} />}
          {this.renderTimestamps()}
          {isExecutionTimePresent && (
            <List.Item term={this.t("execution_time")} description={execution_time} />
          )}
          {resultTag && <List.Item term={this.t("result")} description={resultTag} />}
          {this.renderChannelLink()}
          {this.renderUserName()}
        </List>
        {this.renderMappingChanges(model)}
        {this.renderSettingsChanges(model)}
        {logsSection}
        <ChannelManagementDrawerControlled
          channelId={channelId}
          visible={isChannelDrawerOpen}
          onClose={this.toggleChannelDrawerVisibility}
        />
      </>
    );
  }
}

export default withTranslation()(withRouter(ChannelEventsView));
