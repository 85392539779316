import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import getChannelInfoByCode from "utils/get_channel_info_by_code";

import styles from "./events_list.module.css";

const getChannelIcon = (booking_unique_id) => {
  if (booking_unique_id) {
    const channelCode = booking_unique_id.substr(0, 3);

    const channel = getChannelInfoByCode(channelCode);

    if (channel && channel.icon) {
      return <img src={channel.icon} className={styles.icon} alt={channel.title} />;
    }
    return <div className={styles.emptyIcon}>{channelCode}</div>;
  }
  return null;
};

function EventsListItemAlterationRequest({ item }) {
  const { t } = useTranslation();
  const { event, payload } = item;

  const unique_id = payload.bms ? payload.bms.unique_id : payload.unique_id;
  const status = payload.bms ? payload.bms.status : payload.status;
  const count_of_rooms = 1;
  const count_of_nights = payload.bms ? payload.bms.raw_message.reservation.nights : payload.nights;

  const eventTitle = useMemo(() => {
    return t("live_feed_events_widget:events:alteration_request").toUpperCase();
  }, [t]);

  const nightPostFix = count_of_nights > 1 ? "s" : "";
  const roomPostFix = count_of_rooms > 1 ? "s" : "";

  const channelIcon = getChannelIcon(unique_id);
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);

  return (
    <>
      <div>
        <p className={eventTitleClassName}>{eventTitle}</p>
        <p className={styles.arrivalDate}>{unique_id}</p>

        <p className={styles.description}>
          {t("live_feed_events_widget:status")}: <code>{status}</code> <span>|</span>{" "}
          {count_of_rooms} {t("live_feed_events_widget:room")}
          {roomPostFix} <span>x</span> {count_of_nights} {t("live_feed_events_widget:night")}
          {nightPostFix}
        </p>
      </div>
      <div className={styles.right}>
        {channelIcon}
        <p className={styles.amount}>{payload.expected_payout_amount_accurate}</p>
      </div>
    </>
  );
}

EventsListItemAlterationRequest.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemAlterationRequest;
