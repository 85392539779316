import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { getActiveGroup, getActiveProperty, getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import EventEmmiter from "utils/event_emmiter";

import useUserChannelSubscription from "./data_loader_hooks/use_user_channel_subscription";

const { Properties, Groups } = store;

export default function DataLoader() {
  const appMode = useSelector(getAppMode);
  const activeProperty = useSelector(getActiveProperty);
  const activeGroup = useSelector(getActiveGroup);
  useUserChannelSubscription();

  const getFilters = useCallback(() => {
    const filters = {};

    if (appMode === APP_MODES.HEADLESS) {
      if (activeProperty) {
        filters.id = activeProperty;
      }

      if (activeGroup) {
        filters.group_id = activeGroup;
      }
    }

    return filters;
  }, [appMode, activeProperty, activeGroup]);

  const loadPropertiesOptions = useCallback(async () => {
    const filter = getFilters();
    const options = await Properties.options(filter);

    Properties.setOptions(options);
  }, [getFilters]);

  const loadGroupsOptions = useCallback(async () => {
    const filter = getFilters();
    const options = await Groups.options(filter);

    Groups.setOptions(options);
  }, [getFilters]);

  useEffect(() => { loadGroupsOptions(); }, [loadGroupsOptions]);
  useEffect(() => { loadPropertiesOptions(); }, [loadPropertiesOptions]);

  useEffect(() => {
    EventEmmiter.bind("property:deleted", loadPropertiesOptions);
    EventEmmiter.bind("property:created", loadPropertiesOptions);
    EventEmmiter.bind("property:updated", loadPropertiesOptions);
    EventEmmiter.bind("onboarding:confirmed", loadPropertiesOptions);
    EventEmmiter.bind("group:updated", loadGroupsOptions);
    EventEmmiter.bind("group:created", loadGroupsOptions);
    EventEmmiter.bind("group:deleted", loadGroupsOptions);

    return () => {
      EventEmmiter.unbind("property:deleted", loadPropertiesOptions);
      EventEmmiter.unbind("property:created", loadPropertiesOptions);
      EventEmmiter.unbind("property:updated", loadPropertiesOptions);
      EventEmmiter.unbind("onboarding:confirmed", loadPropertiesOptions);
      EventEmmiter.unbind("group:updated", loadGroupsOptions);
      EventEmmiter.unbind("group:created", loadGroupsOptions);
      EventEmmiter.unbind("group:deleted", loadGroupsOptions);
    };
  }, [loadPropertiesOptions, loadGroupsOptions]);

  return null;
}
