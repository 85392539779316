import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Result } from "antd";
import store from "store";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import Loading from "components/loading";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import MissingEntry from "./missing_entry";

const { Channels } = store;

export default function MissingContent({ channelId, propertiesOptionsById, onChannelSettingsNavigateClick }) {
  const { t } = useTranslation();
  const [missedContent, setMissedContent] = useState({});
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const missingEntryIds = Object.keys(missedContent);

  const loadReadinessStatus = useCallback(() => {
    setLoading();

    Channels.checkReadiness(channelId)
      .then(setMissedContent)
      .catch(showErrorMessageFromResponse)
      .finally(setLoadingComplete);
  }, [channelId, setLoading, setLoadingComplete]);

  useEffect(
    function initReadinessInfo() {
      loadReadinessStatus();
    },
    [loadReadinessStatus],
  );

  if (loading) {
    return <Loading />;
  }

  if (!missingEntryIds.length) {
    return (
      <Result
        status="success"
        title={t("channels_page:form:missing_content:success_message")}
      />
    );
  }

  return missingEntryIds.map((propertyId) => (
    <Form.Item key={propertyId} {...horizontalFormItemLayout} label={propertiesOptionsById[propertyId].title}>
      {missedContent[propertyId].map((missingContent) => (
        <MissingEntry
          propertyId={propertyId}
          missingContent={missingContent}
          onReload={loadReadinessStatus}
          onChannelSettingsNavigateClick={onChannelSettingsNavigateClick}
        />
      ))}
    </Form.Item>
  ));
}
