import React, { cloneElement, useCallback, useEffect, useMemo, useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import usePagination from "utils/use_pagination";

import styles from "./infinite_list.module.css";

function InfiniteList({
  list,
  meta,
  loading,
  loadMore,
  className,
  defaultCacheProps,
  itemComponent,
  loadTopScroll,
  loadBottomScroll,
}) {
  const cache = useMemo(() => new CellMeasurerCache(defaultCacheProps), [defaultCacheProps]);
  const { nextPage, isLastPage } = usePagination(meta);
  const listRef = useRef();

  const rowCountTotal = meta.total;
  const rowCountLoaded = list.length;

  const rowRenderer = useCallback(
    ({ index, key, parent, style }) => {
      const item = list[index];
      const isRowLoaded = !isLastPage || item;

      if (isRowLoaded) {
        return (
          <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
            {cloneElement(itemComponent, {
              key,
              item,
              style,
            })}
          </CellMeasurer>
        );
      }
      return null;
    },
    [cache, itemComponent, list, isLastPage],
  );

  const loadMoreRows = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e;
    const atTop = scrollTop === 0 && clientHeight !== 0 && loadTopScroll;
    const atBottom = scrollTop + clientHeight >= scrollHeight && loadBottomScroll;
    const isLoading = !isLastPage && rowCountLoaded < rowCountTotal;

    if (atTop && isLoading) {
      loadMore(nextPage);
    }

    if (atBottom && isLoading) {
      loadMore(nextPage);
    }
  };

  useEffect(
    function updateListItems() {
      if (!listRef.current) {
        return;
      }

      cache.clearAll();
      listRef.current.recomputeRowHeights();
    },
    [list, listRef, cache],
  );

  const getRowHeight = ({ index }) => cache.getHeight(index);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <List
            className={className}
            rowRenderer={rowRenderer}
            ref={listRef}
            rowCount={rowCountLoaded}
            rowHeight={getRowHeight}
            height={height}
            width={width}
            onScroll={loadMoreRows}
          />
          {loading && (
            <div className={styles.spinWrapper}>
              <Spin indicator={<LoadingOutlined />} size="large" />
            </div>
          )}
        </>
      )}
    </AutoSizer>
  );
}

InfiniteList.propTypes = {
  list: PropTypes.array,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  loadMore: PropTypes.func,
  className: PropTypes.string,
  defaultCacheProps: PropTypes.object,
  itemComponent: PropTypes.node,
  loadTopScroll: PropTypes.bool,
  loadBottomScroll: PropTypes.bool,
};

export default InfiniteList;
