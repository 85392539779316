import React from "react";

import Text from "../../../../../_common/text";

export default function ReplyMessage({ review }) {
  const { attributes: { reply } } = review;

  return (
    <Text>{reply.reply}</Text>
  );
}
