import store from "store";

import AppStorageManager from "utils/app_storage_manager";

const { Auth } = store;

export default async function logout() {
  try {
    await Auth.signOut();
  } finally {
    AppStorageManager.clear();
    window.location.assign("/");
  }
}
