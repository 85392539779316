import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import store from "store";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";
import PageHeader from "components/page_header";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";
import EventEmmiter from "utils/event_emmiter";

import styles from "styles/dashboard.module.css";

const { ChannelEvents } = store;

const DEFAULT_ORDER = { inserted_at: "desc" };

class IssuesPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    columnsToHide: 0,
    tableQuery: "",
  };

  tableRef = React.createRef();

  actions = () => {
    const { t, routes } = this.props;

    return (text, record) => (
      <span>
        <Link to={pathBuilder(routes.userAppRoutes.issues.view, { issueId: record.id })}>
          {t("general:action:view")}
        </Link>
      </span>
    );
  };

  componentDidMount() {
    EventEmmiter.bind("issues:reload", this.reloadTable);
  }

  componentWillUnmount() {
    EventEmmiter.unbind("issues:reload", this.reloadTable);
  }

  componentDidUpdate(prevProps) {
    const { activeGroup, activeProperty } = this.props;

    if (activeGroup !== prevProps.activeGroup || activeProperty !== prevProps.activeProperty) {
      const { current } = this.tableRef;

      if (current && current.resetTable) {
        current.resetTable();
      }
    }
  }

  reloadTable = () => {
    const { current } = this.tableRef;

    if (current && current.reloadTable) {
      current.reloadTable();
    }
  };

  loadData = (query, pagination, order) => {
    const { activeGroup, activeProperty } = this.props;

    const filter = { type: ["notification", "error"] };

    if (activeGroup) {
      filter.group_id = activeGroup;
    }
    if (activeProperty) {
      filter.property_id = activeProperty;
    }

    return ChannelEvents.list(filter, pagination, order);
  };

  columns = () => {
    const { t, routes } = this.props;

    let dataColumns = [
      {
        title: "",
        key: "icon",
        className: styles.dashboard__widget_id_column,
        render: (text, { payload }) => {
          const { resolved_at } = payload || {};
          let icon = <WarningOutlined />;
          let iconStyle = "unresolved";

          if (resolved_at) {
            icon = <CheckCircleOutlined />;
            iconStyle = "resolved";
          }

          return (
            <span
              className={[styles.dashboard__icons, styles[`dashboard__icons__${iconStyle}`]].join(
                " ",
              )}
            >
              {icon}
            </span>
          );
        },
      },
      {
        title: "Type",
        key: "type",
        render: (text, record) => {
          const { id, name } = record;

          return (
            <Link
              to={{
                pathname: pathBuilder(routes.userAppRoutes.issues.view, { issueId: id }),
                state: { issue: record },
              }}
            >
              {t(`issues:types:${name}`)}
            </Link>
          );
        },
      },
      {
        title: "Created At",
        key: "date",
        render: (text, record) => {
          return (
            <div className={[styles.dashboard__block__values__left].join(" ")}>
              {formatToLocal(record.inserted_at, t("formats:date_time_with_seconds"))}
            </div>
          );
        },
      },
    ];

    const actionColumns = [
      {
        title: t("issues_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions(),
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("issues_page:empty_message");
  }

  pageTitle() {
    const { t } = this.props;
    return t("issues_page:header");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { tableQuery } = this.state;
    const { routes } = this.props;

    const basePath = pathBuilder(routes.userAppRoutes.issues);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  render() {
    const { t, data } = this.props;
    const closePath = this.getClosePath();

    return (
      <Page.Main>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <Page.Content>
          <PageHeader title={t("issues_page:header")} />
          <CRUDTable.Container>
            <CRUDTable
              data={data}
              componentRef={this.tableRef}
              defaultOrder={DEFAULT_ORDER}
              columns={this.columns}
              emptyMessage={this.emptyMessage()}
              showCreateMessage={false}
              topBar={false}
              onTableQueryChange={this.handleTableQueryChange}
              loadData={this.loadData}
            />
          </CRUDTable.Container>
        </Page.Content>

        <Outlet context={{ closePath }} />
      </Page.Main>
    );
  }
}

const mapStateToProps = ({ channelEvents, session }) => {
  const { entities } = channelEvents;

  return {
    data: entities ? Object.values(entities) : null,
    activeGroup: session.activeGroup,
    activeProperty: session.activeProperty,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(IssuesPage)));
