import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form, notification, Typography } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormRate from "components/forms/inputs/form_rate";
import FormTextarea from "components/forms/inputs/form_textarea";

import dayjs from "utils/dayjs";

import styles from "./shared.module.css";

const { Title } = Typography;
const { Reviews } = store;
const EXPIRATION_TIMEFRAME_IN_DAYS = 30;

function Hidden({ review }) {
  const { t } = useTranslation();
  const [guestReview, setGuestReview] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    attributes: { receivedAt },
  } = review;

  useEffect(
    function loadRequiredAppSettings() {
      setGuestReview({});
      setLoading(false);
    },
    [review],
  );

  const submitGuestReview = () => {
    setLoading(true);
    Reviews.guestReview(review.id, guestReview).then(
      (_repliedReview) => {
        setLoading(false);
        Reviews.review(review.id);
      },
      (error) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: error.errors?.title || "N/A",
        });
      },
    );
  };

  const handleChange = (field, fieldValue) => {
    setGuestReview({ ...guestReview, [field]: fieldValue });
  };

  const handleCheckBoxChange = (field) => (fieldValue) => {
    handleChange(field, fieldValue);
  };

  const isDisabled = () => {
    return !(
      guestReview.publicReview
      && guestReview.respectHouseRules
      && guestReview.cleanliness
      && guestReview.communication
    );
  };

  const daysAgo = dayjs().diff(dayjs(receivedAt), "days");
  const isExpired = daysAgo >= EXPIRATION_TIMEFRAME_IN_DAYS;

  return (
    <div className={styles.feedbackContent}>
      {isExpired ? (
        <p className={styles.feedback}>{t("reviews_page:feedback:not_allowed_for_expired")}</p>
      ) : (
        <>
          <p className={styles.feedback}>
            Review is hidden till you provide your feedback to Guest.
          </p>
          <Title level={4}>{t("reviews_page:feedback:guest_review_subheader")}</Title>
          <Form onFinish={submitGuestReview}>
            <FormTextarea
              label={t("reviews_page:feedback:private_review")}
              placeholder={t("reviews_page:feedback:private_review")}
              name="privateReview"
              value={guestReview.privateReview}
              onChange={handleChange}
            />
            <FormTextarea
              label={t("reviews_page:feedback:public_review")}
              placeholder={t("reviews_page:feedback:public_review")}
              name="publicReview"
              value={guestReview.publicReview}
              onChange={handleChange}
            />
            <FormCheckbox
              label={t("reviews_page:feedback:recommend")}
              value={guestReview.isRevieweeRecommended}
              onChange={handleCheckBoxChange("isRevieweeRecommended")}
            />
            <FormRate
              label={t("reviews_page:feedback:respect_house_rules")}
              name="respectHouseRules"
              value={guestReview.respectHouseRules}
              onChange={handleChange}
            />
            <FormRate
              label={t("reviews_page:feedback:communication")}
              name="communication"
              value={guestReview.communication}
              onChange={handleChange}
            />
            <FormRate
              label={t("reviews_page:feedback:cleanliness")}
              name="cleanliness"
              value={guestReview.cleanliness}
              onChange={handleChange}
            />

            <SubmitButton disabled={isDisabled()} loading={loading}>
              {t("reviews_page:feedback:send_review")}
            </SubmitButton>
          </Form>
        </>
      )}
    </div>
  );
}

Hidden.propTypes = {
  review: PropTypes.object,
};

export default Hidden;
