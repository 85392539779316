import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import _ from "lodash";
import store from "store";
import { getActiveProperty } from "store/storage/selectors/session_selector";

import channelNames from "config/constants/channels/channel_codes";

import Loading from "components/loading";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import useBoolState from "utils/use_bool_state";

import styles from "./score_statistics.module.css";

const { Title } = Typography;
const { Scores } = store;

function ScoreDetails({ isMobile }) {
  const { t } = useTranslation();
  const activeProperty = useSelector(getActiveProperty);
  const [loading, _setTrueLoading, setFalseLoading] = useBoolState(true);
  const [score, setScore] = useState(null);
  const { userAppRoutes } = useRouting();
  const path = pathBuilder(userAppRoutes.reviews);

  useEffect(
    function loadScores() {
      Scores.scoreDetailed(activeProperty).then((response) => {
        setScore(response);
        setFalseLoading();
      });
    },
    [activeProperty, setScore, setFalseLoading],
  );

  const header = isMobile ? (
    <>
      <NavLink to={`${path}?is_active=false`} className={styles.backLink}>
        <ArrowLeftOutlined />
        {t("messages_page:dialog:back_link")}
      </NavLink>
      <div>{t("reviews_page:scores:sub_page_title")}</div>
    </>
  ) : (
    t("reviews_page:scores:sub_page_title")
  );

  return (
    <Card className={styles.wrapper} title={header}>
      {loading ? (
        <Loading />
      ) : (
        score.relationships.otaScores.map((otaScore) => {
          const {
            data: { attributes },
          } = otaScore;
          const { scores } = attributes;
          const { title: channelTitle } = channelNames[attributes.ota];

          return (
            <div key={otaScore.data.id} className={styles.scoreRow}>
              <Title level={5}>
                {channelTitle}: {attributes.overallScore} / 10
              </Title>
              <Row justify="space-between">
                {_.keys(scores).map((scoreKey) => {
                  const scoreItem = scores[scoreKey];

                  return (
                    <Col span={3} key={scoreKey} className={styles.scoreBlock}>
                      <Statistic
                        title={t(`reviews_page:scores:${scoreKey}`)}
                        value={scoreItem.score}
                        suffix="/ 10"
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })
      )}
    </Card>
  );
}

ScoreDetails.propTypes = {};

export default ScoreDetails;
