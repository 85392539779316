import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SyncOutlined } from "@ant-design/icons";
import { Button, message } from "antd";

import RefreshButton from "components/forms/buttons/refresh_button";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import PublishSelect from "./publish_select";

import styles from "./listing_row.module.css";

const SYNC_DELAY = 1000;

export default function AirBnbListing(props) {
  const { t } = useTranslation();
  const {
    listing,
    onPublishClick,
    onUnpublishClick,
    onAvailabilityClick,
    onPriceClick,
    onSyncClick,
  } = props;
  const { title, published, listing_id: listingId, sync_category: syncCategory } = listing;
  const isListingLoading = syncCategory === "pending";

  const handlePublishClick = useCallback(() => {
    return onPublishClick(listingId);
  }, [listingId, onPublishClick]);

  const handleUnpublishClick = useCallback(() => {
    return onUnpublishClick(listingId);
  }, [listingId, onUnpublishClick]);

  const handleAvailabilityClick = useCallback(() => {
    onAvailabilityClick(listingId);
  }, [listingId, onAvailabilityClick]);

  const handlePriceClick = useCallback(() => {
    onPriceClick(listingId);
  }, [listingId, onPriceClick]);

  const handleSyncClick = useCallback(() => {
    const emulatedDelay = new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));

    return Promise.all([onSyncClick(listingId), emulatedDelay])
      .then(() => {
        message.success(t("channels_page:form:listing:sync_success"));

        return null;
      })
      .catch(showErrorMessageFromResponse);
  }, [t, listingId, onSyncClick]);

  return (
    <div className={styles.listingRow}>
      <div className={styles.listingTitle}>
        {`${title} (${listingId})`}
        {isListingLoading && <SyncOutlined spin className={styles.listingLoadingIcon} />}
      </div>
      <PublishSelect
        published={published}
        disabled={isListingLoading}
        onPublish={handlePublishClick}
        onUnpublish={handleUnpublishClick}
      />
      <Button type="link" disabled={isListingLoading} onClick={handlePriceClick}>
        {t("channels_page:form:listing:price_settings")}
      </Button>
      <Button type="link" disabled={isListingLoading} onClick={handleAvailabilityClick}>
        {t("channels_page:form:listing:availability_settings")}
      </Button>
      <RefreshButton onClick={handleSyncClick}>
        {t("channels_page:form:listing:sync")}
      </RefreshButton>
    </div>
  );
}
