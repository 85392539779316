export const features = {
  RATE_PLAN_QUICK_VIEW: "ratePlanQuickview",
  ONBOARDING: "onboarding",
  RATE_CATEGORIES: "rateCategories",
  CHANNEL_DERIVED_OPTIONS: "channelDerivedOptions",
  GROUP_PROPERTY_MANAGEMENT: "groupPropertyManagement",
  BOOKING_SOURCES_WIDGET: "bookingSourcesWidget",
  LIVE_FEED_NOTIFICATIONS: "liveFeedNotifications",
  DISABLE_USER_MANAGEMENT: "disableUserManagement",
  ENABLE_USER_MANAGEMENT: "enableUserManagement",
  NEW_REVIEW: "newReview",
  TRIPLA_IMPORT: "triplaImportEnabled",
};

export default {
  [features.RATE_PLAN_QUICK_VIEW]: false,
  [features.ONBOARDING]: false,
  [features.RATE_CATEGORIES]: false,
  [features.CHANNEL_DERIVED_OPTIONS]: true,
  [features.GROUP_PROPERTY_MANAGEMENT]: true,
  [features.BOOKING_SOURCES_WIDGET]: true,
  [features.LIVE_FEED_NOTIFICATIONS]: false,
  [features.DISABLE_USER_MANAGEMENT]: false,
  [features.ENABLE_USER_MANAGEMENT]: false,
  [features.NEW_REVIEW]: true,
  [features.TRIPLA_IMPORT]: false,
};
