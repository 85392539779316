import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./timings_chart.module.css";

const COLORS = [
  "#ff4d4f",
  "#ff7a45",
  "#ffa940",
  "#ffc53d",
  "#ffec3d",
  "#bae637",
  "#73d13d",
  "#36cfc9",
  "#40a9ff",
  "#597ef7",
  "#9254de",
  "#f759ab",
];

class TimingsChart extends Component {
  static propTypes = {
    timings: PropTypes.array.isRequired,
  };

  calcTotalExecutionTime(timings) {
    return timings.reduce((acc, el) => {
      return acc + el[1];
    }, 0);
  }

  calcTimingsWithOffset(timings) {
    let offset = 0;
    return timings.map((el) => {
      el[2] = offset;
      offset += el[1];
      return el;
    });
  }

  getBackgroundColor(index) {
    return COLORS[index - Math.floor(index / COLORS.length) * COLORS.length];
  }

  render() {
    const { timings } = this.props;
    const timingsWithOffset = this.calcTimingsWithOffset(timings);
    const totalExecutionTime = this.calcTotalExecutionTime(timings);

    return (
      <div className={styles.timings}>
        <div className={styles.timings__titles}>
          {timings.map((el) => {
            return <div key={`${el[0]}_title`}>{el[0]}</div>;
          })}
        </div>
        <div className={styles.timings__bars}>
          {timingsWithOffset.map((el, index) => {
            const width = (el[1] / totalExecutionTime) * 100;
            const style = {
              width: width >= 0.1 ? `${(el[1] / totalExecutionTime) * 100}%` : "1px",
              marginLeft: `${(el[2] / totalExecutionTime) * 100}%`,
              background: this.getBackgroundColor(index),
            };

            return <div key={`${el[0]}_bar`} className={styles.timings__bar} style={style} />;
          })}
        </div>
        <div className={styles.timings__times}>
          {timings.map((el) => {
            return <div key={`${el[0]}_time`}>{el[1]} μs</div>;
          })}
        </div>
      </div>
    );
  }
}

export default TimingsChart;
