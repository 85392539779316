import React, { useMemo } from "react";

import ErrorMessage from "./error_message";

import styles from "./error_section.module.css";

export default function ErrorSection({ title, errors, ratePlansById }) {
  const errorsByRate = useMemo(() => {
    return errors.reduce((acc, error) => {
      const id = `${error.property_id}_${error.rate_plan_id}`;

      const { [id]: groupedErrors = [] } = acc;
      const updatedErrors = [...groupedErrors, error];

      return { ...acc, [id]: updatedErrors };
    }, {});
  }, [errors]);

  return (
    <div>
      <legend className={styles.title}>{title}</legend>
      <div className={styles.rateContainer}>
        {Object.keys(errorsByRate).map((errorKey) => (
          <ErrorMessage
            key={errorKey}
            errors={errorsByRate[errorKey]}
            ratePlansById={ratePlansById}
          />
        ))}
      </div>
    </div>
  );
}
