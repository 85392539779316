import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";
import store from "store";

import AuthFormTitle from "components/auth_form_title";

import withFormSubmission from "containers/with_form_submission";

import withRouter from "routing/with_router";

import AccountDetailsForm from "./components/account_details_form";

const { Auth } = store;

function ConfirmInvite({ isSubmitting, makeRequest }) {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (form) => {
    const query = queryString.parse(window.location.search);
    const token = query.token;

    const attrs = {
      invitation_token: token,
      ...form,
    };

    makeRequest(Auth.confirmInvite(attrs))
      .then(() => {
        navigate("/", {
          state: {
            notification: t("account_confirmation:success"),
          },
        });
      })
      .catch((err) => {
        setErrors(err);
      });
  };

  return (
    <>
      <AuthFormTitle isOnly>{t("confirm_invite_page:header")}</AuthFormTitle>
      <AccountDetailsForm onSubmit={handleSubmit} loading={isSubmitting} errors={errors} />
    </>
  );
}

ConfirmInvite.propTypes = {
  makeRequest: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default withFormSubmission(withRouter(ConfirmInvite));
