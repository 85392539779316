import React, { Component } from "react";
import store from "store";

import Loading from "components/loading";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import RoomSpaceForm from "../room_space_form";

const { RoomSpaces } = store;

class RoomSpaceFormContainer extends Component {
  state = {
    loading: true,
    submitting: false,
    value: {},
    errors: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { entityId } = this.props;

    if (entityId) {
      this.loadEntity();
    } else {
      this.setState({ loading: false });
    }
  }

  loadEntity = () => {
    const { roomId, entityId } = this.props;

    RoomSpaces.find(roomId, entityId).then((value) => {
      this.setState({ value, loading: false });
    });
  };

  handleSubmit = () => {
    const { roomId, entityId, onClose, onCreate = () => {} } = this.props;
    const { value } = this.state;

    const submitHandler = entityId ? RoomSpaces.update : RoomSpaces.create;

    this.setState({ submitting: true });

    this.formRef.current
      .validate()
      .then(() => {
        submitHandler(roomId, { ...value, roomTypeId: roomId })
          .then((result) => {
            this.setState({ submitting: false });

            onCreate(result);
            onClose();
          })
          .catch((payload) => {
            const errors = parseApiErrors(payload);

            this.setState({ submitting: false, errors });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  beforeClose = () => {
    const { current } = this.formRef;

    if (!current) {
      return Promise.resolve();
    }

    const { dirty } = current.formik.current;

    return confirmDirtyStateSave(dirty);
  };

  render() {
    const { value, loading, submitting, errors } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <RoomSpaceForm
        value={value}
        errors={errors}
        submitting={submitting}
        componentRef={this.formRef}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withComponentRef(RoomSpaceFormContainer);
