import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import useBoolState from "utils/use_bool_state";

import State from "./state";

import generalStyles from "styles/general.module.css";

export default function Actions({ id, onEdit, onDelete }) {
  const [loading, setLoading, setComplete] = useBoolState(false);
  const { t } = useTranslation();

  const handleEdit = useCallback(() => {
    onEdit(id);
  }, [id, onEdit]);

  const handleDelete = useCallback(async () => {
    setLoading();

    try {
      onDelete(id);
    } finally {
      setComplete();
    }
  }, [id, onDelete, setLoading, setComplete]);

  const items = [{
    key: "actions_menu_edit",
    label: (
      <div data-cy="actions_menu_edit" onClick={handleEdit}>
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  }, {
    key: "actions_menu_remove",
    label: (
      <div data-cy="actions_menu_remove" onClick={handleDelete}>
        <DeleteOutlined /> {t("general:action:remove")}
      </div>
    ),
  }];

  return (
    <>
      <State isLoading={loading} />
      <Dropdown menu={{ items }} trigger={["click"]}>
        <a
          data-cy="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(e) => e.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    </>
  );
}
