import React, { useCallback, useState } from "react";

import PropertyFormDrawerControlled from "drawers/property_form_drawer_controlled";

import useBoolState from "utils/use_bool_state";

import MissingContentPropertyField from "./missing_content_property_field";

export default function MissingContentProperty({ propertyId, missingContent, onReload }) {
  const [isOpen, setDrawerOpen, setDrawerClosed] = useBoolState(false);
  const [tabToOpen, setTabToOpen] = useState(null);
  const [fieldToFocus, setFieldToFocus] = useState(null);

  const handleFieldClick = useCallback(
    (fieldTab, field) => {
      setTabToOpen(fieldTab);
      setFieldToFocus(field);
      setDrawerOpen();
    },
    [setDrawerOpen],
  );

  const handleDrawerClose = useCallback(() => {
    onReload();
    setDrawerClosed();
  }, [onReload, setDrawerClosed]);

  return (
    <div>
      <MissingContentPropertyField entry={missingContent} onClick={handleFieldClick} />

      <PropertyFormDrawerControlled
        visible={isOpen}
        defaultTab={tabToOpen}
        focusField={fieldToFocus}
        propertyId={propertyId}
        onClose={handleDrawerClose}
      />
    </div>
  );
}
