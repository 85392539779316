import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import _ from "lodash";
import { getUser, getWhiteLabelFlag } from "store/storage/selectors/session_selector";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";
import WHITE_LABEL_FLAGS from "config/constants/white_label_flags";

import Avatar from "components/users/avatar/avatar";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import AppStorageManager from "utils/app_storage_manager";
import logout from "utils/logout";

import styles from "./user_drop_down.module.css";

function UserDropDown({ isMobile }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const isDisableFeedback = useSelector(getWhiteLabelFlag(WHITE_LABEL_FLAGS.DISABLE_FEEDBACK));
  const { userAppRoutes } = useRouting();

  const handleLocaleChange = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);
      AppStorageManager.setItem("CHANNEX_LANGUAGE", lang);
    },
    [i18n],
  );

  if (_.isEmpty(user)) {
    return null;
  }

  const feedBackLink = "https://channex.frill.co";

  const items = [{
    key: "0",
    label: (
      <div className={styles.menuItemUser}>
        <Avatar name={user.name} email={user.email} />
        <div className={styles.user__Wrapper}>
          <span className={styles.user__Name}>{user.name}</span>
          <span className={styles.user__Email}>{user.email}</span>
        </div>
      </div>
    ),
  }, {
    type: "divider",
  }, {
    key: "5",
    label: t("general:links:language"),
    children: SUPPORTED_LANGUAGES.map((lang) => ({
      key: lang.key,
      onClick: () => handleLocaleChange(lang.key),
      label: t(`general:links:languages:${lang.key}`),
    })),
  }, {
    type: "divider",
  }, {
    key: "1",
    onClick: () => navigate(pathBuilder(userAppRoutes.userProfile)),
    label: t("general:links:user_profile"),
  }, {
    key: "2",
    onClick: () => navigate(pathBuilder(userAppRoutes.webhooks)),
    label: t("general:links:webhooks"),
  }, {
    type: "divider",
  }];

  if (!isDisableFeedback) {
    items.push({
      key: "3",
      onClick: () => window.open(feedBackLink, "_blank"),
      label: t("general:links:feedback"),
    });
    items.push({ type: "divider" });
  }

  items.push({
    key: "4",
    onClick: () => logout(),
    label: t("general:links:logout"),
  });

  return (
    <Dropdown menu={{ items }} overlayClassName={styles.menuWrapper} trigger={["click"]}>
      <div className={styles.menu__Trigger}>
        <Avatar name={user.name} email={user.email} />
        {!isMobile && <DownOutlined className={styles.menu__TriggerDownIcon} />}
      </div>
    </Dropdown>
  );
}

export default UserDropDown;
