import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { sortableHandle } from "react-sortable-hoc";
import { DeleteOutlined, DragOutlined } from "@ant-design/icons";
import { Card, Modal, Spin, Tooltip } from "antd";

import { getPreviewUrl } from "utils/uploadcare";

import styles from "./photo_card.module.css";

const DragHandle = sortableHandle(() => <DragOutlined className={styles.dragIcon} />);

class PhotoCard extends Component {
  state = {
    loading: true,
  };

  handleDelete = () => {
    const { t, onDelete } = this.props;

    Modal.confirm({
      title: t("general:delete_photo_dialog:title"),
      content: t("general:delete_photo_dialog:description"),
      onOk: onDelete,
    });
  };

  onLoad = () => this.setState({ loading: false });

  render() {
    const { t, children, photo, draggable, onDelete } = this.props;
    const { loading } = this.state;
    const { url } = photo;

    const previewUrl = getPreviewUrl(url);
    const imageClassName = loading ? styles.imagePlaceholder : styles.image;
    const updatedChild = children && React.cloneElement(children, { loading });

    const cardStyle = [styles.card];

    if (!children) {
      cardStyle.push(styles.cardEmpty);
    }

    const defaultTooltipProps = {
      className: styles.cardTooltip,
      placement: "topLeft",
      arrowPointAtCenter: true,
    };

    return (
      <Card
        className={cardStyle.join(" ")}
        cover={
          <Spin spinning={loading}>
            <img className={imageClassName} src={previewUrl} alt="" onLoad={this.onLoad} />
          </Spin>
        }
      >
        {draggable && <DragHandle />}

        {onDelete && (
          <Tooltip {...defaultTooltipProps} title={t("photo_card:card:tooltips:remove")}>
            <DeleteOutlined className={styles.deleteIcon} onClick={this.handleDelete} />
          </Tooltip>
        )}
        {updatedChild}
      </Card>
    );
  }
}

export default withTranslation()(PhotoCard);
