import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { matchPath } from "react-router";
import { Modal } from "antd";
import store from "store";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import EventEmmiter from "utils/event_emmiter";

import UsersListComponent from "./users_list_component";

const { GroupUsers } = store;

const hoc = (WrappedComponent) => {
  class UsersListComponentWrapper extends Component {
    state = {
      searchQuery: "",
      filteredUserAccesses: [],
    };

    componentDidUpdate(prevProps) {
      const { userAccesses } = this.props;

      if (userAccesses !== prevProps.userAccesses) {
        this.setState({
          filteredUserAccesses: userAccesses,
          searchQuery: "",
        });
      }
    }

    componentDidMount() {
      const { userAccesses } = this.props;

      this.setState({
        filteredUserAccesses: userAccesses,
      });
    }

    handleSearchChange = (searchQuery) => {
      const { userAccesses } = this.props;

      const filteredUserAccesses = userAccesses.filter((userAccess) => {
        const { user } = userAccess;
        const email = (user.email || "").toLowerCase();
        const name = (user.name || "").toLowerCase();

        return email.includes(searchQuery) || name.includes(searchQuery);
      });

      this.setState({
        searchQuery,
        filteredUserAccesses,
      });
    };

    handleRevokeAccessClick = (group) => {
      const { t } = this.props;

      Modal.confirm({
        title: t("general:revoke_access_dialog:title"),
        content: t("general:revoke_access_dialog:description"),
        onOk: () => {
          GroupUsers.revokeAccess(group.id)
            .then(() => {
              EventEmmiter.trigger("group_users:reload");
            });
        },
      });
    };

    handleUserEditClick = (basePath) => (userId) => {
      const { groupId, navigate } = this.props;

      navigate(pathBuilder(basePath.userEdit, { groupId, userId }));
    };

    handleInviteClick = (basePath) => () => {
      const { groupId, navigate } = this.props;

      navigate(pathBuilder(basePath.userInvite, { groupId }));
    };

    render() {
      const { routes, location } = this.props;
      const { searchQuery, filteredUserAccesses } = this.state;

      const isGroupsPage = matchPath({
        path: routes.userAppRoutes.groups._path,
        end: false,
      }, location.pathname);

      const basePath = isGroupsPage
        ? routes.userAppRoutes.groups.edit
        : routes.userAppRoutes.properties.editGroup;

      return (
        <WrappedComponent
          {...this.props}
          searchQuery={searchQuery}
          userAccesses={filteredUserAccesses}
          handleSearchChange={this.handleSearchChange}
          handleRevokeAccessClick={this.handleRevokeAccessClick}
          handleUserEditClick={this.handleUserEditClick(basePath)}
          handleInviteClick={this.handleInviteClick(basePath)}
        />
      );
    }
  }

  return UsersListComponentWrapper;
};

export default withRouter(withTranslation()(hoc(UsersListComponent)));
