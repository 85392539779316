import React from "react";
import PropTypes from "prop-types";

import FormInput from "components/forms/inputs/form_input";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function InputRate(props) {
  const { t, model, errors, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "rate");

  return (
    <FormInput
      name="rate"
      view="horizontal"
      type="text"
      placeholder={t("rates_page:form:rate_placeholder")}
      label={t("rates_page:form:rate_label")}
      defaultValue={model.rate}
      errors={errors ? errors.rate : null}
      onChange={onHandleChange}
      addonAfter={model.currency}
      disabled={Boolean(model.id) || disabled}
    />
  );
}

InputRate.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputRate;
