import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import store from "store";
import { getPropertiesList } from "store/storage/selectors/properties_selector";
import {
  getActiveGroup,
  getActiveProperty,
  getAppMode,
  getDisableUserManagement,
  getEnableUserManagement,
} from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";
import PropertyActions from "components/properties/property_actions";
import GroupsColumn from "components/properties/table_columns/groups_column";
import LocationColumn from "components/properties/table_columns/location";
import UsersColumn from "components/properties/table_columns/users_column";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import alphabetSort from "utils/alphabet_sort";
import EventEmmiter from "utils/event_emmiter";
import handleActionError from "utils/handle_action_error";

const { Properties } = store;

const DEFAULT_ORDER = { title: "asc" };
const SEARCH_FIELD = "title";

export default function PropertiesPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [columnsToHide, setColumnsToHide] = useState(0);
  const propertiesList = useSelector(getPropertiesList);
  const activeProperty = useSelector(getActiveProperty);
  const activeGroup = useSelector(getActiveGroup);
  const disableUserManagement = useSelector(getDisableUserManagement);
  const enableUserManagement = useSelector(getEnableUserManagement);
  const appMode = useSelector(getAppMode);
  const isEmbeddedMode = appMode === APP_MODES.HEADLESS;
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const { userAppRoutes } = useRouting();

  const createLink = pathBuilder(userAppRoutes.properties.create);
  const basePath = pathBuilder(userAppRoutes.properties);
  const closePath = `${basePath}?searchQuery=${searchQuery}`;

  const reloadTable = useCallback(() => {
    return tableRef?.current?.reloadTable();
  }, [tableRef]);

  const handleMediaChange = useCallback(
    (value) => (matches) => {
      if (!matches) {
        return;
      }

      setColumnsToHide(value);
    },
    [],
  );

  const handleDataLoad = useCallback(
    (query, pagination, order) => {
      let filter = { title: { has: query } };

      if (activeGroup) {
        filter = { ...filter, group_id: activeGroup };
      }

      if (activeProperty) {
        filter = { ...filter, id: activeProperty };
      }

      return Properties.list(filter, pagination, order);
    },
    [activeGroup, activeProperty],
  );

  const handlePropertyDelete = useCallback((id) => {
    return Properties.delete(id).then(reloadTable).catch(handleActionError);
  }, [reloadTable]);

  const renderPropertyActions = useCallback(
    (_data, record) => <PropertyActions property={record} onDelete={handlePropertyDelete} />,
    [handlePropertyDelete],
  );

  const columns = useCallback(() => {
    let dataColumns = [
      {
        title: t("properties_page:columns:title"),
        dataIndex: "title",
        key: "title",
        sorter: alphabetSort("title"),
      },
      {
        title: t("properties_page:columns:location"),
        dataIndex: "location",
        key: "location",
        width: 200,
        render: LocationColumn,
      },
      {
        title: t("properties_page:columns:users"),
        dataIndex: "users",
        key: "users",
        render: UsersColumn,
      },
      {
        title: t("properties_page:columns:groups"),
        dataIndex: "groups",
        key: "groups",
        render: GroupsColumn,
      },
      {
        title: t("properties_page:columns:channels_connected"),
        dataIndex: "acc_channels_count",
        key: "acc_channels_count",
        sorter: true,
      },
    ];

    if (disableUserManagement || (isEmbeddedMode && enableUserManagement !== true)) {
      dataColumns = dataColumns.filter((el) => el.key !== "users");
    }

    const actionColumns = [
      {
        title: t("properties_page:columns:actions"),
        key: "action",
        align: "right",
        render: renderPropertyActions,
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - columnsToHide);

    return [...dataColumns, ...actionColumns];
  }, [
    columnsToHide,
    t,
    renderPropertyActions,
    disableUserManagement,
    isEmbeddedMode,
    enableUserManagement,
  ]);

  useEffect(
    function setEventListeners() {
      EventEmmiter.bind("property:created", reloadTable);
      EventEmmiter.bind("property:updated", reloadTable);
      EventEmmiter.bind("property:deleted", reloadTable);
      EventEmmiter.bind("property_users:reload", reloadTable);
      EventEmmiter.bind("group:updated", reloadTable);
      EventEmmiter.bind("group:properties_updated", reloadTable);

      return () => {
        EventEmmiter.unbind("property:created", reloadTable);
        EventEmmiter.unbind("property:updated", reloadTable);
        EventEmmiter.unbind("property:deleted", reloadTable);
        EventEmmiter.unbind("property_users:reload", reloadTable);
        EventEmmiter.unbind("group:updated", reloadTable);
        EventEmmiter.unbind("group:properties_updated", reloadTable);
      };
    },
    [reloadTable],
  );

  useEffect(
    function handlePropertyFilterChange() {
      reloadTable();
    },
    [activeProperty, activeGroup, reloadTable],
  );

  return (
    <Page.Main>
      <Media query="(max-width: 414px)" onChange={handleMediaChange(4)} />
      <Media query="(min-width: 415px) and (max-width: 991px)" onChange={handleMediaChange(2)} />
      <Media query="(min-width: 992px)" onChange={handleMediaChange(0)} />

      <CRUDTable
        data={propertiesList}
        componentRef={tableRef}
        columns={columns}
        emptyMessage={t("properties_page:empty_message")}
        searchField={SEARCH_FIELD}
        defaultOrder={DEFAULT_ORDER}
        createLink={isEmbeddedMode ? null : pathBuilder(userAppRoutes.properties.create)}
        msgCreateLink={createLink}
        onTableQueryChange={setSearchQuery}
        loadData={handleDataLoad}
      />

      <Outlet context={{ closePath }} />
    </Page.Main>
  );
}
