import { useEffect } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { getUser } from "store/storage/selectors/session_selector";
import relationshipsExtractor from "store/utils/relationships_extractor";

import EventEmmiter from "utils/event_emmiter";

const { ws } = store;

export default function useUserChannelSubscription() {
  const user = useSelector(getUser);

  useEffect(
    function initUserChannelWSListiners() {
      const userChannel = ws.socket.channel(`user:${user.id}`);
      userChannel.join();

      userChannel.on("property_created", (payload) => {
        const newProperty = relationshipsExtractor(payload.data);

        EventEmmiter.trigger("property:created", newProperty);
      });

      userChannel.on("property_updated", (payload) => {
        const updatedProperty = relationshipsExtractor(payload.data);

        EventEmmiter.trigger("property:updated", updatedProperty);
      });

      userChannel.on("property_deleted", (payload) => {
        EventEmmiter.trigger("property:deleted", payload.id);
      });

      userChannel.on("live_feed_event_created", (payload) => EventEmmiter.trigger("live_feed_event:created", payload.data));

      return () => {
        userChannel.leave();
      };
    },
    [user],
  );
}
