import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import currencies from "world-currencies";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormInputNumber from "components/forms/inputs/formik/form_input_number";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

import alphabetSort from "utils/alphabet_sort";

const DECIMAL_PART_LENGTH = 2;
const MAX_PERCENT_VALUE = 100;

class GuaranteePaymentForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    title: yup.string().trim().required(errorMessages.required()),
    currency: yup.string().required(errorMessages.required()),
    guarantee_payment_policy: yup.string().required(errorMessages.required()),
    guarantee_payment_amount: yup
      .mixed()
      .nullable()
      .when("guarantee_payment_policy", {
        is: "nights_based",
        then: yup
          .number()
          .integer(errorMessages.integer())
          .positive(errorMessages.positive())
          .typeError(errorMessages.number())
          .required(errorMessages.required()),
      })
      .when("guarantee_payment_policy", {
        is: "percent_based",
        then: yup
          .number()
          .positive(errorMessages.positive())
          .max(MAX_PERCENT_VALUE, errorMessages.lessOrEqual(MAX_PERCENT_VALUE))
          .test("isFloat", errorMessages.maxDecimalPlaces(DECIMAL_PART_LENGTH), (value = 0) => {
            const [, decimalPart = ""] = value ? value.toString().split(".") : [];
            return decimalPart.length <= DECIMAL_PART_LENGTH;
          })
          .typeError(errorMessages.number())
          .required(errorMessages.required()),
      })
      .when("guarantee_payment_policy", {
        is: (val) => val === "fixed_per_booking" || val === "fixed_per_room",
        then: yup
          .number()
          .positive(errorMessages.positive())
          .typeError(errorMessages.number())
          .required(errorMessages.required()),
      }),
  });

  DEFAULT_VALUE = {
    guarantee_payment_policy: "none",
    guarantee_payment_amount: null,
    title: "",
  };

  static FIELDS = ["guarantee_payment_policy", "guarantee_payment_amount", "title", "currency"];

  getPaymentOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "none",
        representation: t("policies:guarantee_payment:payment_type_options:non_required"),
      },
      {
        value: "nights_based",
        representation: t("policies:guarantee_payment:payment_type_options:nights_based"),
      },
      {
        value: "percent_based",
        representation: t("policies:guarantee_payment:payment_type_options:percent_based"),
      },
      {
        value: "fixed_per_booking",
        representation: t("policies:guarantee_payment:payment_type_options:booking_based"),
      },
      {
        value: "fixed_per_room",
        representation: t("policies:guarantee_payment:payment_type_options:room_based"),
      },
    ];
  };

  getCurrencyOptions() {
    return Object.values(currencies)
      .map((el) => {
        return {
          value: el.iso.code,
          representation: `${el.name} (${el.iso.code})`,
        };
      })
      .sort(alphabetSort("representation"));
  }

  isCancellationPaid = (value) => {
    const { guarantee_payment_policy } = value;

    return guarantee_payment_policy && guarantee_payment_policy !== "none";
  };

  getPaymentAmountAddon = (value) => {
    const { guarantee_payment_policy, currency } = value;

    return guarantee_payment_policy.includes("fixed") ? currency : null;
  };

  getAmountInputProps = (policy) => {
    switch (policy) {
      case "nights_based":
        return {
          step: 1,
          min: 0,
          precision: 0,
        };
      case "percent_based":
        return {
          step: 1,
          min: 0,
          max: 100,
          precision: 2,
        };
      case "fixed_per_booking":
      case "fixed_per_room":
        return {
          step: 1,
          min: 0,
          precision: 2,
        };
      default:
        return {
          step: 1,
          min: 0,
          precision: 0,
        };
    }
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    const amountInputProps = this.getAmountInputProps(value.guarantee_payment_policy);

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={GuaranteePaymentForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormInput
              name="title"
              view="horizontal"
              placeholder={t("policies:guarantee_payment:cancellation_policy_title")}
              label={t("policies:guarantee_payment:cancellation_policy_title")}
            />
            <FormikFormSelect
              name="currency"
              view="horizontal"
              placeholder={t("policies:general_policy:currency")}
              label={t("policies:general_policy:currency")}
              options={this.getCurrencyOptions()}
            />
            <FormikFormSelect
              name="guarantee_payment_policy"
              view="horizontal"
              placeholder={t("policies:guarantee_payment:payment_type_label")}
              label={t("policies:guarantee_payment:payment_type_label")}
              options={this.getPaymentOptions()}
            />
            {this.isCancellationPaid(value) && (
              <FormikFormInputNumber
                name="guarantee_payment_amount"
                precision={amountInputProps.precision}
                min={amountInputProps.min}
                max={amountInputProps.max}
                step={amountInputProps.step}
                view="horizontal"
                addonAfter={this.getPaymentAmountAddon(value)}
                placeholder={t("policies:guarantee_payment:payment_amount_label")}
                label={t("policies:guarantee_payment:payment_amount_label")}
              />
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

export default withTranslation()(GuaranteePaymentForm);
