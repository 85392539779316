import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import _ from "lodash";

import ChannexPopover from "components/channex_popover";
import RemoveButton from "components/forms/buttons/remove";
import MappingStatus from "components/mapping/mapping_status";
import RateTitle from "components/rates/title";

import MappingForm from "./mapping_form";
import MappingValue from "./mapping_value";

import styles from "./mapping_row.module.css";

class MappingRow extends Component {
  onChangeMappingValue = (raw_model) => (rateId) => (field) => (value) => {
    const { onChangeTempMappings, tempMappings } = this.props;

    if (!tempMappings[rateId]) {
      tempMappings[rateId] = { ...raw_model, [field]: value };
    } else {
      tempMappings[rateId][field] = value;
    }

    onChangeTempMappings(tempMappings);
  };

  handleDeleteClick = () => {
    const { rate, handlePopupDeleteClick } = this.props;

    handlePopupDeleteClick(rate.id);
  };

  handleClosePopup = () => {
    const { rate, closePopup } = this.props;

    closePopup(rate.id);
  };

  handleApplyMapping = () => {
    const { rate, handleApplyMapping } = this.props;

    handleApplyMapping(rate.id);
  };

  renderMappingPopoverContent = () => {
    const {
      t,
      form,
      mappings,
      mappingSchema,
      mappingOptions,
      tempMappings,
      room,
      rate,
    } = this.props;

    const rateId = rate.id;

    const popoverAdditionalButton = mappings[rateId] && (
      <RemoveButton className={styles.deleteButton} onClick={this.handleDeleteClick} />
    );

    return (
      <ChannexPopover.Content
        title={t("mapping:map_rate_title")}
        additionalButtons={popoverAdditionalButton}
        onSave={this.handleApplyMapping}
        onCancel={this.handleClosePopup}
      >
        <MappingForm
          rate={rate}
          room={room}
          rateId={rateId}
          form={form}
          mappingSchema={mappingSchema}
          mappingOptions={mappingOptions}
          tempMappings={tempMappings}
          onChangeMappingValue={this.onChangeMappingValue}
        />
      </ChannexPopover.Content>
    );
  };

  haveDeletedMapping = (rateId) => {
    const { mappings, mappingOptions } = this.props;

    const mapping = mappings[rateId];

    return _.find(mapping, (value, key) => {
      let isDeleted = false;
      if (mappingOptions && mappingOptions[`${key}_dictionary`]) {
        const optionValues = mappingOptions[`${key}_dictionary`].values;
        const optionValue = _.find(optionValues, (ov) => ov.id === value);

        isDeleted = optionValue.isDeleted;
      }

      return isDeleted;
    });
  };

  _getDerivedNeighbourIds = (rateId) => {
    const { ratePlans } = this.props; // TODO: use ratePlanByIds
    const parentRatePlanid = ratePlans.find((ratePlan) => ratePlan.id === rateId)
      .parent_rate_plan_id;

    if (!parentRatePlanid) {
      return [];
    }

    const derivedNeighbourIds = ratePlans
      .filter((ratePlan) => ratePlan.parent_rate_plan_id === parentRatePlanid)
      .map((ratePlan) => ratePlan.id);

    return derivedNeighbourIds;
  };

  haveDuplicateMapping = (rateId) => {
    const { mappings } = this.props;

    const mappingSetting = mappings[rateId];

    if (!mappingSetting) {
      return false;
    }

    const derivedNeighbourIds = this._getDerivedNeighbourIds(rateId);
    const uniquenessGroup = _.omitBy(
      mappings,
      (_value, key) => key === rateId || derivedNeighbourIds.includes(key),
    );

    const duplicateRateIds = _.keys(_.pickBy(uniquenessGroup, mappingSetting));

    return duplicateRateIds.length !== 0;
  };

  handleOpenMappingDialog = () => {
    const { rate, openMappingDialog } = this.props;

    openMappingDialog(rate.id);
  };

  handleRemoveMapping = () => {
    const { rate, removeMapping } = this.props;

    removeMapping(rate.id);
  };

  render() {
    const {
      rate,
      popupOpen,
      mappings,
      mappingOptions,
      primary = false,
      derived = false,
    } = this.props;

    const isMappingError = this.haveDuplicateMapping(rate.id) || this.haveDeletedMapping(rate.id);

    const className = [
      styles.mappingRow,
      isMappingError ? styles.mappingRowError : null,
      primary ? styles.primaryRatePlanRow : null,
      derived ? styles.derivedRatePlanRow : null,
    ].join(" ");

    return (
      <Row className={className}>
        <Col span={11}>
          {primary && <RateTitle rate={rate} />}
          {derived && (
            <span className={styles.derivedRatePlanTitle}>
              <UserOutlined />
              &nbsp;
              {rate.occupancy}
            </span>
          )}
        </Col>
        <Col span={2}>
          <MappingStatus isError={isMappingError} />
        </Col>
        <Col span={11} className={styles.mappedRateCol}>
          <Row>
            <Col md={22}>
              <ChannexPopover visible={popupOpen} content={this.renderMappingPopoverContent()}>
                <MappingValue
                  mapping={mappings[rate.id]}
                  mappingOptions={mappingOptions}
                  onClick={this.handleOpenMappingDialog}
                />
              </ChannexPopover>
            </Col>
            {mappings && mappings[rate.id] && (
              <Col md={2}>
                <a href="#remove" onClick={this.handleRemoveMapping}>
                  <DeleteOutlined />
                </a>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(MappingRow);
