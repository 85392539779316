import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Empty } from "antd";
import _ from "lodash";

import EmptyMessage from "components/empty_message/empty_message";
import Layouts from "components/layouts";

import RateRowInput from "./rate_row_input";
import SelectAllInput from "./select_all_input";

import styles from "./rates_select.module.css";

class RatesSelect extends Component {
  state = {
    isAllChecked: false,
    isSelectAllDisplayed: true,
    ratesList: [],
  };

  componentDidMount() {
    this._updateRatesList();
    this._updateIsAllCheckedOption();
    this._setIsSelectAllDisplayedOption();
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, ratePlans } = this.props;
    const { ratesList } = this.state;

    const isChannelChanged = form.values.channel !== prevProps.form.values.channel;
    const isMappingsChanged = form.values.mappings !== prevProps.form.values.mappings;
    const isRatePlansChanged = ratePlans !== prevProps.ratePlans;
    const isRateListChanged = ratesList !== prevState.ratesList;

    if (isChannelChanged || isRatePlansChanged) {
      this._updateRatesList();
    }

    if (isMappingsChanged || isRateListChanged) {
      this._updateIsAllCheckedOption();
    }
  }

  _getRooms = (property_id) => {
    const { roomTypes } = this.props;
    return roomTypes.filter((el) => el.property_id === property_id);
  };

  _getRates = (property_id, room_id) => {
    const { ratePlans } = this.props;
    return ratePlans
      .filter(
        (el) => !el.channel_id && el.property_id === property_id && el.room_type_id === room_id,
      )
      .sort((a, b) => {
        if (a.title === b.title) {
          return a.occupancy - b.occupancy;
        }

        return a.title > b.title ? 1 : -1;
      });
  };

  _getMappingsValues = () => {
    const { form } = this.props;

    return form.values.mappings || {};
  };

  _buildRatesTree = () => {
    const { form, properties } = this.props;
    const channel = form.values;

    const ratesTree = channel.properties.map((propertyId) => {
      const rooms = this._getRooms(propertyId);
      const channelProperty = properties[propertyId];

      return {
        ...channelProperty,
        children: rooms.map((room) => {
          const rates = this._getRates(propertyId, room.id);

          return {
            ...room,
            children: rates,
          };
        }),
      };
    });

    return ratesTree;
  };

  _updateRatesList = () => {
    const { form } = this.props;
    const channel = form.values;

    const ratesList = channel.properties.reduce((propertyRatesAcc, propertyId) => {
      const rooms = this._getRooms(propertyId);

      const roomRelatedRates = rooms.reduce((roomRatesAcc, room) => {
        return [...roomRatesAcc, ...this._getRates(propertyId, room.id)];
      }, []);

      return [...propertyRatesAcc, ...roomRelatedRates];
    }, []);

    this.setState({ ratesList });
  };

  _getRateSyncValue = (selected) => {
    return selected ? { sync: true } : null;
  };

  _updateIsAllCheckedOption = () => {
    const { isAllChecked } = this.state;
    const { ratePlans } = this.props;
    const value = this._getMappingsValues();

    const newCheckedValue = ratePlans.every((rate) => value[rate.id]);

    if (isAllChecked === newCheckedValue) {
      return;
    }

    this.setState({ isAllChecked: newCheckedValue });
  };

  _isGoogleVRAccount = () => {
    const { form: { values } } = this.props;

    return values.settings.partner_account === "channex";
  };

  _setIsSelectAllDisplayedOption = () => {
    const { isSelectAllDisplayed } = this.state;
    const newDisplayedValue = !this._isGoogleVRAccount();

    if (isSelectAllDisplayed === newDisplayedValue) {
      return;
    }

    this.setState({ isSelectAllDisplayed: newDisplayedValue });
  };

  _allowSelectOnlyOneRoom = (values, rateId, selected) => {
    const { onChangeMapping } = this.props;
    const { ratesList } = this.state;

    const roomTypeIdsByRateId = ratesList.reduce((acc, rate) => {
      return { ...acc, [rate.id]: rate.room_type_id };
    }, {});

    const updatedMapping = Object.keys(values).reduce((acc, id) => {
      const value = roomTypeIdsByRateId[id] === roomTypeIdsByRateId[rateId] ? values[id] : null;
      return { ...acc, [id]: value };
    }, {});

    const newValue = { ...updatedMapping, [rateId]: this._getRateSyncValue(selected) };
    onChangeMapping(newValue);
    return true;
  };

  handleRateSelectChange = (rateId) => (selected) => {
    const { onChangeMapping } = this.props;
    const values = this._getMappingsValues();

    if (this._isGoogleVRAccount()) {
      return this._allowSelectOnlyOneRoom(values, rateId, selected);
    }

    const newValue = {
      ...values,
      [rateId]: this._getRateSyncValue(selected),
    };

    onChangeMapping(newValue);
    return true;
  };

  handleToggleAll = (newValue) => {
    const { onChangeMapping, ratePlans } = this.props;

    const updatedMapping = ratePlans.reduce((acc, rate) => {
      return { ...acc, [rate.id]: this._getRateSyncValue(newValue) };
    }, {});

    onChangeMapping(updatedMapping);
  };

  render() {
    const { t, onRefresh } = this.props;
    const { isAllChecked, isSelectAllDisplayed } = this.state;

    const mappingValues = this._getMappingsValues();
    const ratesWithMapping = _.pickBy(mappingValues, (value) => !!value);
    const selectedRateIds = Object.keys(ratesWithMapping);
    const ratesTree = this._buildRatesTree();

    return (
      <Layouts.WithRefresh onRefresh={onRefresh}>
        {isSelectAllDisplayed && Boolean(ratesTree.length) && (
          <SelectAllInput checked={isAllChecked} onChange={this.handleToggleAll} />
        )}
        {ratesTree.map(({ id, children }) => (
          <div key={id}>
            {children.length === 0 && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("channels_page:form:message:no_rooms")}
              />
            )}
            {children.map(({ children: grandChildren, ...room }) => (
              <div className={styles.roomContainer} key={room.id}>
                <h4>{room.title}</h4>
                {grandChildren.length === 0 && (
                  <EmptyMessage
                    className={styles.rateRowContainer}
                    message={t("channels_page:form:message:no_rates")}
                  />
                )}
                {grandChildren.map((rate, key) => {
                  return (
                    <RateRowInput
                      key={key}
                      value={selectedRateIds.includes(rate.id)}
                      className={styles.rateRowContainer}
                      rate={rate}
                      onChange={this.handleRateSelectChange(rate.id)}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        ))}
      </Layouts.WithRefresh>
    );
  }
}

export default withTranslation()(RatesSelect);
