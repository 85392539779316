import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

import HelpSidebar from "components/help_sidebar";

import useRouting from "routing/use_routing";

import styles from "./help_link.module.css";

function HelpLink() {
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();

  const isDashboardPage = useMatch({ path: userAppRoutes.dashboard._path, end: false });
  const isBookingPage = useMatch({ path: userAppRoutes.bookings._path, end: false });
  const isChannelEventsPage = useMatch({ path: userAppRoutes.channelEvents._path, end: false });
  const isChannelsPage = useMatch({ path: userAppRoutes.channels._path, end: false });
  const isInventoryPage = useMatch({ path: userAppRoutes.inventory._path, end: false });
  const isIssuesPage = useMatch({ path: userAppRoutes.issues._path, end: false });
  const isPropertiesPage = useMatch({ path: userAppRoutes.properties._path, end: false });
  const isRoomsPage = useMatch({ path: userAppRoutes.rooms._path, end: false });
  const isTasksPage = useMatch({ path: userAppRoutes.tasks._path, end: false });
  const isUserProfilePage = useMatch({ path: userAppRoutes.userProfile._path, end: false });
  const [helpIsVisible, setVisible] = useState(false);

  const getHelpUrl = () => {
    if (isDashboardPage) {
      return "dashboard_page:help_url";
    }
    if (isBookingPage) {
      return "bookings_page:help_url";
    }
    if (isChannelEventsPage) {
      return "channel_events_page:help_url";
    }
    if (isChannelsPage) {
      return "channels_page:help_url";
    }
    if (isInventoryPage) {
      return "inventory_page:help_url";
    }
    if (isIssuesPage) {
      return "issues_page:help_url";
    }
    if (isPropertiesPage) {
      return "properties_page:help_url";
    }
    if (isRoomsPage) {
      return "rooms_page:help_url";
    }
    if (isTasksPage) {
      return "tasks_page:help_url";
    }
    if (isUserProfilePage) {
      return "channels_page:help_url";
    }
    return "dashboard_page:help_url";
  };

  const onOpenHelp = useCallback((event) => {
    event.preventDefault();
    setVisible(true);
  }, []);

  const onCloseHelp = useCallback(() => {
    setVisible(false);
  }, []);

  const helpUrl = getHelpUrl();

  const endpoint = t(helpUrl);

  if (endpoint) {
    return (
      <>
        <a
          href={endpoint}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.helpLink}
          onClick={onOpenHelp}
        >
          <QuestionCircleOutlined />
        </a>
        <HelpSidebar endpoint={endpoint} visible={helpIsVisible} onClose={onCloseHelp} />
      </>
    );
  }
}

export default HelpLink;
