import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import Form from "./form";

export default function RoomSpaceManageDrawer({ id, roomId, visible, onClose, onCreate }) {
  const { t } = useTranslation();
  const title = id
    ? t("rooms_page:spaces:edit_dialog_title")
    : t("rooms_page:spaces:create_dialog_title");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {({ handleCloseFromContent, componentRef }) => (
        <DrawerFormWrapper withActions>
          <Form
            entityId={id}
            roomId={roomId}
            componentRef={componentRef}
            onClose={handleCloseFromContent}
            onCreate={onCreate}
          />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
