import React, { Component } from "react";
import compose from "recompose/compose";
import store from "store";

import AccessManagement from "components/access_management/access_management";

import withComponentRef from "containers/with_component_ref";
import withFormSubmission from "containers/with_form_submission";

import EventEmmiter from "utils/event_emmiter";

const { GroupUsers } = store;

class GroupUserAccess extends Component {
  state = {
    loading: true,
    errors: {},
  };

  componentDidMount() {
    this.loadUser();
  }

  handleSubmit = (form) => {
    const { userId, groupId, onClose, makeRequest } = this.props;

    const formSubmit = userId
      ? GroupUsers.update({
        id: userId,
        role: form.role,
        preferred_language: form.preferred_language,
        overrides: form.overrides,
      })
      : GroupUsers.invite({
        group_id: groupId,
        user_email: form.email,
        preferred_language: form.preferred_language,
        role: form.role,
        overrides: form.overrides,
      });

    makeRequest(formSubmit)
      .then(() => {
        EventEmmiter.trigger("group_users:reload");
        onClose();
      })
      .catch((errors) => {
        this.setState({ errors });
      });
  };

  loadUser = () => {
    const { userId } = this.props;

    if (!userId) {
      this.setState({
        loading: false,
      });

      return;
    }

    this.setState({
      loading: true,
    });

    GroupUsers.find(userId).then((groupUser) => {
      this.setState(({ form }) => ({
        loading: false,
        form: {
          ...form,
          role: groupUser.role,
          email: groupUser.user.email,
          preferred_language: groupUser.user.preferred_language,
          overrides: groupUser.overrides,
        },
      }));
    });
  };

  render() {
    const { onClose, userId, isSubmitting } = this.props;

    const { form, loading, errors } = this.state;

    const isInviteMode = !userId;

    if (!isInviteMode && loading) {
      return null;
    }

    return (
      <AccessManagement
        onSubmit={this.handleSubmit}
        onClose={onClose}
        isInviteMode={isInviteMode}
        loading={loading || isSubmitting}
        initialValues={form}
        errors={errors}
      />
    );
  }
}

const enhance = compose(withFormSubmission, withComponentRef);

export default enhance(GroupUserAccess);
