import React, { Component } from "react";
import { Field } from "formik";

import RateSelector from "components/rate_plans/rate_selector";

import calcSelectorState, { buildSelectedRatePlans } from "./calc_selector_state";

// formik changes form and field objects on each render, which triggers rerender of whole component tree
// we need "form" and "field" only here, so we just keep new references for each render in component memory
const formikRefs = {
  form: null,
  field: null,
};

class FormikRateSelector extends Component {
  initialRatePlanValue = (ratePlanId) => {
    const { field } = formikRefs;

    return field.value.includes(ratePlanId);
  };

  handleChange = (value) => {
    const { onChange } = this.props;
    const { form, field } = formikRefs;

    const selectedRatePlansIds = Object.entries(value)
      .filter(([_ratePlanId, isSelected]) => isSelected)
      .map(([ratePlanId, _isSelected]) => ratePlanId);

    if (onChange) {
      return onChange(selectedRatePlansIds, field, form);
    }

    return form.setFieldValue(field.name, selectedRatePlansIds);
  };

  handleAllToggle = (event) => {
    const { disabledRatePlans = [], roomTypes = [] } = this.props;

    const value = event.target.checked;
    const newSelectedRatePlans = {};

    roomTypes.forEach((roomType) => {
      roomType.ratePlans.forEach((ratePlan) => {
        if (disabledRatePlans.includes(ratePlan.id)) {
          newSelectedRatePlans[ratePlan.id] = this.initialRatePlanValue(ratePlan.id);
        } else {
          newSelectedRatePlans[ratePlan.id] = value;
        }
      });
    });

    this.handleChange(newSelectedRatePlans);
  };

  handleRoomTypeToggle = (roomTypeId) => (event) => {
    const { disabledRatePlans = [], roomTypes = [] } = this.props;
    const { field } = formikRefs;

    const selectedRatePlans = buildSelectedRatePlans(field.value);
    const roomType = roomTypes.filter((el) => el.id === roomTypeId)[0];

    const newSelectedRatePlans = {
      ...selectedRatePlans,
    };

    roomType.ratePlans.forEach((ratePlan) => {
      if (disabledRatePlans.includes(ratePlan.id)) {
        newSelectedRatePlans[ratePlan.id] = this.initialRatePlanValue(ratePlan.id);
      } else {
        newSelectedRatePlans[ratePlan.id] = event.target.checked;
      }
    });

    this.handleChange(newSelectedRatePlans);
  };

  handleRatePlanToggle = (ratePlanId) => (event) => {
    const { disabledRatePlans = [] } = this.props;
    const { field } = formikRefs;

    if (disabledRatePlans.includes(ratePlanId)) {
      return;
    }

    const selectedRatePlans = buildSelectedRatePlans(field.value);

    const newSelectedRatePlans = {
      ...selectedRatePlans,
      [ratePlanId]: event.target.checked,
    };

    this.handleChange(newSelectedRatePlans);
  };

  render() {
    const {
      name,
      roomTypes = [],
      label,
      disabledRatePlans: disabledRatePlansAsValue = [],
    } = this.props;

    return (
      <Field name={name}>
        {({ field, form }) => {
          const {
            selectedRatePlans,
            disabledRatePlans,
            selectedRoomTypes,
            allSelected,
            allIndeterminate,
            allDisabled,
          } = calcSelectorState(field.value, disabledRatePlansAsValue, roomTypes);

          formikRefs.form = form;
          formikRefs.field = field;

          return (
            <RateSelector
              {...this.props}
              {...field}
              label={label}
              selectedRatePlans={selectedRatePlans}
              disabledRatePlans={disabledRatePlans}
              selectedRoomTypes={selectedRoomTypes}
              allSelected={allSelected}
              allIndeterminate={allIndeterminate}
              allDisabled={allDisabled}
              errors={form.touched[name] && form.errors[name]}
              onBlur={() => form.setFieldTouched(name, true)}
              onAllToggle={this.handleAllToggle}
              onRoomTypeToggle={this.handleRoomTypeToggle}
              onRatePlanToggle={this.handleRatePlanToggle}
            />
          );
        }}
      </Field>
    );
  }
}

export default FormikRateSelector;
