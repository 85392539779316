import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";

import IconPlaceholder from "components/icon_placeholder";

function Loading(props) {
  const { t, className } = props;

  return (
    <IconPlaceholder
      className={className}
      dataCy="loading"
      Icon={LoadingOutlined}
      text={t("general:loading")}
    />
  );
}

Loading.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Loading);
