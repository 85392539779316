import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import compose from "recompose/compose";
import * as yup from "yup";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikTextareaInput from "components/forms/inputs/formik/form_textarea";
import RadioInput from "components/forms/inputs/formik/radio";

class CleaningPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    enhanced_cleaning_practices: yup.bool(),
    partner_hygiene_link: yup.string().url(),
    cleaning_practices_description: yup.string(),
  });

  DEFAULT_VALUE = {
    enhanced_cleaning_practices: false,
  };

  static FIELDS = [
    "enhanced_cleaning_practices",
    "partner_hygiene_link",
    "cleaning_practices_description",
  ];

  getYesNoOptions = () => {
    const { t } = this.props;

    return [
      {
        value: false,
        representation: t("general:no"),
      },
      {
        value: true,
        representation: t("general:yes"),
      },
    ];
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={CleaningPolicyForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <RadioInput
              name="enhanced_cleaning_practices"
              view="horizontal"
              label={t("policies:cleaning_policy:enhanced_cleaning_practices")}
              options={this.getYesNoOptions()}
            />
            <FormikFormInput
              name="partner_hygiene_link"
              view="horizontal"
              placeholder={t("policies:cleaning_policy:partner_hygiene_link")}
              label={t("policies:cleaning_policy:partner_hygiene_link")}
            />
            <FormikTextareaInput
              name="cleaning_practices_description"
              view="horizontal"
              placeholder={t("policies:cleaning_policy:cleaning_practices_description")}
              label={t("policies:cleaning_policy:cleaning_practices_description")}
            />
          </Form>
        )}
      </ChannexForm>
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(CleaningPolicyForm);
