import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";

import Gallery from "components/content_module/gallery";
import PhotoCard from "components/content_module/photo_card";
import PhotoUploader from "components/content_module/photo_uploader";

import styles from "./room_photos.module.css";

const DEFAULT_PHOTO_KIND = "ad";

class RoomPhotos extends Component {
  getValue = () => {
    return (this.props.value || [])
      .sort((a, b) => a.position - b.position);
  };

  handlePictureSelect = (photos = []) => {
    const { name, onChange } = this.props;
    const value = this.getValue();

    const photosAmount = value.filter(({ is_removed }) => !is_removed).length;

    const newPhotos = photos.map(({ cdnUrl }, key) => ({
      is_removed: false,
      url: cdnUrl,
      position: photosAmount + key,
      description: null,
      author: null,
      kind: DEFAULT_PHOTO_KIND,
    }));

    onChange(name, [...value, ...newPhotos]);
  };

  handleDelete = (url) => () => {
    const { name, onChange } = this.props;
    const value = this.getValue();
    const photoIndex = value.findIndex((photo) => photo.url === url);
    const selectedPhoto = value[photoIndex];

    const updatedPhotos = [...value.slice(0, photoIndex), ...value.slice(photoIndex + 1)];

    if (selectedPhoto.id) {
      const photoToDelete = { ...selectedPhoto, is_removed: true };

      updatedPhotos.push(photoToDelete);
    }

    updatedPhotos
      .filter(({ is_removed }) => !is_removed)
      .forEach((photo, index) => {
        photo.position = index;
      });

    onChange(name, updatedPhotos);
  };

  handleMetaUpdate = (url) => (values) => {
    const { name, onChange } = this.props;
    const value = this.getValue();

    const updatedPhotos = value.map((photo) => {
      return photo.url === url ? { ...photo, ...values } : photo;
    });

    onChange(name, updatedPhotos);
  };

  renderMeta = (photo) => {
    const { t } = this.props;
    const { url, description } = photo;

    const metaClass = [styles.photoMeta];
    let metaDescription = description;

    if (!description) {
      metaClass.push(styles.photoMetaEmpty);
      metaDescription = t("rooms_page:content:photo_description:placeholder");
    }

    const metaContent = <PhotoCard.MetaForm photo={photo} onChange={this.handleMetaUpdate(url)} />;

    return (
      <PhotoCard.Meta
        editable
        tooltipText={t("rooms_page:content:photo_description:tooltip")}
        editForm={metaContent}
      >
        <div className={styles.photoMetaContainer}>
          <div className={metaClass.join(" ")}>{metaDescription}</div>
          <EditOutlined />
        </div>
      </PhotoCard.Meta>
    );
  };

  renderPhotos = () => {
    const value = this.getValue();

    const photoList = value
      .filter((photo) => !photo.is_removed)
      .map((photo) => {
        const { url } = photo;

        return (
          <PhotoCard
            draggable
            photo={photo}
            key={`property_photo_${url}`}
            onDelete={this.handleDelete(url)}
          >
            {this.renderMeta(photo)}
          </PhotoCard>
        );
      });

    return photoList;
  };

  handleSort = ({ oldIndex, newIndex }) => {
    const { onChange, name } = this.props;
    const value = this.getValue();

    const displayedPhotos = [];
    const deletedPhotos = [];

    value.forEach((photo) => {
      const { is_removed } = photo;

      if (is_removed) {
        deletedPhotos.push(photo);
      } else {
        displayedPhotos.push(photo);
      }
    });

    const movedPhoto = displayedPhotos[oldIndex];
    const restPhotos = [
      ...displayedPhotos.slice(0, oldIndex),
      ...displayedPhotos.slice(oldIndex + 1),
    ];
    const updatedPhotos = [
      ...restPhotos.slice(0, newIndex),
      movedPhoto,
      ...restPhotos.slice(newIndex),
    ];

    updatedPhotos.forEach((photo, index) => {
      photo.position = index;
    });

    onChange(name, [...updatedPhotos, ...deletedPhotos]);
  };

  renderLoaderCard = () => {
    return (
      <PhotoUploader
        multiple
        button={<PhotoCard.UploadButton />}
        onFileSelect={this.handlePictureSelect}
      />
    );
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <legend>{t("rooms_page:content:photos_legend")}</legend>
        <Gallery uploadButton={this.renderLoaderCard()} onSortEnd={this.handleSort}>
          {this.renderPhotos()}
        </Gallery>
      </>
    );
  }
}

export default withTranslation()(RoomPhotos);
