import React, { Component } from "react";
import { Field } from "formik";
import _ from "lodash";

import FormCheckbox from "components/forms/inputs/form_checkbox";

class FormikFormCheckbox extends Component {
  render() {
    const { name, namespace, onChange } = this.props;

    const fieldName = namespace ? `${namespace}.${name}` : name;

    return (
      <Field name={fieldName}>
        {({ field, form }) => {
          const isTouched = _.get(form.touched, name, false);

          return (
            <FormCheckbox
              {...this.props}
              {...field}
              defaultValue={field.value}
              errors={isTouched && form.errors[name]}
              onChange={(value) => (onChange ? onChange(value, field, form) : form.setFieldValue(name, value))}
              onBlur={() => form.setFieldTouched(name, true)}
            />
          );
        }}
      </Field>
    );
  }
}

export default FormikFormCheckbox;
