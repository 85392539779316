import React from "react";
import { useTranslation } from "react-i18next";
import { EyeInvisibleOutlined, MoreOutlined, SolutionOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import APP_MODES from "config/constants/app_modes";

import AppModeRenderer from "components/app_mode_renderer";
import LinkButton from "components/forms/buttons/link_button";

import styles from "./header_actions.module.css";

export default function HeaderActions({ isMobile, handleOpenBooking, archive }) {
  const { t } = useTranslation();

  const actions = [];

  if (handleOpenBooking) {
    actions.push({
      label: (
        <LinkButton key="openBooking" className={styles.action} onClick={handleOpenBooking}>
          <SolutionOutlined />
          <p>{t("messages_page:dialog:open_booking")}</p>
        </LinkButton>
      ),
      key: "openBooking",
    });
  }

  if (archive) {
    actions.push({
      label: (
        <LinkButton key="archive" className={styles.action} onClick={archive}>
          <EyeInvisibleOutlined />
          <p>{t("reviews_page:dialog:archive_review")}</p>
        </LinkButton>
      ),
      key: "archive",
    });
  }

  return (
    <div className={styles.container}>
      <AppModeRenderer visible={[APP_MODES.DEFAULT]}>
        {isMobile && (
          <div>
            <Dropdown menu={{ items: actions }}>
              <MoreOutlined style={{ fontSize: 16, marginTop: 3, cursor: "pointer" }} />
            </Dropdown>
          </div>
        )}
        {!isMobile && (
          <div>{actions.map(({ label }) => label)}</div>
        )}
      </AppModeRenderer>
    </div>
  );
}
