import React from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormSelectRaw from "components/forms/inputs/form_select";

export default function FormSelect({ name, label, errors, options, control }) {
  return (
    <Row>
      <Col span={24}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormSelectRaw
              hookForm
              label={label}
              errors={errors}
              options={options}
              {...field}
            />
          )}
        />
      </Col>
    </Row>
  );
}
