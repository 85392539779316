import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import useBoolState from "utils/use_bool_state";

export default function ReservationRequestActionButtons({ onResolve }) {
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const { t } = useTranslation();

  const handleResolve = (resolution) => () => {
    setLoading();

    onResolve(resolution).finally(setLoadingComplete);
  };

  return (
    <>
      <Button onClick={handleResolve(true)} disabled={loading} loading={loading} type="primary">
        {t("general:action:accept")}
      </Button>
      <Button onClick={handleResolve(false)} disabled={loading} type="primary" danger>
        {t("general:action:decline")}
      </Button>
    </>
  );
}
