import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import useDrawer from "hooks/use_drawer";
import useFilteredData from "hooks/use_filtered_data";

import RoomSpaceManageDrawer from "drawers/room_space_manage_drawer";

import useRoomSpaces from "data/use_room_spaces";
import useRoomSpacesRemoveMutation from "data/use_room_spaces/mutations/remove";

import TabTable from "components/tab_table";

import alphabetSort from "utils/alphabet_sort";

import Actions from "./actions/actions";

const ACTION_COLUMNS_WIDTH = 200;

export default function RoomSpacesTable({ roomId }) {
  const { t } = useTranslation();

  const {
    isOpen: isManageDrawerOpen,
    payload: manageDrawerPayload,
    open: openManageDrawer,
    close: closeManageDrawer,
  } = useDrawer();

  const { data: roomSpaces, refetch, isLoading } = useRoomSpaces(roomId);
  const { mutate: removeRoomSpace } = useRoomSpacesRemoveMutation(roomId);
  const { filteredData, searchQuery, setSearchQuery } = useFilteredData(roomSpaces, { searchItem: (item) => item.title.toLowerCase() });

  const handleRoomSpaceProcessed = useCallback(() => { closeManageDrawer(); refetch(); }, [closeManageDrawer, refetch]);

  const columns = useMemo(() => {
    return [
      {
        title: t("general:hotel"),
        key: "title",
        dataIndex: "title",
        sorter: alphabetSort("title"),
        ellipsis: true,
      },
      {
        title: t("general:actions"),
        key: "actions",
        align: "right",
        width: ACTION_COLUMNS_WIDTH,
        render: (_text, roomSpaceId) => (
          <Actions
            id={roomSpaceId.id}
            onEdit={(id) => openManageDrawer({ id })}
            onDelete={removeRoomSpace}
          />
        ),
      },
    ];
  }, [t, openManageDrawer, removeRoomSpace]);

  return (
    <>
      <TabTable
        actions={
          <Button
            data-cy="create_button"
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={() => openManageDrawer()}
          >
            {t("general:action:create")}
          </Button>
        }
        emptyMessage={t("rooms_page:spaces:empty_message")}
        loading={isLoading}
        searchQuery={searchQuery}
        rowKey="id"
        columns={columns}
        dataSource={filteredData}
        onCreate={() => openManageDrawer()}
        onSearchChange={setSearchQuery}
      />
      <RoomSpaceManageDrawer
        id={manageDrawerPayload?.id}
        roomId={roomId}
        visible={isManageDrawerOpen}
        onClose={closeManageDrawer}
        onCreate={handleRoomSpaceProcessed}
      />
    </>
  );
}
