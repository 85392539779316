import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import DeletePropertyButton from "components/properties/delete_property_button";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import DeletePropertyModal from "../delete_property_button/delete_property_modal";

import styles from "./property_actions.module.css";

export default function PropertyActions({ property, onDelete }) {
  const { t } = useTranslation();
  const { id } = property;
  const appMode = useSelector(getAppMode);
  const { userAppRoutes } = useRouting();

  const isEmbeddedMode = appMode === APP_MODES.HEADLESS;

  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);
  const handleDeleteClick = () => {
    toggleModal();
  };

  return (
    <span className={styles.actionsContainer}>
      <Link
        data-cy="crud_entry_logs_action"
        to={pathBuilder(userAppRoutes.tasks, { propertyId: id })}
      >
        {t("general:action:logs")}
      </Link>
      &nbsp;|&nbsp;
      <Link
        to={pathBuilder(userAppRoutes.properties.edit, { propertyId: id })}
        data-cy="crud_entry_edit_action"
      >
        {t("general:action:edit")}
      </Link>
      &nbsp;
      {isEmbeddedMode ? null : (
        <>
          | <DeletePropertyButton onClick={handleDeleteClick} />
          <DeletePropertyModal
            property={property}
            visible={isModalOpen}
            onConfirm={onDelete}
            onClose={toggleModal}
          />
        </>
      )}
    </span>
  );
}
