import React from "react";
import { useTranslation } from "react-i18next";
import { EyeInvisibleOutlined, SolutionOutlined } from "@ant-design/icons";

import APP_MODES from "config/constants/app_modes";

import AppModeRenderer from "components/app_mode_renderer";
import LinkButton from "components/forms/buttons/link_button";

import styles from "./header_actions.module.css";

export default function HeaderActions({ handleOpenBooking, archive }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <AppModeRenderer visible={[APP_MODES.DEFAULT]}>
        {handleOpenBooking && (
          <LinkButton className={styles.action} onClick={handleOpenBooking}>
            <SolutionOutlined />
            <p>{t("messages_page:dialog:open_booking")}</p>
          </LinkButton>
        )}
        {archive && (
          <LinkButton className={styles.action} onClick={archive}>
            <EyeInvisibleOutlined />
            <p>{t("reviews_page:dialog:archive_review")}</p>
          </LinkButton>
        )}
      </AppModeRenderer>
    </div>
  );
}
