import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Typography } from "antd";
import store from "store";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

const { Paragraph, Text } = Typography;
const { Webhooks } = store;

export default function WebhookTest({ model }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { t } = useTranslation();

  const testWebhook = () => {
    setLoading(true);
    setResult(null);
    Webhooks.test(model).then((response) => {
      setLoading(false);
      setResult(response);
    });
  };

  return (
    <div>
      <legend>{t("webhook_drawer:test:legend")}</legend>
      <Row>
        <Col xs={{ span: 24, offset: 24 }} sm={{ span: 16, offset: 8 }}>
          <Button loading={loading} onClick={testWebhook}>
            {t("webhook_drawer:test:send")}
          </Button>
        </Col>
      </Row>
      {result ? (
        <Form.Item label={t("webhook_drawer:test:response")} {...horizontalFormItemLayout}>
          <div>
            {result.status === 200 ? (
              <Text type="success">
                <CheckCircleOutlined /> {t("webhook_drawer:test:success")}
              </Text>
            ) : (
              <Text type="danger">
                <ExclamationCircleOutlined /> {t("webhook_drawer:test:failed")}
              </Text>
            )}
            <Paragraph>
              <pre>{JSON.stringify(result, null, 4)}</pre>
            </Paragraph>
          </div>
        </Form.Item>
      ) : null}
    </div>
  );
}
