import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button, Steps } from "antd";

import Flexbox from "components/flexbox";

import ConfirmationStep from "./confirmation_step";
import ImportStep from "./import_step";
import PropertySearchForm from "./property_search_form";

const { Step } = Steps;

const DESKTOP_MIN_WIDTH = 600;

export default function TriplaImportWorkflow() {
  const { t } = useTranslation();

  const STEPS = [{
    title: t("applications_page:tripla_import:steps:property_data"),
  }, {
    title: t("applications_page:tripla_import:steps:confirmation"),
    nextButtonTitle: t("applications_page:tripla_import:steps:confirmation_next_button"),
  }, {
    title: t("applications_page:tripla_import:steps:import"),
  }];

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [workflowData, setWorkflowData] = useState({});
  const [loading, setLoading] = useState(false);
  const stepRefs = useRef([...Array(STEPS.length)].fill(React.createRef()));
  const isDesktop = useMediaQuery({ minWidth: DESKTOP_MIN_WIDTH });

  const currentStepConfig = STEPS[currentStepIndex];

  const showBackButton = currentStepIndex > 0 && currentStepIndex !== STEPS.length - 1;
  const showNextButton = currentStepIndex < (STEPS.length - 1);

  const handleNextButtonClick = useCallback(async () => {
    setLoading(true);

    const currentStep = stepRefs.current[currentStepIndex];

    try {
      const result = await currentStep.current.proceed();

      setWorkflowData({
        ...workflowData,
        ...result,
      });

      setCurrentStepIndex(currentStepIndex + 1);
    } catch {
      // noop
    } finally {
      setLoading(false);
    }
  }, [setLoading, stepRefs, currentStepIndex, workflowData, setWorkflowData, setCurrentStepIndex]);

  const handleResetButtonClick = useCallback(() => {
    setCurrentStepIndex(0);
  }, [setCurrentStepIndex]);

  const handleBackButtonClick = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex, setCurrentStepIndex]);

  return (
    <Flexbox style={{ padding: 24 }}>
      {isDesktop && (
        <div>
          <Steps
            direction="vertical"
            current={currentStepIndex}
            style={{ height: 200 }}
          >
            {STEPS.map((step, i) => (
              <Step key={i} title={step.title} />
            ))}
          </Steps>
        </div>
      )}
      <Flexbox style={{ flexDirection: "column", flexWrap: "wrap", alignContent: "center" }}>
        <div style={{ maxWidth: "600px", width: "100%" }}>
          <div>
            {currentStepIndex === 0 && (
              <PropertySearchForm ref={stepRefs.current[0]} />
            )}
            {currentStepIndex === 1 && (
              <ConfirmationStep ref={stepRefs.current[1]} triplaProperty={workflowData.triplaProperty} />
            )}
            {currentStepIndex === 2 && (
              <ImportStep
                ref={stepRefs.current[2]}
                triplaProperty={workflowData.triplaProperty}
                onStartAgainClick={handleResetButtonClick}
              />
            )}
          </div>
        </div>
        <div style={{ textAlign: "end" }}>
          {showBackButton && (
            <Button
              type="default"
              style={{ marginRight: 12 }}
              onClick={handleBackButtonClick}
            >
              {t("general:action:back")}
            </Button>
          )}
          {showNextButton && (
            <Button
              type="primary"
              loading={loading}
              onClick={handleNextButtonClick}
            >
              {currentStepConfig.nextButtonTitle ? currentStepConfig.nextButtonTitle : t("general:action:next")}
            </Button>
          )}
        </div>
      </Flexbox>
    </Flexbox>
  );
}
