import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import store from "store";
import { getPropertyOptionById } from "store/storage/selectors/properties_selector";
import { getReviewById } from "store/storage/selectors/reviews_selector";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import Content from "./content";
import Header from "./header";

import styles from "./review.module.css";

const { Bookings, Reviews } = store;

let requestedBookingId = null;

function Review({ isMobile }) {
  const { review_id } = useParams();
  const review = useSelector(getReviewById(review_id));
  const property = useSelector(getPropertyOptionById(review?.relationships.property.data.id));
  const [bookingParams, setBookingParams] = useState(null);
  const [bookingLoading, setBookingLoading] = useState(true);
  const bookingId = review?.relationships.booking?.data?.id;

  useEffect(
    function loadAssociatedBookind() {
      setBookingParams(null);
      setBookingLoading(true);

      if (!bookingId) {
        requestedBookingId = null;
        setBookingLoading(false);
        return;
      }

      requestedBookingId = bookingId;

      Bookings.find(bookingId, { relationships: "all" })
        .then(
          (newBooking) => {
            if (requestedBookingId !== newBooking.id) {
              setBookingLoading(false);
              return;
            }

            setBookingLoading(false);
            setBookingParams(newBooking);
          },
          () => {
            setBookingLoading(false);
          },
        )
        .catch(showErrorMessageFromResponse);
    },
    [bookingId, setBookingLoading],
  );

  const archive = () => {
    Reviews.archive(review.id).then(
      (_repliedReview) => {},
      (error) => {
        notification.error({
          message: "Error",
          description: error.errors.title,
        });
      },
    );
  };

  if (!property) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Header
        review={review}
        isMobile={isMobile}
        bookingParams={bookingParams}
        bookingLoading={bookingLoading}
        archive={!review.isReplied && archive}
      />
      <Content review={review} />
    </div>
  );
}

export default Review;
