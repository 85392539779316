let transport;
let storage;

const ENDPOINT = "admin/users";

export default class AdminUsers {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order = { email: "desc" }) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.adminUsersLoad(response.data, response.meta);

      return response;
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  update(attrs) {
    return transport.send("PUT", `${ENDPOINT}/${attrs.id}`, { user: attrs }).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  allowAPIKey(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/allow_api_key_access`).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  disallowAPIKey(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/disallow_api_key_access`).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  allowAdminFieldsAccess(id) {
    return transport
      .send("POST", `${ENDPOINT}/${id}/allow_admin_fields_access`)
      .then((response) => {
        storage.adminUsersAdd(response.data);
        return response;
      });
  }

  disallowAdminFieldsAccess(id) {
    return transport
      .send("POST", `${ENDPOINT}/${id}/disallow_admin_fields_access`)
      .then((response) => {
        storage.adminUsersAdd(response.data);
        return response;
      });
  }

  markAsRemoved(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/remove`).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  assignBillingAccount(userId, billingAccountId, role) {
    return transport
      .send("POST", `${ENDPOINT}/${userId}/assign_billing_account`, {
        billing_account: {
          id: billingAccountId,
          role,
        },
      })
      .then((response) => {
        storage.adminUsersAdd(response.data);
        return response;
      });
  }

  sendResetPassword(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/send_reset_password`).then((response) => {
      return response;
    });
  }

  block(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/block`).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  unblock(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/unblock`).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }

  invite(attrs) {
    return transport.send("POST", `${ENDPOINT}/invite`, { user: attrs }).then((response) => {
      storage.adminUsersAdd(response.data);
      return response;
    });
  }
}
