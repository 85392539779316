import messageStatuses from "config/constants/message_statuses";

import { convertToCamelCase } from "../utils/case_converter";
import extractRelationships from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "bookings";

export default class Bookings {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order = { inserted_at: "desc" }) {
    return transport.send("GET", `${ENDPOINT}`, { filter, pagination, order }).then((response) => {
      storage.bookingsLoad(response.data, response.meta);

      return response;
    });
  }

  find(id, { relationships } = {}) {
    return transport
      .send("GET", `${ENDPOINT}/${id}`, { relationships })
      .then((response) => extractRelationships(response.data));
  }

  stats(meta = {}, order = { inserted_at: "desc" }) {
    return transport.send("GET", `${ENDPOINT}/stats`, { ...meta, order }).then((response) => {
      const data = convertToCamelCase(response.data.attributes);
      storage.bookingStatsLoad(data, response.meta);
      return response;
    });
  }

  messagesListByBookingId(bookingId, pagination = {}, order = { inserted_at: "desc" }) {
    return transport
      .send("GET", `${ENDPOINT}/${bookingId}/messages`, { pagination, order })
      .then((response) => {
        const data = convertToCamelCase(response.data);
        storage.channelMessagesLoad(bookingId, data, response.meta);
        return response;
      });
  }

  sendMessageByBookingId(bookingId, message) {
    const sendMessage = {
      message: {
        message: message.attributes.message,
      },
    };

    storage.channelMessagesAdd(bookingId, message);

    return transport
      .send("POST", `${ENDPOINT}/${bookingId}/messages`, sendMessage)
      .then((response) => {
        const newMessage = { ...response.data, requestId: message.requestId };

        storage.channelMessagesAdd(bookingId, newMessage);
        return response;
      })
      .catch((error) => {
        const newMessage = {
          attributes: {
            ...message.attributes,
            status: messageStatuses.FAILED,
          },
          requestId: message.requestId,
        };

        storage.channelMessagesAdd(bookingId, newMessage);
        throw error;
      });
  }

  resend(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/resend`, {});
  }
}
