import React, { Component } from "react";
import { Checkbox, Form } from "antd";
import snakeCase from "lodash/snakeCase";

import getFieldLayout from "./utils/get_field_layout";
import getFieldStyle from "./utils/get_field_style";

const FormItem = Form.Item;

class FormCheckbox extends Component {
  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({
      checked: defaultValue,
    });
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      this.setState({ checked: defaultValue });
    }
  }

  onChange() {
    const { checked } = this.state;
    const { onChange, name } = this.props;
    this.setState({
      checked: !checked,
    });

    if (onChange && typeof onChange === "function") {
      onChange(!checked, name);
    }
  }

  render() {
    const { inputRef, extra, errors, label, view, name, onBlur = () => {} } = this.props;
    const { checked } = this.state;
    const formItemLayout = getFieldLayout(view);
    const style = getFieldStyle(this.props);
    const dataCy = name ? snakeCase(`checkbox_${name.replace(".", "_")}`) : null;

    return (
      <FormItem
        {...formItemLayout}
        {...FormCheckbox.validation(errors)}
        style={style}
        extra={extra}
        label={label}
        data-cy={dataCy}
      >
        <Checkbox ref={inputRef} checked={checked} onChange={this.onChange} onBlur={onBlur} />
      </FormItem>
    );
  }
}

export default FormCheckbox;
