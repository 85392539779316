import React, { Component } from "react";
import store from "store";

import withRouter from "routing/with_router";

import EventEmmiter from "utils/event_emmiter";

const { RatePlans } = store;

const generateAutoDerivedOption = (value, option) => {
  let derivedOption = null;

  if (option.occupancy > value.primary_occupancy) {
    derivedOption = [
      [
        value.increase_mode === "$" ? "increase_by_amount" : "increase_by_percent",
        String(value.increase_value * Math.abs(value.primary_occupancy - option.occupancy)),
      ],
    ];
  } else {
    derivedOption = [
      [
        value.decrease_mode === "$" ? "decrease_by_amount" : "decrease_by_percent",
        String(value.decrease_value * Math.abs(value.primary_occupancy - option.occupancy)),
      ],
    ];
  }

  return {
    occupancy: option.occupancy,
    rate: value.rate,
    derived_option: { rate: derivedOption },
  };
};

const normalizeOccupancyOptions = (value) => (option) => {
  let result = null;

  switch (value.rate_mode) {
    case "manual":
      result = {
        ...option,
        derived_option: null,
      };
      break;
    case "auto":
      result = generateAutoDerivedOption(value, option);
      break;

    default:
      result = { ...option, rate: value.rate };
      break;
  }

  if (value.primary_occupancy === option.occupancy) {
    result.is_primary = true;
    result.derived_option = value.derived_option;
  } else {
    result.is_primary = false;
  }

  return result;
};

const getOptions = (value) => {
  if (value.sell_mode === "per_person") {
    const normalizeOccupancyOptionsFn = normalizeOccupancyOptions(value);

    return value.options.map(normalizeOccupancyOptionsFn);
  }

  return [
    {
      is_primary: true,
      occupancy: value.id ? value.options[0].occupancy : value.occupancy,
      rate: value.id ? value.options[0].rate : value.rate,
      derived_option: value.derived_option,
    },
  ];
};

const derivedKeys = [
  "is_derived",
  "inherit_stop_sell",
  "inherit_rate",
  "inherit_min_stay_through",
  "inherit_min_stay_arrival",
  "inherit_max_stay",
  "inherit_closed_to_departure",
  "inherit_closed_to_arrival",
];

const getDerivedParams = (value) => {
  const isDerived = value.is_derived;

  return derivedKeys.reduce(
    (result, key) => ({
      ...result,
      [key]: isDerived ? value[key] : false,
    }),
    {},
  );
};

const prepareValue = (value) => {
  const derivedParams = getDerivedParams(value);

  let output = {
    id: value.id,
    title: value.title,
    property_id: value.property_id,
    room_type_id: value.room_type_id,
    parent_rate_plan_id: value.is_derived ? value.parent_rate_plan_id || null : null,
    cancellation_policy_id: value.cancellation_policy_id,
    tax_set_id: value.tax_set_id,
    currency: value.currency,
    children_fee: value.children_fee || 0,
    infant_fee: value.infant_fee || 0,
    auto_rate_settings: {
      increase_mode: value.increase_mode,
      decrease_mode: value.decrease_mode,
      increase_value: value.increase_value,
      decrease_value: value.decrease_value,
    },
    sell_mode: value.sell_mode,
    rate_mode: value.rate_mode,
    meal_type: value.meal_type,
    options: getOptions(value),
    ui_read_only: value.ui_read_only,
    ...derivedParams,
  };

  output = Object.keys(output).reduce((acc, el) => {
    acc[el] = output[el] === "" ? null : output[el];
    return acc;
  }, {});

  return output;
};

const withLogic = (FormComponent) => {
  class HOC extends Component {
    onSubmit = (value, success, error) => {
      const method = value.id ? RatePlans.update : RatePlans.create;

      method(prepareValue(value)).then(
        (response) => {
          if (success && typeof success === "function") {
            EventEmmiter.trigger("rate_plan:created", response.data.attributes);
            success(response);
          }
        },
        (response) => {
          if (error && typeof error === "function") {
            error(response);
          }
        },
      );
    };

    render() {
      return <FormComponent {...this.props} onSubmit={this.onSubmit} />;
    }
  }

  return withRouter(HOC);
};

export default withLogic;
