import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "utils/dayjs";

import ContentContainer from "../../../_common/content_container";
import SectionHeader from "../../../_common/section_header/section_header";
import Text from "../../../_common/text";

import GuestReviewForm from "./guest_review_form";

const EXPIRATION_TIMEFRAME_IN_DAYS = 30;

export default function DoubleBlind({ review }) {
  const { t } = useTranslation();
  const { attributes: { receivedAt } } = review;

  const reviewReceivedDaysAgo = dayjs().diff(dayjs(receivedAt), "days");
  const isGuestReviewTimeframeExpired = reviewReceivedDaysAgo >= EXPIRATION_TIMEFRAME_IN_DAYS;
  const isGuestReviewProvided = !!review.attributes.reply?.guestReview;

  return (
    <div>
      <ContentContainer quotes border>
        <SectionHeader header={t("reviews_page:feedback:guest_subheader")} />
        <Text disabled>The review is hidden until you provide feedback for the Guest</Text>
      </ContentContainer>

      <ContentContainer>
        <SectionHeader header={t("reviews_page:feedback:guest_review_subheader")} />

        {!isGuestReviewTimeframeExpired && !isGuestReviewProvided && (
          <GuestReviewForm review={review} />
        )}

        {isGuestReviewTimeframeExpired && (
          <Text disabled>{t("reviews_page:feedback:not_allowed_for_expired")}</Text>
        )}

        {isGuestReviewProvided && (
          <Text disabled>{t("reviews_page:feedback:already_provided")}</Text>
        )}
      </ContentContainer>
    </div>
  );
}
