import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Cell, Pie, PieChart } from "recharts";

import Transition from "components/transition";

import getChannelInfoByCode from "utils/get_channel_info_by_code";

import ActiveShape from "./active_shape";
import LegendList from "./legend_list";

import styles from "./sources_chart.module.css";

const DATA_KEY = "totalAmount";
const DEFAULT_COLOR_FILL = "#4285f4";
const CHART_WIDTH = 190;
const CHART_HEIGHT = 190;
const CHART_INNER_RADIUS = "90%";
const CHART_OUTER_RADIUS = "100%";
const CHART_CORNER_RADIUS = "50%";
const CHART_PADDING_ANGLE = 2;
const CHART_MIN_ANGLE = 10;

function SourcesChart({ data }) {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(null);
  const [channelNameForLabel, setChannelNameForLabel] = useState(null);
  const [countOfBookingsForLabel, setCountOfBookingsForLabel] = useState(null);

  const getChannelName = useCallback((code) => {
    const channel = getChannelInfoByCode(code);
    if (channel) {
      return channel.title;
    }
    return code;
  }, []);

  const onPieEnter = useCallback(
    ({ channel, countOfBookings }, index) => {
      setChannelNameForLabel(getChannelName(channel));
      setCountOfBookingsForLabel(countOfBookings);
      setActiveIndex(index);
    },
    [setActiveIndex, getChannelName],
  );

  const onPieLeave = useCallback(() => {
    setChannelNameForLabel(null);
    setCountOfBookingsForLabel(null);
    setActiveIndex(null);
  }, []);

  const getFillColor = useCallback((code) => {
    const channel = getChannelInfoByCode(code);

    return channel?.color || DEFAULT_COLOR_FILL;
  }, []);

  const totalSources = data.length;

  const totalCountOfBookings = useMemo(() => {
    return data.reduce((acc, el) => {
      acc += el.countOfBookings;
      return acc;
    }, 0);
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartWrapper}>
        <PieChart width={CHART_WIDTH} height={CHART_HEIGHT}>
          <Pie
            activeIndex={activeIndex}
            dataKey={DATA_KEY}
            data={data}
            innerRadius={CHART_INNER_RADIUS}
            outerRadius={CHART_OUTER_RADIUS}
            cornerRadius={CHART_CORNER_RADIUS}
            minAngle={CHART_MIN_ANGLE}
            paddingAngle={CHART_PADDING_ANGLE}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            isAnimationActive={false}
            activeShape={(props) => ActiveShape(props, t, totalSources)}
          >
            {useMemo(
              () => data.map((item, index) => {
                const fillColor = getFillColor(item.channel);
                return <Cell key={`cell-${index}`} fill={fillColor} className={styles.sector} />;
              }),
              [data, getFillColor],
            )}
          </Pie>
        </PieChart>

        <Transition className={styles.chartLabelWrapper}>
          {channelNameForLabel && <p className={styles.chartLabel}>{channelNameForLabel}</p>}
        </Transition>
        <Transition className={styles.chartLabelWrapper}>
          {!channelNameForLabel && (
            <p className={styles.chartLabel}>{t("booking_sources_widget:chart_label")}</p>
          )}
        </Transition>

        <Transition className={styles.chartSubLabelWrapper}>
          {countOfBookingsForLabel && (
            <p className={styles.chartSubLabel}>{countOfBookingsForLabel}</p>
          )}
        </Transition>
        <Transition className={styles.chartSubLabelWrapper}>
          {!countOfBookingsForLabel && (
            <p className={styles.chartSubLabel}>{totalCountOfBookings}</p>
          )}
        </Transition>
      </div>
      <LegendList data={data} activeIndex={activeIndex} />
    </div>
  );
}

SourcesChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default SourcesChart;
