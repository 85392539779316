import i18n from "i18next";

import dayjs from "utils/dayjs";

export const addDate = (date, offset) => {
  return dayjs(date).add(offset, "day").toDate();
};

export const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const format = (date, fmt) => {
  return dayjs(date).format(fmt);
};

export const formatToLocal = (date, fmt) => {
  return dayjs.utc(date).local().format(fmt);
};

export const toLocalFromNow = (date) => {
  return dayjs.utc(date).local().fromNow();
};

export const bookingDate = (date, dateFormat) => {
  if (!date) {
    return i18n.t("general:not_applicable");
  }
  return dayjs(date).format(dateFormat);
};

export const sortStringDates = (dates) => {
  return dates.sort((a, b) => {
    return dayjs(a).diff(dayjs(b));
  });
};
