import React, { useCallback } from "react";

import RefreshButton from "components/forms/buttons/refresh_button";

import useBoolState from "utils/use_bool_state";

import WithToolbar from "../with_toolbar";

export default function WithRefesh({ onRefresh, children }) {
  const [isLoading, setLoading, setLoadingComplete] = useBoolState(false);

  const handleRefreshClick = useCallback(() => {
    setLoading();

    return onRefresh().finally(setLoadingComplete);
  }, [setLoading, setLoadingComplete, onRefresh]);

  return (
    <WithToolbar loading={isLoading} toolbar={<RefreshButton onClick={handleRefreshClick} />}>
      {children}
    </WithToolbar>
  );
}
