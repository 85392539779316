import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Loading from "components/loading";

import styles from "./help_sidebar.module.css";

class InventoryBulkUpdate extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
  };

  render() {
    const { endpoint } = this.props;

    return (
      <div className={styles.helpWrapper}>
        <Loading />
        <iframe title="help_center" className={styles.helpContent} src={endpoint} />
      </div>
    );
  }
}

export default withTranslation()(InventoryBulkUpdate);
