import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover } from "antd";

import useBoolState from "utils/use_bool_state";

import GroupTag from "./group_tag";

import styles from "./groups_column.module.css";

export default function HiddenGroupsPopover({ list }) {
  const { t } = useTranslation();
  const [isPopoverOpen, setPopoverOpen, setPopoverClosed] = useBoolState(false);
  const hiddenGroupsAmount = list.length;

  const popoverContent = useMemo(() => {
    return list.map(({ id, title }) => (
      <GroupTag key={id} id={id} title={title} onClick={setPopoverClosed} />
    ));
  }, [list, setPopoverClosed]);

  if (!hiddenGroupsAmount) {
    return null;
  }

  return (
    <Popover
      title={t("properties_page:columns:groups")}
      content={<div className={styles.popoverContent}>{popoverContent}</div>}
      visible={isPopoverOpen}
      onVisibleChange={setPopoverClosed}
    >
      <Button
        type="link"
        className={styles.popoverToggle}
        onMouseOver={setPopoverOpen}
        onHover={setPopoverOpen}
        onFocus={setPopoverOpen}
      >
        {`+ ${hiddenGroupsAmount}`}
      </Button>
    </Popover>
  );
}
