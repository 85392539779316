import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "utils/dayjs";

import ContentContainer from "../../../../_common/content_container";
import SectionHeader from "../../../../_common/section_header/section_header";

import NoReplyReason from "./no_reply_reason";
import ReplyForm from "./reply_form";
import ReplyMessage from "./reply_message";

const EXPIRATION_TIMEFRAME_IN_DAYS = 30;

export default function Reply({ review }) {
  const { t } = useTranslation();

  const {
    attributes: { isReplied, content, receivedAt },
  } = review;

  const reviewReceivedDaysAgo = dayjs().diff(dayjs(receivedAt), "days");

  const isReplyTimeframeExpired = reviewReceivedDaysAgo >= EXPIRATION_TIMEFRAME_IN_DAYS;
  const isEmptyFeedback = !content;
  const shouldReply = !isReplied && !isEmptyFeedback && !isReplyTimeframeExpired;
  const cannotReply = !isReplied && !shouldReply;

  return (
    <ContentContainer>
      <SectionHeader header={t("reviews_page:feedback:response_subheader")} />
      {isReplied && <ReplyMessage review={review} />}
      {shouldReply && <ReplyForm review={review} />}
      {cannotReply && <NoReplyReason isEmptyFeedback={isEmptyFeedback} isReplyTimeframeExpired={isReplyTimeframeExpired} />}
    </ContentContainer>
  );
}
