import React from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

import styles from "./delete_property_button.module.css";

export default function DeletePropertyButton({ showIcon, onClick }) {
  const { t } = useTranslation();
  const icon = showIcon ? <DeleteOutlined /> : null;

  const btnProps = {};

  if (onClick) {
    btnProps.onClick = onClick;
  }

  return (
    <Button
      className={styles.deleteButton}
      type="link"
      danger
      data-cy="crud_entry_remove_action"
      {...btnProps}
    >
      {icon}
      {t("general:action:remove")}
    </Button>
  );
}
