import React from "react";
import { withTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

function RemoveButton({ t, onClick, className }) {
  return (
    <Button icon={<DeleteOutlined />} danger type="primary" onClick={onClick} className={className}>
      {t("general:action:remove")}
    </Button>
  );
}

export default withTranslation()(RemoveButton);
