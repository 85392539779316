import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import HelpSidebar from "./help_sidebar";

export default function HelpSidebarDrawer({ endpoint, visible, onClose }) {
  return (
    <ChannexDrawer visible={visible} onClose={onClose}>
      {({ handleCloseFromContent }) => (
        <HelpSidebar endpoint={endpoint} onClose={handleCloseFromContent} />
      )}
    </ChannexDrawer>
  );
}
