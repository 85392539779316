import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import UserEditForm from "./user_edit_form";

export default function EditDrawer({ visible, userId, onClose }) {
  const { t } = useTranslation();

  const title = t("admin:user_edit_drawer:header");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {() => (
        <DrawerFormWrapper>
          <UserEditForm userId={userId} onFinish={onClose} />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
