import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import CancellationPolicyWizard from "./cancellation_policy_wizard";

class CanellationPolicyDrawerControlled extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { t, visible, propertyId, currency, onCreate, onClose } = this.props;

    return (
      <ChannexDrawer
        title={t("policies:dict:cancellation_policy")}
        visible={visible}
        onClose={onClose}
        dataCy="cancellation_policy_drawer"
      >
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper withActions>
            <CancellationPolicyWizard
              currency={currency}
              propertyId={propertyId}
              onCreate={onCreate}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(CanellationPolicyDrawerControlled);
