const ensureRatePlansExists = (selectedRatePlans, roomTypes) => {
  const knownRatePlanIds = roomTypes.reduce((acc, roomType) => {
    return [
      ...acc,
      ...roomType.ratePlans.map((ratePlan) => ratePlan.id),
    ];
  }, []);

  const unknownRatePlanIds = Object.keys(selectedRatePlans).filter((ratePlanId) => !knownRatePlanIds.includes(ratePlanId));

  if (unknownRatePlanIds.length) {
    throw new Error(`Unknown rate plan ids: ${unknownRatePlanIds.join(", ")}`);
  }
};

const buildSelectedRatePlans = (value) => {
  const selectedRatePlans = {};

  value.forEach((ratePlanId) => {
    selectedRatePlans[ratePlanId] = true;
  });

  return selectedRatePlans;
};

const buildSelectedRoomTypes = (selectedRatePlans, disabledRatePlans, roomTypes) => {
  return roomTypes.reduce((acc, roomType) => {
    const selected = roomType.ratePlans.every((ratePlan) => selectedRatePlans[ratePlan.id]);
    const indeterminate = !selected && roomType.ratePlans.some((ratePlan) => selectedRatePlans[ratePlan.id]);
    const disabled = roomType.ratePlans.every((ratePlan) => disabledRatePlans[ratePlan.id]);

    return {
      ...acc,
      [roomType.id]: {
        selected,
        disabled,
        indeterminate,
      },
    };
  }, {});
};

const calcAllSelected = (selectedRoomTypes) => {
  const selectedRoomTypesValues = Object.values(selectedRoomTypes);

  if (selectedRoomTypesValues.length === 0) {
    return false;
  }

  return selectedRoomTypesValues.every((roomType) => roomType.selected);
};

const calcAllIndeterminate = (selectedRoomTypes) => {
  const selectedRoomTypesValues = Object.values(selectedRoomTypes);

  if (selectedRoomTypesValues.length === 0) {
    return false;
  }

  const someRoomIsIndeterminate = selectedRoomTypesValues.some((roomType) => roomType.indeterminate);
  const someRoomIsSelected = selectedRoomTypesValues.some((roomType) => roomType.selected);
  const allRoomsAreSelected = selectedRoomTypesValues.every((roomType) => roomType.selected);

  return !allRoomsAreSelected && (someRoomIsIndeterminate || someRoomIsSelected);
};

const calcAllDisabled = (selectedRoomTypes) => {
  const selectedRoomTypesValues = Object.values(selectedRoomTypes);

  if (selectedRoomTypesValues.length === 0) {
    return false;
  }

  return selectedRoomTypesValues.every((roomType) => roomType.disabled);
};

export { buildSelectedRatePlans };

export default function calcSelectorState(value, disabledRatePlansAsValue, roomTypes) {
  const selectedRatePlans = buildSelectedRatePlans(value || []);
  const disabledRatePlans = buildSelectedRatePlans(disabledRatePlansAsValue || []);

  ensureRatePlansExists({ ...selectedRatePlans, ...disabledRatePlans }, roomTypes);

  const selectedRoomTypes = buildSelectedRoomTypes(selectedRatePlans, disabledRatePlans, roomTypes);

  const allSelected = calcAllSelected(selectedRoomTypes);
  const allIndeterminate = calcAllIndeterminate(selectedRoomTypes);
  const allDisabled = calcAllDisabled(selectedRoomTypes);

  return {
    selectedRatePlans,
    disabledRatePlans,
    selectedRoomTypes,
    allSelected,
    allIndeterminate,
    allDisabled,
  };
}
