import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

export default function useAppForm(options) {
  const {
    validationSchema,
    defaultValue,
    fieldNames,
    submitHandler = () => {},
  } = options;

  const {
    handleSubmit: originalHandleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    control,
    resetField,
    watch,
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: defaultValue,
  });

  const handleSubmit = () => {
    clearErrors();

    originalHandleSubmit(async (values) => {
      try {
        await submitHandler(values);
      } catch (error) {
        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, fieldNames);

        if (Object.keys(globalErrors).length !== 0) {
          setError("root.global", {
            errors: globalErrors,
          });
        }

        Object.entries(formErrors).forEach(([fieldName, errorMessage]) => {
          setError(fieldName, {
            type: "api",
            message: errorMessage,
          });
        });
      }
    })();
  };

  return {
    handleSubmit,
    errors,
    clearErrors,
    setError,
    control,
    resetField,
    watch,
  };
}
