const buildMultioccupancyRatesList = (rates, t) => {
  const generatedRates = rates.reduce((acc, rate) => {
    const { max_persons, occupancies, ...otherRateProps } = rate;

    if (Array.isArray(occupancies) && occupancies.length) {
      occupancies.forEach((occupancy) => {
        acc.push({ ...otherRateProps, occupancy });
      });

      return acc;
    }

    const updatedRate = { ...otherRateProps, occupancy: max_persons };

    acc.push(updatedRate);

    if (updatedRate.price_1) {
      acc.push({
        occupancy: 1,
        title: `${updatedRate.title} - ${t("channels_page:form:single_occupancy")}`,
        id: updatedRate.id,
        readonly: updatedRate.readonly,
      });
    }

    return acc;
  }, []);

  return generatedRates;
};

export default buildMultioccupancyRatesList;
