import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col, Form, notification, Row, Statistic, Typography } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import FormTextarea from "components/forms/inputs/form_textarea";

import dayjs from "utils/dayjs";

import styles from "./shared.module.css";

const { Title } = Typography;
const { Reviews } = store;
const EXPIRATION_TIMEFRAME_IN_DAYS = 30;

function NoReplied({ review }) {
  const { t } = useTranslation();
  const [reply, setReply] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(
    function loadRequiredAppSettings() {
      setReply(null);
      setLoading(false);
    },
    [review],
  );

  const {
    attributes: { content, scores, receivedAt },
  } = review;

  const daysAgo = dayjs().diff(dayjs(receivedAt), "days");
  const formIsVisible = daysAgo < EXPIRATION_TIMEFRAME_IN_DAYS && content;
  const handleReplyChange = (_field, fieldValue) => {
    setReply(fieldValue);
  };

  const submitReply = () => {
    setLoading(true);
    Reviews.reply(review.id, reply).then(
      (_repliedReview) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: error.errors.title,
        });
      },
    );
  };

  return (
    <>
      <div className={styles.scores}>
        <Row justify="space-between">
          {scores.map((score) => (
            <Col span={3} key={score.category} className={styles.score}>
              <Statistic
                title={t(`reviews_page:scores:${score.category}`)}
                value={score.score}
                suffix="/ 10"
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className={styles.feedbackContent}>
        <Title level={4}>{t("reviews_page:feedback:guest_subheader")}</Title>
        <p className={styles.feedback}>{content || t("general:not_applicable")}</p>
        <Title level={4}>{t("reviews_page:feedback:response_subheader")}</Title>
        {formIsVisible && (
          <Form onFinish={submitReply}>
            <FormTextarea
              placeholder={t("reviews_page:feedback:response_subheader")}
              name="reply"
              value={reply}
              view="fullWidth"
              onChange={handleReplyChange}
            />
            <SubmitButton disabled={!reply} loading={loading}>
              {t("reviews_page:feedback:send")}
            </SubmitButton>
          </Form>
        )}
        {!content && t("reviews_page:feedback:not_allowed_for_empty")}
        {daysAgo >= EXPIRATION_TIMEFRAME_IN_DAYS
          && t("reviews_page:feedback:not_allowed_for_expired")}
      </div>
    </>
  );
}

NoReplied.propTypes = {
  review: PropTypes.object,
};

export default NoReplied;
