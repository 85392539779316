import React, { useCallback, useState } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getWhiteLabelFlag } from "store/storage/selectors/session_selector";

import appModes from "config/constants/app_modes";
import WHITE_LABEL_FLAGS from "config/constants/white_label_flags";

import AppModeRenderer from "components/app_mode_renderer";
import GroupFilter from "components/group_filter/group_filter";
import HelpLink from "components/help_link/help_link";
import PropertyFilter from "components/property_filter/property_filter";

import Logo from "../logo";
import Navigation from "../navigation";
import UserDropDown from "../user_drop_down";

import styles from "./header.module.css";

const NAVIGATION_BREAKPOINT = 1275;

function Header() {
  const [isNavigationMenuOpened, setIsNavigationMenuOpened] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const isHelpDisabled = useSelector(getWhiteLabelFlag(WHITE_LABEL_FLAGS.DISABLE_HELP));

  const handleToggleMenu = useCallback(() => {
    if (!isDesktop) {
      setIsNavigationMenuOpened(!isNavigationMenuOpened);
    }
    return null;
  }, [isNavigationMenuOpened, isDesktop]);

  const toggleDesktopMode = (isDesktopMode) => (matches) => {
    if (!matches) {
      return;
    }

    if (isDesktopMode) {
      setIsNavigationMenuOpened(false);
    }

    setIsDesktop(isDesktopMode);
  };

  const headerLeftClassName = classNames(styles.header__Left, {
    [`${styles.header__Left__opened}`]: isNavigationMenuOpened,
  });

  return (
    <AppModeRenderer visible={[appModes.DEFAULT]}>
      <Media query={`(min-width: ${NAVIGATION_BREAKPOINT}px)`} onChange={toggleDesktopMode(true)} />
      <Media query={`(max-width: ${NAVIGATION_BREAKPOINT}px)`} onChange={toggleDesktopMode(false)} />
      <div data-cy="header" className={styles.header}>
        <div className={headerLeftClassName}>
          <Logo handleToggleMenu={handleToggleMenu} />

          <Navigation
            isOpened={isNavigationMenuOpened}
            onToggleMenu={handleToggleMenu}
            onCloseMenu={handleToggleMenu}
          />
        </div>
        <div className={styles.header__Right}>
          <GroupFilter isMobile={!isDesktop} />
          <PropertyFilter isMobile={!isDesktop} />
          {isHelpDisabled ? null : <HelpLink />}
          <UserDropDown isMobile={!isDesktop} />
        </div>
      </div>
    </AppModeRenderer>
  );
}
export default Header;
