import React from "react";

import PrivateFeedback from "./private_feedback/private_feedback";
import PublicFeedback from "./public_feedback/public_feedback";

export default function Airbnb({ review }) {
  return (
    <div>
      <PublicFeedback review={review} />
      <PrivateFeedback review={review} />
    </div>
  );
}
