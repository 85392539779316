import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import memoizeOne from "memoize-one";
import currencies from "world-currencies";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import withProperty from "data/use_property/hoc";
import withRatePlans from "data/use_rate_plans/hoc";
import withRoomTypes from "data/use_room_types/hoc";
import combineRoomTypesWithRatePlans from "data/utils/combine_room_types_with_rate_plans";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormRateSelector from "components/forms/inputs/formik/form_rate_selector";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import Loading from "components/loading";

import alphabetSort from "utils/alphabet_sort";

import TaxSetCalculator from "./tax_set_calculator";
import withTaxSetCalculatorLogic from "./tax_set_calculator_logic";
import withTaxSetFormLogic from "./tax_set_form_logic";
import TaxTreeInput from "./tax_tree_input";

import styles from "./tax_set_form.module.css";
import formStyles from "styles/form_in_drawer.module.css";

class TaxSetForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    title: yup.string().trim().required(errorMessages.required()),
    currency: yup.string().required(errorMessages.required()),
    associated_rate_plan_ids: yup.array(),
    taxes: yup.array(),
  });

  DEFAULT_VALUE = {
    title: "",
    associated_rate_plan_ids: [],
    currency: undefined,
    taxes: [],
  };

  combineRoomTypesWithRatePlans = memoizeOne(combineRoomTypesWithRatePlans);

  getCurrencyOptions = memoizeOne(() => {
    return Object.values(currencies)
      .map((el) => {
        return {
          value: el.iso.code,
          representation: `${el.name} (${el.iso.code})`,
        };
      })
      .sort(alphabetSort("representation"));
  });

  // param as invalidate cache key;
  getInitialAssociatedRatePlanIds = memoizeOne((_taxSetId) => {
    const { value } = this.props;

    return [...value.associated_rate_plan_ids || []] || [];
  });

  render() {
    const {
      t,
      componentRef,
      value,
      errors,
      submitting,
      propertyId,
      taxSetCalculatorProps,
      isPropertyLoading,
      property,
      isRatePlansLoading,
      ratePlans,
      isRoomTypesLoading,
      roomTypes,
      onSubmit,
      onChange,
    } = this.props;

    if (isPropertyLoading || isRatePlansLoading || isRoomTypesLoading) {
      return <Loading />;
    }

    const { taxes = [] } = value;
    const showTaxCalculator = Boolean(taxes.length);
    const roomTypesWithRatePlans = this.combineRoomTypesWithRatePlans(roomTypes, ratePlans);

    // when we deal with tax set which is the default for property we should not allow to
    // deselect currently associated rate plans, because it will lead to the situation when
    // rate plan will lose any associated tax set when default cancellation policy gets deassociated
    const isDefault = property.default_tax_set_id === value.id;
    const disabledRatePlans = isDefault ? this.getInitialAssociatedRatePlanIds(value.id) : [];

    return (
      <>
        <ChannexForm
          withDebounce={false}
          value={value}
          errors={errors}
          defaultValue={this.DEFAULT_VALUE}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit} className={styles.form}>
              <FormikFormInput
                name="title"
                placeholder={t("taxes:tax_set:form:title")}
                label={t("taxes:tax_set:form:title")}
              />

              <FormikFormSelect
                name="currency"
                view="horizontal"
                placeholder={t("taxes:tax_set:form:currency_placeholder")}
                label={t("taxes:tax_set:form:currency_label")}
                options={this.getCurrencyOptions()}
              />

              <FormRateSelector
                label={t("taxes:tax_set:form:associated_rate_plans_label")}
                name="associated_rate_plan_ids"
                roomTypes={roomTypesWithRatePlans}
                disabledRatePlans={disabledRatePlans}
              />

              <TaxTreeInput name="taxes" propertyId={propertyId} />
            </Form>
          )}
        </ChannexForm>

        {showTaxCalculator && <TaxSetCalculator {...taxSetCalculatorProps} />}

        <div className={formStyles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default (
  withProperty(
    withRatePlans({ groupResults: true })(
      withRoomTypes({ sorted: true })(
        withTaxSetFormLogic(
          withTaxSetCalculatorLogic(
            withTranslation()(
              TaxSetForm,
            ),
          ),
        ),
      ),
    ),
  )
);
