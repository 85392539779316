import { convertToCamelCase } from "store/utils/case_converter";
import { v4 as uuidv4 } from "uuid";

import messageStatuses from "config/constants/message_statuses";

let transport;
let storage;

const ENDPOINT = "message_threads";

export default class ChannelMessagesThreads {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  threadsList(
    filter = {},
    pagination = {},
    order = {
      last_message_received_at: "desc",
    },
  ) {
    const requestId = uuidv4();
    storage.channelMessagesThreadsSetRequestId(requestId);

    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.channelMessagesThreadsLoad(data, response.meta, requestId);
      return response;
    });
  }

  thread(threadId) {
    return transport.send("GET", `${ENDPOINT}/${threadId}`).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.channelMessagesAddThread(data);
      return data;
    });
  }

  closeThread(threadId) {
    return transport.send("POST", `${ENDPOINT}/${threadId}/close`).then((response) => {
      storage.channelMessagesCloseThread(response.data.id);
      return response;
    });
  }

  openThread(threadId) {
    return transport.send("POST", `${ENDPOINT}/${threadId}/open`).then((response) => {
      storage.channelMessagesOpenThread(response.data.id);
      return response;
    });
  }

  resetThreads() {
    return storage.channelMessagesResetThreads();
  }

  messagesListByThreadId(threadId, pagination = {}, order = { inserted_at: "desc" }) {
    return transport
      .send("GET", `${ENDPOINT}/${threadId}/messages`, { pagination, order, system_events: true })
      .then((response) => {
        const data = convertToCamelCase(response.data);
        storage.channelMessagesLoad(threadId, data, response.meta);
        return response;
      });
  }

  resetMessagesList(threadId) {
    return storage.channelMessagesReset(threadId);
  }

  sendMessageByThreadId(threadId, message) {
    const sendMessage = {
      message: {
        message: message.attributes.message,
        request_id: message.requestId,
      },
    };

    storage.channelMessagesAdd(threadId, message);

    return transport
      .send("POST", `${ENDPOINT}/${threadId}/messages`, sendMessage)
      .then((response) => {
        const newMessage = { ...response.data, requestId: message.requestId };

        storage.channelMessagesUpdateThreadByLatestMessage(threadId, message.attributes.message);
        storage.channelMessagesAdd(threadId, newMessage);
        return response;
      })
      .catch((error) => {
        const newMessage = {
          attributes: {
            ...message.attributes,
            status: messageStatuses.FAILED,
          },
          requestId: message.requestId,
        };

        storage.channelMessagesAdd(threadId, newMessage);
        throw error;
      });
  }

  handleMessageByThreadId(threadId, message) {
    const msg = {
      attributes: {
        attachments: message.attachments,
        insertedAt: message.inserted_at,
        message: message.message,
        sender: message.sender,
        updatedAt: message.updated_at,
        meta: convertToCamelCase(message.meta),
      },
      id: message.id,
      relationships: {
        messageThread: {
          data: {
            id: message.message_thread_id,
            type: "message_thread",
          },
        },
      },
      type: "message",
      requestId: message.request_id || uuidv4(),
    };
    storage.channelMessagesAdd(threadId, msg);
  }
}
