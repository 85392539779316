import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import eventTypeOptions from "config/constants/event_type_options";

import dayjs from "utils/dayjs";

import styles from "./events_list.module.css";

function EventsListItemNewMessage({ item }) {
  const { t } = useTranslation();
  const {
    event,
    payload: { message, have_attachment },
    inserted_at,
  } = item;

  const eventTitle = useMemo(() => {
    const findEventOption = eventTypeOptions.find((i) => i === event);
    if (findEventOption) {
      return t(`live_feed_events_widget:events:${findEventOption}`).toUpperCase();
    }
    return null;
  }, [t, event]);

  const insertedAtDateValue = dayjs(inserted_at).format(t("formats:date_time_with_weekday"));
  const insertedAtTimeValue = dayjs(inserted_at).format(t("formats:iso_time_with_seconds"));
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);

  const messageText = !message && have_attachment ? t("messages_page:thread:attachment") : message;

  return (
    <div>
      <p className={eventTitleClassName}>{eventTitle}</p>
      <p className={styles.message}>{messageText}</p>
      <p className={styles.description}>
        {insertedAtDateValue} <span>|</span> {insertedAtTimeValue}
      </p>
    </div>
  );
}

EventsListItemNewMessage.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemNewMessage;
