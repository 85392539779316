import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/form_input";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import styles from "../../pages/applications_page/application_settings/shared.module.css";

const FIELD_LAYOUT = {
  span: 24,
};

export default function HookForm({ value, validationSchema, fields = [], submit, isSubmitting, onFinish }) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    control,
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: value,
  });

  const submitHandler = async (values) => {
    try {
      await submit(values);

      onFinish();
    } catch (error) {
      const parsedErrors = parseApiErrors(error);
      const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, fields.map(({ field }) => field));

      if (Object.keys(globalErrors).length !== 0) {
        setError("root.global", {
          errors: globalErrors,
        });
      }

      Object.entries(formErrors).forEach(([fieldName, errorMessage]) => {
        setError(fieldName, {
          type: "api",
          message: errorMessage,
        });
      });
    }
  };

  return (
    <div>
      <Form>
        <GlobalErrors hookForm errors={errors} />

        {fields.map(({ field: fieldName, label }) => (
          <Row key={fieldName}>
            <Col {...FIELD_LAYOUT}>
              <Controller
                name={fieldName}
                control={control}
                render={({ field }) => (
                  <FormInput
                    hookForm
                    label={label}
                    errors={errors?.[fieldName]?.message}
                    {...field}
                  />
                )}
              />
            </Col>
          </Row>
        ))}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={() => { clearErrors(); handleSubmit(submitHandler)(); }} loading={isSubmitting}>
          {t("general:action:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
