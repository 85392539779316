import React, { Component } from "react";
import { Form, InputNumber } from "antd";

import { horizontalFormItemLayout, verticalFormItemLayout } from "config/constants/layouts/form";

import AddonAfterWrapper from "../components/addon_after_wrapper";
import getFieldLayout from "../utils/get_field_layout";

import styles from "./form_input_number.module.css";

class FormInputNumber extends Component {
  state = {
    value: null,
  };

  componentDidMount() {
    const { defaultValue } = this.props;

    this.setState({
      value: defaultValue,
    });
  }

  validation = () => {
    const { errors } = this.props;

    if (errors) {
      const message = Array.isArray(errors) ? errors.join(". ") : errors;

      return {
        validateStatus: "error",
        help: <span data-cy="input_error_message">{message}</span>,
      };
    }

    return {};
  };

  getLayout = () => {
    const { view = "horizontal" } = this.props;

    return view === "horizontal" ? horizontalFormItemLayout : verticalFormItemLayout;
  };

  renderIcon = () => {
    const { icon } = this.props;

    if (icon && React.isValidElement(icon)) {
      return React.cloneElement(icon, { style: { color: "rgba(0,0,0,.25)" } });
    }

    return null;
  };

  getValue = () => {
    const { value, onChange } = this.props;

    return onChange && value !== undefined ? value : this.state.value;
  };

  onChange = (value) => {
    const { name, min, allowEmpty, onChange } = this.props;
    const isMinValueSet = min !== undefined;
    const isEmptyInput = value === null;

    if (!allowEmpty && isMinValueSet && isEmptyInput) {
      value = min;
    }

    this.setState({ value });

    if (onChange && typeof onChange === "function") {
      onChange(value, name);
    }
  };

  // react hook form requires this method to be defined
  focus = () => {};

  render() {
    const {
      name,
      step,
      min,
      max,
      placeholder,
      inputRef,
      label,
      extra,
      defaultValue,
      precision,
      decimalSeparator,
      disabled,
      addonAfter,
      addonBefore,
      grouped = false,
      onBlur = () => {},
    } = this.props;

    const formItemLayout = grouped ? {} : getFieldLayout();
    const dataCy = name ? `input_${name.replace(".", "_")}` : null;

    return (
      <Form.Item
        {...formItemLayout}
        {...this.validation()}
        label={label}
        data-cy={dataCy}
        extra={extra}
      >
        <div className="ant-input-group-wrapper">
          <div className="ant-input-wrapper ant-input-group">
            {addonBefore && (
              <span className="ant-input-group-addon">
                <AddonAfterWrapper>{addonBefore}</AddonAfterWrapper>
              </span>
            )}
            <InputNumber
              className={styles.input}
              precision={Number(precision)}
              value={this.getValue()}
              onChange={this.onChange}
              onBlur={onBlur}
              decimalSeparator={decimalSeparator}
              prefix={this.renderIcon()}
              min={min}
              max={max}
              step={step}
              disabled={disabled}
              placeholder={placeholder}
              defaultValue={defaultValue}
              ref={inputRef}
              name={name}
            />
            {addonAfter && (
              <span className="ant-input-group-addon">
                <AddonAfterWrapper>{addonAfter}</AddonAfterWrapper>
              </span>
            )}
          </div>
        </div>
      </Form.Item>
    );
  }
}

export default FormInputNumber;
