import React, { useState } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import { Outlet, useMatch } from "react-router-dom";
import classNames from "classnames";
import { getIsAppInstalled } from "store/storage/selectors/applications_selector";
import { getActiveProperty, getAppMode } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import appModes from "config/constants/app_modes";

import Application from "components/application";

import useRouting from "routing/use_routing";

import NoAppPlaceholder from "./components/no_app_placeholder";
import NonSelectedPlaceholder from "./components/non_selected_placeholder/non_selected_placeholder";
import ReviewsList from "./components/reviews_list";
import ScoreStatistics from "./components/score_statistics";

import styles from "./reviews_page.module.css";

const applicationParams = { applicationCode: appCodes.messages };

function ReviewsPage() {
  const [isMobile, setIsMobile] = useState(false);
  const activeProperty = useSelector(getActiveProperty);
  const appMode = useSelector(getAppMode);
  const { userAppRoutes } = useRouting();

  const match = useMatch({ path: userAppRoutes.reviews.review._path, end: false });
  const reviewId = match?.params?.review_id;

  const appParams = { ...applicationParams };

  if (activeProperty) {
    appParams.propertyId = activeProperty;
  }

  const isInstalled = useSelector(getIsAppInstalled(appParams));

  const handleMediaChange = (newVal) => (matches) => matches && setIsMobile(newVal);

  if (!isInstalled) {
    return <NoAppPlaceholder />;
  }

  const containerClassName = classNames(styles.layout, {
    [`${styles.layout__headless}`]: appMode === appModes.HEADLESS,
  });

  return (
    <>
      <Media query="(max-width: 930px)" onChange={handleMediaChange(true)} />
      <Media query="(min-width: 931px)" onChange={handleMediaChange(false)} />
      <Application params={applicationParams}>
        <div className={containerClassName}>
          {isMobile === true && reviewId ? null : (
            <div className={isMobile ? styles.sideBar : null}>
              <ScoreStatistics isMobile={isMobile} />
              <ReviewsList isMobile={isMobile} />
            </div>
          )}
          {!reviewId && !isMobile && <NonSelectedPlaceholder />}
          <Outlet context={{ isMobile }} />
        </div>
      </Application>
    </>
  );
}

export default ReviewsPage;
