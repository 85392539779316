import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { arrayMove } from "react-sortable-hoc";
import store from "store";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import Loading from "components/loading";

import EventEmmiter from "utils/event_emmiter";

import { SortableItem, SortableList } from "./sortable_list";

import styles from "./sortable_list/styles.module.css";

const { RoomTypes } = store;

export default function InventorySettingsForm({ propertyId }) {
  const { t } = useTranslation();

  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, groupResults: true });
  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sortedBy: "position" });
  const [items, setItems] = React.useState([]);
  const [isGrabbing, setIsGrabbing] = React.useState(false);

  const [sortState, setSortState] = React.useState({});

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return;
    }

    const combinedItems = (roomTypes || []).map((roomType) => {
      roomType.ratePlans = ratePlans[roomType.id];

      return roomType;
    });

    setItems(combinedItems);
  }, [ratePlans, roomTypes, isRatePlansLoading, isRoomTypesLoading]);

  if (isRatePlansLoading || isRoomTypesLoading) {
    return <Loading />;
  }

  const handleSortChange = ({ oldIndex, newIndex }) => {
    setIsGrabbing(false);

    if (oldIndex === newIndex) {
      return;
    }

    if (sortState.type === "rooms") {
      const reorderedItems = arrayMove(items, oldIndex, newIndex);
      setItems(reorderedItems);

      const newPositions = reorderedItems.map((item, index) => ({ id: item.id, position: index }));
      RoomTypes.updatePositions(newPositions)
        .then(() => {
          EventEmmiter.trigger("room_type:created");
        });
    }

    if (sortState.type === "rate") {
      const newItems = [...items];
      const room = newItems[sortState.roomIndex];
      room.ratePlans = arrayMove(room.ratePlans, oldIndex, newIndex);
      setItems(newItems);
    }
  };

  const sortingRatePlansEnabled = false;

  return (
    <div style={{ padding: 12 }}>
      <legend>{t("inventory_settings_drawer:rooms_order")}</legend>
      <SortableList
        lockAxis="y"
        items={items}
        useWindowAsScrollContainer
        onSortStart={() => {
          setIsGrabbing(true);
          setSortState({
            type: "rooms",
          });
        }}
        onSortEnd={handleSortChange}
        renderItem={(room, roomIndex) => (
          <SortableItem style={{ padding: "4px 8px" }} lockAxis="y" key={`item-${roomIndex}`} index={roomIndex} isGrabbing={isGrabbing}>
            <span style={{ cursor: "default" }}>{room.title}</span>
            {sortingRatePlansEnabled && (
              <div style={{ marginLeft: 24 }}>
                <SortableList
                  onSortStart={() => {
                    setIsGrabbing(true);
                    setSortState({
                      type: "rate",
                      roomIndex,
                    });
                  }}
                  onSortEnd={handleSortChange}
                  items={room.ratePlans || []}
                  renderItem={(rate, rateIndex) => (
                    <SortableItem style={{ padding: "0 8px" }} key={`item-${rateIndex}`} index={rateIndex} isGrabbing={isGrabbing}>
                      {rate.title}
                    </SortableItem>
                  )}
                  helperClass={styles.sortableHelper}
                />
              </div>
            )}
          </SortableItem>
        )}
        helperClass={styles.sortableHelper}
      />
    </div>
  );
}
