import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import _ from "lodash";
import * as yup from "yup";

import airbnbCurrencies from "config/constants/channels/airbnb_currencies";
import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikCurrencySelect from "components/forms/inputs/formik/form_currency_select";
import FormikInput from "components/forms/inputs/formik/form_input";
import FormikInputNumber from "components/forms/inputs/formik/form_input_number";
import FormikSelect from "components/forms/inputs/formik/form_select";
import Layouts from "components/layouts";

import drawerFormStyles from "styles/form_in_drawer.module.css";

const MAX_PERCENT_VALUE = 100;

class ListingPriceForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape(
    (() => {
      const priceValidationSchema = yup
        .number(errorMessages.number())
        .required(errorMessages.required())
        .typeError(errorMessages.number())
        .min(0, errorMessages.greaterOrEqual(0));

      return {
        listing_currency: yup.string().required(errorMessages.required()),
        default_daily_price: priceValidationSchema,
        weekend_price: priceValidationSchema,
        monthly_price_factor: priceValidationSchema.max(99, errorMessages.lessOrEqual(99)),
        weekly_price_factor: priceValidationSchema.max(99, errorMessages.lessOrEqual(99)),
        price_per_extra_person: priceValidationSchema,
        guests_included: priceValidationSchema,
        standard_fees: yup.object({
          pass_through_resort_fee: yup.object({
            amount_type: yup.string().required(errorMessages.required()),
            amount: yup.number().when("amount_type", {
              is: "percent",
              then: priceValidationSchema.max(
                MAX_PERCENT_VALUE,
                errorMessages.lessOrEqual(MAX_PERCENT_VALUE),
              ),
              otherwise: priceValidationSchema,
            }),
          }),
          pass_through_management_fee: yup.object({
            amount_type: yup.string().required(errorMessages.required()),
            amount: yup.number().when("amount_type", {
              is: "percent",
              then: priceValidationSchema.max(
                MAX_PERCENT_VALUE,
                errorMessages.lessOrEqual(MAX_PERCENT_VALUE),
              ),
              otherwise: priceValidationSchema,
            }),
          }),
          pass_through_community_fee: yup.object({
            amount_type: yup.string().required(errorMessages.required()),
            amount: yup.number().when("amount_type", {
              is: "percent",
              then: priceValidationSchema.max(
                MAX_PERCENT_VALUE,
                errorMessages.lessOrEqual(MAX_PERCENT_VALUE),
              ),
              otherwise: priceValidationSchema,
            }),
          }),
          pass_through_linen_fee: yup.object({
            charge_type: yup.string().required(errorMessages.required()),
            amount: priceValidationSchema,
          }),
          pass_through_pet_fee: yup.object({
            amount_type: yup.string().required(errorMessages.required()),
            amount: priceValidationSchema,
          }),
          pass_through_cleaning_fee: yup.object({
            amount: priceValidationSchema,
          }),
          pass_through_short_term_cleaning_fee: yup.object({
            amount: priceValidationSchema,
          }),
          pass_through_security_deposit: yup.object({
            amount: priceValidationSchema,
          }),
        }),
      };
    })(),
  );

  t = (varPath) => {
    return this.props.t(`channels_page:form:listing:price_drawer:${varPath}`);
  };

  getAmountTypeOptions = () => {
    const activeCurrency = this.props.value.listing_currency;

    return [
      {
        value: "flat",
        representation: activeCurrency,
      },
      {
        value: "percent",
        representation: "%",
      },
    ];
  };

  getChargeTypeOptions = () => {
    return [
      {
        value: "PER_GROUP",
        representation: this.t("per_group"),
      },
      {
        value: "PER_PERSON",
        representation: this.t("per_person"),
      },
    ];
  };

  render() {
    const {
      t,
      componentRef,
      value,
      submitting,
      errors,
      globalErrors,
      onRefresh,
      onChange,
      onSubmit,
    } = this.props;

    return (
      <>
        <Layouts.WithRefresh onRefresh={onRefresh}>
          <ChannexForm
            value={value}
            errors={errors}
            globalErrors={globalErrors}
            componentRef={componentRef}
            validationSchema={this.VALIDATION_SCHEMA}
            onChange={onChange}
          >
            {({ handleSubmit }) => (
              <Form onFinish={handleSubmit}>
                <legend>{this.t("currency_legend")}</legend>
                <FormikCurrencySelect
                  name="listing_currency"
                  placeholder={this.t("currency")}
                  label={this.t("currency")}
                  availableCurrencies={airbnbCurrencies}
                />

                <legend>{this.t("pricing_settings_legend")}</legend>
                <FormikInputNumber
                  min="0"
                  precision="0"
                  name="default_daily_price"
                  placeholder={this.t("default_daily_price")}
                  label={this.t("default_daily_price")}
                />
                <FormikInputNumber
                  min="0"
                  precision="0"
                  name="weekend_price"
                  placeholder={this.t("default_weekend_price")}
                  label={this.t("default_weekend_price")}
                />

                <legend>{this.t("length_of_stay_discounts_legend")}</legend>
                <FormikInput
                  name="monthly_price_factor"
                  placeholder={this.t("monthly_stay_discount")}
                  label={this.t("monthly_stay_discount")}
                  addonAfter="%"
                />
                <FormikInput
                  name="weekly_price_factor"
                  placeholder={this.t("weekly_stay_discount")}
                  label={this.t("weekly_stay_discount")}
                  addonAfter="%"
                />

                <legend>{this.t("standart_fees_and_charges_legend")}</legend>
                <FormikInputNumber
                  min="0"
                  precision="0"
                  name="price_per_extra_person"
                  placeholder={this.t("price_per_extra_guest")}
                  label={this.t("price_per_extra_guest")}
                />
                <FormikInputNumber
                  min="0"
                  precision="0"
                  name="guests_included"
                  placeholder={this.t("guests_included")}
                  label={this.t("guests_included")}
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_resort_fee.amount"
                  capturedErrors={["standard_fees.pass_through_resort_fee.amount_type"]}
                  placeholder={this.t("pass_through_resort_fee")}
                  label={this.t("pass_through_resort_fee")}
                  addonAfter={
                    <FormikSelect
                      showSearch={false}
                      name="standard_fees.pass_through_resort_fee.amount_type"
                      placeholder={this.t("fee_type")}
                      options={this.getAmountTypeOptions()}
                    />
                  }
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_management_fee.amount"
                  capturedErrors={["standard_fees.pass_through_management_fee.amount_type"]}
                  placeholder={this.t("pass_through_management_fee")}
                  label={this.t("pass_through_management_fee")}
                  addonAfter={
                    <FormikSelect
                      showSearch={false}
                      name="standard_fees.pass_through_management_fee.amount_type"
                      placeholder={this.t("fee_type")}
                      options={this.getAmountTypeOptions()}
                    />
                  }
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  capturedErrors={["standard_fees.pass_through_community_fee.amount_type"]}
                  name="standard_fees.pass_through_community_fee.amount"
                  placeholder={this.t("pass_through_community_fee")}
                  label={this.t("pass_through_community_fee")}
                  addonAfter={
                    <FormikSelect
                      showSearch={false}
                      name="standard_fees.pass_through_community_fee.amount_type"
                      placeholder={this.t("fee_type")}
                      options={this.getAmountTypeOptions()}
                    />
                  }
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_linen_fee.amount"
                  capturedErrors={["standard_fees.pass_through_linen_fee.charge_type"]}
                  placeholder={this.t("pass_through_linen_fee")}
                  label={this.t("pass_through_linen_fee")}
                  addonAfter={
                    <FormikSelect
                      showSearch={false}
                      name="standard_fees.pass_through_linen_fee.charge_type"
                      placeholder={this.t("fee_type")}
                      options={this.getChargeTypeOptions()}
                    />
                  }
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_pet_fee.amount"
                  capturedErrors={["standard_fees.pass_through_pet_fee.amount_type"]}
                  placeholder={this.t("pass_through_pet_fee")}
                  label={this.t("pass_through_pet_fee")}
                  addonAfter={this.props.value.listing_currency}
                />
                <input
                  name="standard_fees.pass_through_pet_fee.amount_type"
                  type="hidden"
                  value="flat"
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_security_deposit.amount"
                  capturedErrors={["standard_fees.pass_through_security_deposit.amount_type"]}
                  placeholder={this.t("pass_through_security_deposit")}
                  label={this.t("pass_through_security_deposit")}
                  addonAfter={this.props.value.listing_currency}
                />
                <input
                  name="standard_fees.pass_through_security_deposit.amount_type"
                  type="hidden"
                  value="flat"
                />
                <legend>{this.t("cleaning_legend")}</legend>
                <small>{this.t("cleaning_description")}</small>
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_cleaning_fee.amount"
                  capturedErrors={["standard_fees.pass_through_cleaning_fee.amount_type"]}
                  placeholder={this.t("pass_through_cleaning_fee")}
                  label={this.t("pass_through_cleaning_fee")}
                  addonAfter={this.props.value.listing_currency}
                />
                <input
                  name="standard_fees.pass_through_cleaning_fee.amount_type"
                  type="hidden"
                  value="flat"
                />
                <FormikInputNumber
                  min="0"
                  precision="2"
                  name="standard_fees.pass_through_short_term_cleaning_fee.amount"
                  capturedErrors={[
                    "standard_fees.pass_through_short_term_cleaning_fee.amount_type",
                  ]}
                  placeholder={this.t("pass_through_short_term_cleaning_fee")}
                  label={this.t("pass_through_short_term_cleaning_fee")}
                  addonAfter={this.props.value.listing_currency}
                />
                <input
                  name="standard_fees.pass_through_short_term_cleaning_fee.amount_type"
                  type="hidden"
                  value="flat"
                />
              </Form>
            )}
          </ChannexForm>
        </Layouts.WithRefresh>
        <div className={drawerFormStyles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default withTranslation()(ListingPriceForm);
