export default [
  "ari",
  "booking",
  "booking_new",
  "booking_modification",
  "booking_cancellation",
  "sync_error",
  "reservation_request",
  "booking_unmapped_room",
  "booking_unmapped_rate",
  "sync_warning",
  "message",
  "alteration_request",
  "inquiry",
];
