import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

import List from "components/list";

function TriplaPropertyInfo({ triplaProperty }) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <List>
          <List.Item term={t("applications_page:tripla_import:property_title")} description={triplaProperty.title} />
          <List.Item term={t("applications_page:tripla_import:property_currency")} description={triplaProperty.currency} />
          <List.Item term={t("applications_page:tripla_import:property_email")} description={triplaProperty.email} />
          <List.Item term={t("applications_page:tripla_import:property_mapping_id")} description={triplaProperty.mappingId} />
          <List.Item term={t("applications_page:tripla_import:property_latitude")} description={triplaProperty.latitude} />
          <List.Item term={t("applications_page:tripla_import:property_longitude")} description={triplaProperty.longitude} />
          <List.Item term={t("applications_page:tripla_import:property_phone")} description={triplaProperty.phone} />
          <List.Item term={t("applications_page:tripla_import:property_timezone")} description={triplaProperty.timezone} />
        </List>
      </div>
      <div>
        {triplaProperty.roomAndRates.map((room) => {
          return (
            <div key={room.id}>
              <div>{room.title}</div>
              <ul>
                {room.rates.map((rate, i) => {
                  return (
                    <li key={i}>
                      {rate.title} -{" "}
                      {t("applications_page:tripla_import:rate_max_persons")}: {rate.maxPersons},{" "}
                      {t("applications_page:tripla_import:rate_sell_mode")}: {t(`applications_page:tripla_import:rate_sell_mode_${rate.sellMode}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ConfirmationStep({ triplaProperty }, ref) {
  useImperativeHandle(ref, () => ({
    proceed: async () => {},
  }));

  return (
    <TriplaPropertyInfo triplaProperty={triplaProperty} />
  );
}

export default React.forwardRef(ConfirmationStep);
