import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table } from "antd";
import useDrawer from "hooks/use_drawer";
import store from "store";

import Loading from "components/loading/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import ApplicationUsersDrawer from "./application_users_drawer";

import sharedStyles from "components/property_form/components/policy_view/policy_view.module.css";

const { AdminApplications } = store;

const DROPDOWN_TRIGGER = ["click"];

export default function ApplicationAccessSubPage() {
  const { t } = useTranslation();

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const { isOpen: isDrawerOpen, open: openApplicationUsersDrawer, close: closeApplicationUsersDrawer, payload: managePayload } = useDrawer();

  const loadData = useCallback(() => {
    setIsLoading(true);

    return AdminApplications.list()
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [setData, setIsLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // is initial loading
  if (!data.length && isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className={sharedStyles.tableContainer}>
        {data.length ? (
          <Table
            loading={isLoading}
            pagination={false}
            showHeader
            dataSource={data}
            columns={[{
              title: "Title",
              dataIndex: "title",
              key: "title",
            }, {
              key: "action",
              align: "right",
              render: (value, record) => {
                const items = [{
                  key: "edit",
                  onClick: () => { openApplicationUsersDrawer(record); },
                  label: (
                    <div>
                      Manage Access
                    </div>
                  ),
                }];

                return (
                  <Dropdown menu={{ items }} trigger={DROPDOWN_TRIGGER}>
                    <Button type="link">
                      {t("general:actions")} <DownOutlined />
                    </Button>
                  </Dropdown>
                );
              },
            }]}
            rowKey="id"
          />
        ) : (
          <NoDataPlaceholder />
        )}
      </div>

      <ApplicationUsersDrawer
        visible={isDrawerOpen}
        value={managePayload}
        onClose={() => {
          closeApplicationUsersDrawer();
        }}
      />
    </div>
  );
}
