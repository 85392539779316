import React from "react";
import PropTypes from "prop-types";
import currencies from "world-currencies";

import FormSelect from "components/forms/inputs/form_select";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function optionsGenerator() {
  return Object.values(currencies).map((el) => {
    return {
      value: el.iso.code,
      representation: `${el.name} (${el.iso.code})`,
    };
  });
}

function InputCurrency(props) {
  const { t, model, errors, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "currency");
  const options = optionsGenerator();
  const isDisabled = Boolean(model.rate_category_id) || Boolean(model.id) || disabled;

  return (
    <FormSelect
      name="currency"
      view="horizontal"
      placeholder={t("rates_page:form:currency_placeholder")}
      label={t("rates_page:form:currency_label")}
      onChange={onHandleChange}
      defaultValue={model.currency || undefined}
      value={model.currency || undefined}
      errors={errors ? errors.currency : null}
      options={options}
      disabled={isDisabled}
    />
  );
}

InputCurrency.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputCurrency;
