import React from "react";
import PropTypes from "prop-types";
import { Button, Form } from "antd";

import { tailFormItemLayout } from "config/constants/layouts/form";

const FormItem = Form.Item;

function SubmitButton({ loading, icon, children, disabled, onClick = () => {} }) {
  return (
    <FormItem {...tailFormItemLayout}>
      <Button
        block
        icon={icon}
        type="primary"
        loading={loading}
        size="large"
        htmlType="submit"
        disabled={disabled}
        onClick={onClick}
        data-cy="submit"
      >
        {children}
      </Button>
    </FormItem>
  );
}

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool,
};

export default SubmitButton;
