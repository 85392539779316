export default [
  {
    key: "en",
    value: "English",
  },
  {
    key: "es",
    value: "Spanish",
  },
  {
    key: "pt",
    value: "Portugal",
  },
];
