export default {
  1: [
    "title",
    "group_id",
    "currency",
    "property_type",
    "email",
    "phone",
    "country",
    "timezone",
    "zip_code",
    "state",
    "city",
    "address",
    "latitude",
    "longitude",
  ],
  2: ["users"],
  3: [
    "logo_url",
    "description",
    "facilities",
    "important_information",
    "photos",
  ],
  4: ["min_price", "max_price", "allow_availability_autoupdate", "state_length"],
  5: ["hotel_policy", "default_cancellation_policy"],
  6: ["default_tax_set_id"],
};
