import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import AppStorageManager from "utils/app_storage_manager";

const Option = Select.Option;

export default function LocaleSelect() {
  const { t, i18n } = useTranslation();

  const handleLocaleChange = useCallback(
    (localeKey) => {
      i18n.changeLanguage(localeKey);
      AppStorageManager.setItem("CHANNEX_LANGUAGE", localeKey);
    },
    [i18n],
  );

  return (
    <Select
      label={t("general:locale")}
      value={i18n.language}
      onChange={handleLocaleChange}
      bordered={false}
    >
      {SUPPORTED_LANGUAGES.map((lang) => (
        <Option key={lang.key} value={lang.key}>
          {t(`general:links:languages:${lang.key}`)}
        </Option>
      ))}
    </Select>
  );
}
