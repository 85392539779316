import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Empty } from "antd";
import classNames from "classnames";
import store from "store";
import { sortBookingStatsByDesc } from "store/storage/selectors/booking_stats_selector";
import { getActiveGroup, getActiveProperty } from "store/storage/selectors/session_selector";

import ErrorMessage from "components/error_boundary/error_message";

import dayjs from "utils/dayjs";
import useBoolState from "utils/use_bool_state";

import WidgetLoader from "../common/widget_loader";

import SourcesChart from "./sources_chart";
import Title from "./title";

import stylesWidget from "./sources_chart/sources_chart.module.css";
import styles from "styles/dashboard.module.css";

const { Bookings } = store;

function BookingSourcesWidget() {
  const bookingStats = useSelector(sortBookingStatsByDesc);
  const activeProperty = useSelector(getActiveProperty);
  const activeGroup = useSelector(getActiveGroup);
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(false);
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, "months"));
  const [dateTo, setDateTo] = useState(dayjs());
  const [isError, setTrueError, setFalseError] = useBoolState(false);

  const onSelectDate = useCallback((date) => {
    if (date?.length > 0) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    }
  }, []);

  const loadBookingSources = useCallback(() => {
    setTrueLoading();
    let meta = {};

    if (activeProperty) {
      meta = { property_id: activeProperty, ...meta };
    }

    if (activeGroup) {
      meta = { group_id: activeGroup, ...meta };
    }

    if (dateFrom) {
      meta = { from: dateFrom.utc().startOf("day").format(), ...meta };
    }

    if (dateTo) {
      meta = { to: dateTo.utc().endOf("day").format(), ...meta };
    }

    Bookings.stats(meta)
      .then(() => {
        setFalseLoading();
        setFalseError();
      })
      .catch(() => {
        setFalseLoading();
        setTrueError();
      });
  }, [
    setFalseLoading,
    setTrueLoading,
    dateTo,
    dateFrom,
    activeProperty,
    activeGroup,
    setFalseError,
    setTrueError,
  ]);

  useEffect(loadBookingSources, [loadBookingSources]);

  const handleReload = useCallback(() => {
    return loadBookingSources();
  }, [loadBookingSources]);

  const renderContent = useMemo(() => {
    if (loading && !bookingStats) {
      return (
        <div className={stylesWidget.center}>
          <WidgetLoader />
        </div>
      );
    }

    if (bookingStats?.length > 0) {
      return <SourcesChart data={bookingStats} />;
    }

    if (isError) {
      return (
        <div className={stylesWidget.center}>
          <ErrorMessage onReload={handleReload} />
        </div>
      );
    }

    return (
      <div className={stylesWidget.center}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }, [loading, bookingStats, isError, handleReload]);

  const cardClassName = classNames(
    styles.dashboard__widget,
    styles.dashboard__widget__emptyPadding,
    styles.dashboard__widget__withBorder,
    stylesWidget.cardWrapper,
  );

  return (
    <Card
      title={<Title dateFrom={dateFrom} dateTo={dateTo} handleSelectDate={onSelectDate} />}
      className={cardClassName}
    >
      {renderContent}
    </Card>
  );
}

export default BookingSourcesWidget;
