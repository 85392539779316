import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import store from "store";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import List from "components/list";
import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";
import TimingsChart from "components/timings_chart/timings_chart";

import { formatToLocal } from "utils/dates";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

const { Tasks } = store;

export default function TaskViewDetails({ taskId }) {
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const [task, setTask] = useState(null);
  const { t } = useTranslation();

  useEffect(
    function loadTask() {
      setLoading();

      Tasks.find(taskId)
        .then(setTask)
        .catch(showErrorMessageFromResponse)
        .finally(setLoadingComplete);
    },
    [taskId, setLoading, setLoadingComplete],
  );

  if (loading) {
    return <Loading />;
  }

  if (!task) {
    return <NoDataPlaceholder />;
  }

  const hasErrors = Boolean(task.errors?.length);
  const successStatus = task.success ? "TRUE" : "FALSE";
  const received = formatToLocal(task.received_at, t("formats:iso_date_with_miliseconds"));
  const executed = formatToLocal(task.executed_at, t("formats:iso_date_with_miliseconds"));
  const finished = formatToLocal(task.finished_at, t("formats:iso_date_with_miliseconds"));
  const waitingTime = `${task.waiting_time}ms`;
  const executionTime = `${task.execution_time}ms`;
  const timings = Boolean(task.timings) && <TimingsChart timings={task.timings} />;
  const description = (
    <>
      {task.id}
      {task.id && <CopyToClipboard text={task.id} />}
    </>
  );

  return (
    <>
      <List>
        <List.Item term={t("tasks_page:form:task_id")} description={description} />
        <List.Item term={t("tasks_page:form:task")} description={task.task} />
        <List.Item term={t("tasks_page:form:user")} description={task.user_name} />
        <List.Item term={t("tasks_page:form:success")} description={successStatus} />
        <List.Item term={t("tasks_page:form:received_at")} description={received} />
        <List.Item term={t("tasks_page:form:executed_at")} description={executed} />
        <List.Item term={t("tasks_page:form:finished_at")} description={finished} />
        <List.Item term={t("tasks_page:form:waiting_time")} description={waitingTime} />
        <List.Item term={t("tasks_page:form:execution_time")} description={executionTime} />
        <List.Item term={t("tasks_page:form:timings")} description={timings} />
        {hasErrors && <List.Item term={t("tasks_page:form:errors")} description={task.errors} />}
        <List.Item term={t("tasks_page:form:payload")} />
      </List>
      <pre>{JSON.stringify(task.payload, null, 2)}</pre>
    </>
  );
}
