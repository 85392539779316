import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import store from "store";
import { convertToCamelCase } from "store/utils/case_converter";

import ChannexModal from "components/channex_modal";

import EventEmmiter from "utils/event_emmiter";
import showErrorMessage from "utils/show_error_message";

import OnboardingConfirmStep from "./onboarding_confirm_step";
import OnboardingCreateRatePlansStep from "./onboarding_create_rate_plans_step";
import OnboardingFinalStep from "./onboarding_final_step";
import OnboardingFooter from "./onboarding_footer";
import ONBOARDING_STATUSES from "./onboarding_statuses";
import OnboardingSteps from "./onboarding_steps";
import OnboardingUpdateInventoryStep from "./onboarding_update_inventory_step";
import OnboardingUrlStep from "./onboarding_url_step";

import styles from "./onboarding.module.css";

const { ws } = store;

const { Onboarding: OnboardingActions } = store;

export default function Onboarding() {
  const [step, setStep] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [onboardingData, setOnboardingData] = useState(null);

  const formRef = useRef();
  const { t } = useTranslation();

  const handleResetState = useCallback(() => {
    setOnboardingData(null);
    setLoading(false);
  }, [setOnboardingData]);

  const handleRequestError = useCallback(
    (response) => {
      setLoading(false);
      setOnboardingStatus(null);

      if (response && response.errors && response.errors.code) {
        showErrorMessage(t(`general:${response.errors.code}`));
      } else {
        showErrorMessage(t("general:undefined_error"));
      }
    },
    [t],
  );

  const handleFinishScraping = useCallback(
    (payload, id) => {
      if (payload[0] === ONBOARDING_STATUSES.DONE) {
        const onboardingResult = {
          id,
          attributes: {
            status: payload[0],
            payload: payload[1],
          },
        };
        setStep(1);
        setOnboardingStatus(null);
        setOnboardingData(onboardingResult);
        setLoading(false);
      }
    },
    [setOnboardingData],
  );

  const handleSubmitUrl = useCallback(
    (value) => {
      setLoading(true);
      setOnboardingStatus("loading");
      return OnboardingActions.init(value)
        .then((newOnboardingData) => {
          setOnboardingData(newOnboardingData);
        })
        .catch(handleRequestError);
    },
    [setLoading, handleRequestError],
  );

  const handleOnboardingRetry = useCallback(() => {
    setOnboardingData(null);
  }, [setOnboardingData]);

  const onOpenModal = useCallback(() => {
    setStep(0);
  }, []);

  const onCloseModal = useCallback(() => {
    if (step === 0) {
      setStep(null);
    }
  }, [step]);

  useEffect(() => {
    EventEmmiter.bind("onboarding:form_modal_open", onOpenModal);

    return () => EventEmmiter.unbind("onboarding:form_modal_open", onOpenModal);
  }, [onOpenModal]);

  useEffect(() => {
    const isOnboardingPending = onboardingData?.attributes?.status === ONBOARDING_STATUSES.PENDING;

    if (!isOnboardingPending) {
      return;
    }

    const bookingDotCompProcessIdChannel = ws.socket.channel(`onboarding:${onboardingData.id}`);
    bookingDotCompProcessIdChannel.join();
    bookingDotCompProcessIdChannel.on("onboarding:scraping", (payload) => {
      handleFinishScraping(convertToCamelCase(payload), onboardingData.id);
    });

    bookingDotCompProcessIdChannel.on("onboarding:confirmation", (payload) => {
      setOnboardingStatus(payload);

      if (payload === "finish") {
        setOnboardingStatus(null);
        setStep(2);
        setLoading(false);
      }
    });

    if (onboardingData.attributes.propertyId) {
      bookingDotCompProcessIdChannel.leave();
    }
  }, [onboardingData, handleRequestError, handleFinishScraping]);

  useEffect(() => {
    if (!onboardingData?.attributes?.propertyId && !onboardingData?.attributes?.channelId) {
      return;
    }

    const bookingDotCompPropertyIdChannel = ws.socket.channel(
      `onboarding:${onboardingData.attributes.propertyId}`,
    );
    const bookingDotCompChannelIdChannel = ws.socket.channel(
      `onboarding:${onboardingData.attributes.channelId}`,
    );

    bookingDotCompPropertyIdChannel.join();
    bookingDotCompChannelIdChannel.join();

    bookingDotCompPropertyIdChannel.on("onboarding:rate_plans", (payload) => {
      setOnboardingStatus(payload);
      if (payload === "finish") {
        setOnboardingStatus(null);
        setLoading(false);
        setStep(3);
        bookingDotCompPropertyIdChannel.leave();
      }
    });

    bookingDotCompChannelIdChannel.on("onboarding:update_inventory", (payload) => {
      setOnboardingStatus(payload);

      if (payload === "channel_activated") {
        setOnboardingStatus(null);
        setStep(4);
        setLoading(false);
        bookingDotCompChannelIdChannel.leave();
      }
    });
  }, [onboardingData]);

  const handleOnboardingConfirm = useCallback(() => {
    setLoading(true);
    setOnboardingStatus("loading");

    return OnboardingActions.confirm(onboardingData.id)
      .then((data) => {
        setOnboardingData(data);
        setLoading(false);
      })
      .catch(handleRequestError);
  }, [setLoading, handleRequestError, onboardingData]);

  const handleCreateRatePlans = useCallback(() => {
    setLoading(true);
    setOnboardingStatus("loading");

    const args = {
      channelId: onboardingData.attributes.channelId,
      propertyId: onboardingData.attributes.propertyId,
    };

    return OnboardingActions.createRatePlans(args).catch(handleRequestError);
  }, [setLoading, handleRequestError, onboardingData]);

  const handleUpdateInventory = useCallback(() => {
    setLoading(true);
    setOnboardingStatus("loading");

    const args = {
      channelId: onboardingData.attributes.channelId,
    };

    return OnboardingActions.updateInventory(args).catch(handleRequestError);
  }, [setLoading, handleRequestError, onboardingData]);

  const handleOnboardingDecline = useCallback(() => {
    if (!onboardingData) {
      return Promise.resolve();
    }
    setLoading(true);
    return OnboardingActions.decline(onboardingData.id)
      .then(handleResetState)
      .then(() => setStep(0))
      .catch(handleRequestError);
  }, [onboardingData, handleResetState, handleRequestError]);

  const handleSubmit = useCallback(() => {
    switch (step) {
      case 0:
        formRef?.current?.submitForm();
        break;
      case 2:
        handleCreateRatePlans();
        break;
      case 3:
        handleUpdateInventory();
        break;
      case 4:
        setStep(null);
        break;
      default:
        break;
    }
    return null;
  }, [step, formRef, handleCreateRatePlans, handleUpdateInventory, setStep]);

  const activeTabKey = step?.toString();

  const items = [{
    key: 0,
    children: (
      <OnboardingUrlStep
        loading={isLoading}
        onSubmit={handleSubmitUrl}
        formRef={formRef}
        onboardingData={onboardingData}
        onOnboardingRetry={handleOnboardingRetry}
      />
    ),
  }, {
    key: 1,
    children: (
      <OnboardingConfirmStep onboardingData={onboardingData} />
    ),
  }, {
    key: 2,
    children: (
      <OnboardingCreateRatePlansStep />
    ),
  }, {
    key: 3,
    children: (
      <OnboardingUpdateInventoryStep />
    ),
  }, {
    key: 4,
    children: (
      <OnboardingFinalStep />
    ),
  }];

  return (
    <ChannexModal
      title={t("properties_page:booking_com_onboarding:modal_title")}
      visible={step !== null}
      className={styles.modalWrapper}
      onCancel={onCloseModal}
      footer={
        <OnboardingFooter
          step={step}
          onClose={onCloseModal}
          message={onboardingStatus}
          handleSubmitClick={handleSubmit}
          onDecline={handleOnboardingDecline}
          onConfirm={handleOnboardingConfirm}
          loading={isLoading}
        />
        }
      destroyOnClose={false}
    >
      <div className={styles.modalInner}>
        <Tabs items={items} activeKey={activeTabKey} />
      </div>

      <OnboardingSteps step={step} />
    </ChannexModal>
  );
}
