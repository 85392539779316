import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, notification } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormRate from "components/forms/inputs/form_rate";
import FormTextarea from "components/forms/inputs/form_textarea";

import Text from "../../../../_common/text";

import styles from "./guest_review_form.module.css";

const { Reviews } = store;

export default function GuestReviewForm({ review }) {
  const { t } = useTranslation();

  const [guestReview, setGuestReview] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(
    function resetGuestReview() {
      setGuestReview({});
      setLoading(false);
    },
    [review],
  );

  const submitGuestReview = () => {
    setLoading(true);

    Reviews.guestReview(review.id, guestReview).then(
      (_repliedReview) => {
        setLoading(false);
        Reviews.review(review.id);
      },
      (error) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: error.errors?.title || "N/A",
        });
      },
    );
  };

  const handleChange = (field, fieldValue) => {
    setGuestReview({ ...guestReview, [field]: fieldValue });
  };

  const handleCheckBoxChange = (field) => (fieldValue) => {
    handleChange(field, fieldValue);
  };

  const canSubmitGuestReview = () => {
    return !(
      guestReview.publicReview
      && guestReview.respectHouseRules
      && guestReview.cleanliness
      && guestReview.communication
    );
  };

  return (
    <Form className={styles.narrowForm} onFinish={submitGuestReview}>
      <FormTextarea
        autosize={{ minRows: 3, maxRows: 5 }}
        label={<Text>{t("reviews_page:feedback:private_review")}</Text>}
        placeholder={t("reviews_page:feedback:private_review")}
        name="privateReview"
        value={guestReview.privateReview}
        onChange={handleChange}
      />
      <FormTextarea
        autosize={{ minRows: 3, maxRows: 5 }}
        label={<Text>{t("reviews_page:feedback:public_review")}</Text>}
        placeholder={t("reviews_page:feedback:public_review")}
        name="publicReview"
        value={guestReview.publicReview}
        onChange={handleChange}
      />
      <FormCheckbox
        label={<Text>{t("reviews_page:feedback:recommend")}</Text>}
        value={guestReview.isRevieweeRecommended}
        onChange={handleCheckBoxChange("isRevieweeRecommended")}
      />
      <FormRate
        label={<Text>{t("reviews_page:feedback:respect_house_rules")}</Text>}
        name="respectHouseRules"
        value={guestReview.respectHouseRules}
        onChange={handleChange}
      />
      <FormRate
        label={<Text>{t("reviews_page:feedback:communication")}</Text>}
        name="communication"
        value={guestReview.communication}
        onChange={handleChange}
      />
      <FormRate
        label={<Text>{t("reviews_page:feedback:cleanliness")}</Text>}
        name="cleanliness"
        value={guestReview.cleanliness}
        onChange={handleChange}
      />

      <SubmitButton disabled={canSubmitGuestReview()} loading={loading}>
        {t("reviews_page:feedback:send")}
      </SubmitButton>
    </Form>
  );
}
