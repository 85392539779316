import React from "react";
import { useTranslation } from "react-i18next";

import LinkButton from "components/forms/buttons/link_button";
import FORM_FIELDS_BY_TAB from "components/property_form/property_form_fields_by_tab";

export default function MissingContentPropertyField({ entry, onClick }) {
  const { t } = useTranslation();
  const tabKey = Object.keys(FORM_FIELDS_BY_TAB).find((tab) => FORM_FIELDS_BY_TAB[tab].includes(entry.field));

  const handleClick = () => onClick(tabKey, entry.field);

  return (
    <LinkButton onClick={handleClick}>
      {t(`channels_page:form:missing_content:${entry.code}`, { field: t(`channels_page:form:missing_content:${entry.field}`) })}
    </LinkButton>
  );
}
