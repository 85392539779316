import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import FormInput from "components/forms/inputs/form_input";
import FormSelect from "components/forms/inputs/form_select";

import styles from "./mapping_form.module.css";

class MappingForm extends Component {
  onBlur(rateId, el) {
    return (event) => {
      this.props.onChangeMappingValue(this.props.tempMappings[rateId])(rateId)(el)(
        event.target.value,
      );
    };
  }

  render() {
    const { t, form, mappingSchema, mappingOptions, rateId, onChangeMappingValue } = this.props;
    let { tempMappings } = this.props;

    let mappings = form.values.mappings;

    if (!mappings[rateId]) {
      const rateMapping = Object.keys(mappingSchema).reduce((acc, el) => {
        acc[el] = undefined;
        return acc;
      }, {});
      mappings = { ...mappings, [rateId]: rateMapping };
    }

    if (!tempMappings[rateId]) {
      tempMappings = { ...tempMappings, [rateId]: mappings[rateId] };
    }

    const fields = Object.keys(mappingSchema)
      .sort((a, b) => mappingSchema[a].position - mappingSchema[b].position)
      .reduce((acc, el) => {
        let options = [];

        if (mappingOptions[`${el}_dictionary`] && mappingOptions[`${el}_dictionary`].values) {
          options = mappingOptions[`${el}_dictionary`].values
            .filter((v) => !v.isDeleted)
            .map((v) => ({ value: v.id, representation: v.title }));
        } else {
          options = [
            {
              value: "true",
              representation: "true",
            },
            {
              value: "false",
              representation: "false",
            },
          ];
        }

        let value = tempMappings[rateId][el];

        if (mappingOptions[`${el}_dictionary`]) {
          const valueOption = mappingOptions[`${el}_dictionary`].values.find((v) => v.id === value);

          if (valueOption && valueOption.isDeleted) {
            value = undefined;
          }

          acc.push(
            <FormSelect
              name={el}
              key={`${el}_${value}`}
              className={styles.mappingFormControl}
              view="horizontal"
              value={value}
              label={t(`channels_page:form:${el}_label`)}
              placeholder={t(`channels_page:form:${el}_placeholder`)}
              options={options}
              onChange={onChangeMappingValue(tempMappings[rateId])(rateId)(el)}
            />,
          );
        } else {
          acc.push(
            <FormInput
              name={el}
              key={`${el}_${value}`}
              className={styles.mappingFormControl}
              view="horizontal"
              value={value}
              label={t(`channels_page:form:${el}_label`)}
              placeholder={t(`channels_page:form:${el}_placeholder`)}
              onBlur={this.onBlur(rateId, el)}
            />,
          );
        }

        return acc;
      }, []);

    return <Form>{fields}</Form>;
  }
}

export default withTranslation()(MappingForm);
