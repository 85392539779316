const isUnmapped = (revision) => {
  const { rooms } = revision;

  return rooms.reduce((acc, { rate_plan_id }) => {
    return acc || !rate_plan_id;
  }, false);
};

export default (revision) => {
  const errors = [];

  if (isUnmapped(revision)) {
    errors.push("unmapped_room");
  }

  return errors;
};
