import React, { Component } from "react";
import { Form } from "antd";

import PhotoCard from "components/content_module/photo_card";
import PhotoUploader from "components/content_module/photo_uploader";

import styles from "./form_image_uploader.module.css";

class FormImageUploader extends Component {
  getLayout = () => ({
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  });

  handleDelete = () => {
    const { name, onChange } = this.props;

    onChange(null, name);
  };

  handleSelect = ({ cdnUrl }) => {
    const { name, onChange } = this.props;

    onChange(cdnUrl, name);
  };

  renderUploader = () => (
    <PhotoUploader
      button={<PhotoCard.UploadButton className={styles.loader} />}
      onFileSelect={this.handleSelect}
    />
  );

  renderPhoto = () => {
    const { value } = this.props;
    const photo = { url: value };

    return <PhotoCard onDelete={this.handleDelete} photo={photo} />;
  };

  validation = (errors) => {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  };

  render() {
    const { label, extra, value, errors, className } = this.props;

    const itemProps = {
      label,
      extra,
      className: [styles.wrapper, className].join(" "),
    };

    const formItemLayout = this.getLayout();
    const formItemErrors = this.validation(errors);
    const content = !value ? this.renderUploader() : this.renderPhoto();

    return (
      <Form.Item {...itemProps} {...formItemLayout} {...formItemErrors}>
        {content}
      </Form.Item>
    );
  }
}

export default FormImageUploader;
