import React from "react";

import RateTitle from "components/rates/title";

export default function TitleWithI({ className, rate = {} }) {
  const rateId = rate.id ? ` (${rate.id})` : "";
  const rateTitleWithId = `${rate.title}${rateId}`;
  const updatedRate = { ...rate, title: rateTitleWithId };

  return <RateTitle className={className} rate={updatedRate} />;
}
