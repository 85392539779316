import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { LockOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";

import SubmitButton from "components/forms/buttons/submit_button";
import FormInput from "components/forms/inputs/formik/form_input";

function AccountDetails({ isSubmitting, errors, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        password: "",
        password_confirmation: "",
      }}
    >
      <Form>
        <FormInput
          className="form-input__password"
          view="vertical"
          type="password"
          name="password"
          placeholder={t("public_pages:password_field_placeholder")}
          icon={<LockOutlined />}
          errors={errors.password}
        />
        <FormInput
          className="form-input__password_confirmation"
          view="vertical"
          type="password"
          name="password_confirmation"
          placeholder={t("public_pages:password_confirmation_field_placeholder")}
          icon={<LockOutlined />}
          errors={errors.password_confirmation}
        />

        <SubmitButton loading={isSubmitting}>{t("confirm_invite_page:submit_button")}</SubmitButton>
      </Form>
    </Formik>
  );
}

AccountDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default AccountDetails;
