import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DisconnectOutlined, DownOutlined, EditOutlined, LinkOutlined, ReadOutlined } from "@ant-design/icons";
import { Dropdown, Modal } from "antd";
import store from "store";

import DeletePropertyButton from "components/properties/delete_property_button";
import DeletePropertyModal from "components/properties/delete_property_button/delete_property_modal";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import handleActionError from "utils/handle_action_error";

import generalStyles from "../../../../styles/general.module.css";

const { Groups, Properties } = store;
const confirm = Modal.confirm;

export default function PropertyAction({ group, property }) {
  const { t } = useTranslation();
  const [isModalOpen, setModalState] = useState(false);
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();

  const { id, groups } = property;

  const toggleModal = () => setModalState(!isModalOpen);

  const onRemoveFromGroup = () => {
    confirm({
      title: t("general:remove_dialog:title"),
      content: t("general:remove_dialog:description"),
      onOk() {
        Groups.removeProperty(group.id, property.id);
      },
    });
  };

  const handlePropertyDelete = () => {
    toggleModal();
  };

  const deleteProperty = () => {
    return Properties.delete(id).catch(handleActionError);
  };

  const items = [{
    key: "crud_entry_edit_action",
    onClick: () => navigate(pathBuilder(userAppRoutes.groups.editProperty, { propertyId: id })),
    label: (
      <div data-cy="crud_entry_edit_action">
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  }, {
    key: "crud_entry_logs_action",
    onClick: () => navigate(pathBuilder(userAppRoutes.tasks, { propertyId: id })),
    label: (
      <div data-cy="crud_entry_logs_action">
        <ReadOutlined /> {t("general:action:logs")}
      </div>
    ),
  }, {
    type: "divider",
  }, {
    key: "crud_entry_assign_group_action",
    onClick: () => navigate(pathBuilder(userAppRoutes.groups.assignGroup, { propertyId: id })),
    label: (
      <div data-cy="crud_entry_assign_group_action">
        <LinkOutlined /> {t("general:action:assign_group_to_property")}
      </div>
    ),
  }, {
    key: "crud_entry_remove_from_group_action",
    onClick: onRemoveFromGroup,
    label: (
      <div data-cy="crud_entry_remove_from_group_action">
        <DisconnectOutlined /> {t("general:action:remove_from_group")}
      </div>
    ),
    disabled: Object.keys(groups || {}).length <= 1,
  }, {
    key: "crud_entry_delete_property",
    onClick: handlePropertyDelete,
    label: (
      <div>
        <DeletePropertyButton showIcon />
      </div>
    ),
  }];

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <a
          data-cy="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(e) => e.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>

      <DeletePropertyModal
        property={property}
        visible={isModalOpen}
        onConfirm={deleteProperty}
        onClose={toggleModal}
      />
    </>
  );
}
