import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, notification, Typography } from "antd";
import store from "store";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import EventEmmiter from "utils/event_emmiter";

import generalStyles from "styles/general.module.css";

const { Paragraph } = Typography;
const { confirm } = Modal;
const { RatePlans } = store;

export default function RatesSubtableMenu({ record }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();

  const onForceRemove = useCallback(() => {
    notification.destroy();

    return RatePlans.forceRemove(record).then(
      () => {
        EventEmmiter.trigger("rate_plan:removed", record);
      },
      (error) => {
        const reason = error.errors.details.channel ? "channel" : "undefined";

        notification.error({
          message: t("rooms_page:remove_rate_error_message"),
          description: (
            <div>{t(`rooms_page:remove_rate_error_description:${reason}`)}</div>
          ),
          duration: 0,
        });
      },
    );
  }, [record, t]);

  const onRateRemoveConfirm = useCallback(() => {
    return RatePlans.remove(record).then(
      () => {
        EventEmmiter.trigger("rate_plan:removed", record);
      },
      (error) => {
        const reason = error.errors.details.channel ? "channel" : "undefined";
        const relationships = error.errors.relationships?.channels || [];
        const links = relationships.map((relationship) => (
          <li key={relationship.id}>
            <a href={`/channels/${relationship.id}/edit`}>
              {relationship.channel} - {relationship.title}
            </a>
          </li>
        ));

        notification.error({
          message: t("rooms_page:remove_rate_error_message"),
          description: (
            <>
              <div>{t(`rooms_page:remove_rate_error_description:${reason}`)}</div>
              {links ? (
                <Paragraph>
                  <strong>{t("inventory_page:connected_channels")}:</strong>
                  <ul>{links}</ul>
                  <Button onClick={onForceRemove} type="link" danger>
                    {t("rooms_page:remove_rate_unmap")}
                  </Button>
                </Paragraph>
              ) : null}
            </>
          ),
          duration: 0,
        });
      },
    );
  }, [record, t, onForceRemove]);

  const onRemove = useCallback(
    () => {
      confirm({
        title: t("general:remove_dialog:title"),
        content: t("general:remove_dialog:description"),
        onOk: onRateRemoveConfirm,
      });
    },
    [onRateRemoveConfirm, t],
  );

  const items = [{
    key: "actions_menu_edit",
    onClick: () => navigate(pathBuilder(userAppRoutes.rooms.ratesEditAtRooms, { rateId: record.id })),
    label: (
      <div data-cy="actions_menu_edit">
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  }, {
    type: "divider",
  }, {
    key: "actions_menu_remove",
    onClick: onRemove,
    label: (
      <div data-cy="actions_menu_remove">
        <DeleteOutlined /> {t("general:action:remove")}
      </div>
    ),
  }];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <a
        data-cy="crud_entry_actions_menu"
        className={generalStyles.actionsToggle}
        onClick={(e) => e.preventDefault()}
      >
        {t("general:actions")} <DownOutlined />
      </a>
    </Dropdown>
  );
}
