import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown, Modal } from "antd";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import generalStyles from "styles/general.module.css";

const { confirm } = Modal;

export default function GroupsEntryMenu({ record, onDelete }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();

  const handleDelete = useCallback(() => {
    confirm({
      title: t("general:remove_dialog:title"),
      content: <div data-cy="modal_content">{t("general:remove_dialog:description")}</div>,
      okButtonProps: {
        "data-cy": "modal_ok",
      },
      onOk: () => onDelete(record.id),
    });
  }, [t, record, onDelete]);

  const items = [{
    key: "group_crud_entry_edit_action",
    onClick: () => navigate(pathBuilder(userAppRoutes.groups.edit, { groupId: record.id })),
    label: (
      <div data-cy="group_crud_entry_edit_action">
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  }, {
    type: "divider",
  }, {
    key: "group_crud_entry_remove_action",
    onClick: handleDelete,
    label: (
      <div data-cy="group_crud_entry_remove_action">
        <DeleteOutlined /> {t("general:action:remove")}
      </div>
    ),
    disabled: record.properties.length > 0,
  }];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <a
        data-cy="crud_entry_actions_menu"
        className={generalStyles.actionsToggle}
        onClick={(e) => e.preventDefault()}
      >
        {t("general:actions")} <DownOutlined />
      </a>
    </Dropdown>
  );
}
