import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Collapse, Empty, notification, Row, Tabs } from "antd";
import store from "store";

import appCodes from "config/constants/app_codes";

import IssueDrawerControlled from "drawers/issue_drawer_controlled";
import RevisionMessageDrawer from "drawers/revision_message_drawer";

import BookingRevision from "components/booking_revision";
import BookingRevisionTags from "components/booking_revision/booking_revision_tags";
import Loading from "components/loading";

import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";
import getEnabledApplications from "utils/get_enabled_applications";

import BookingIssues from "./booking_issues";
import Messages from "./messages";
import RevisionCard from "./revision_card";

import styles from "./booking_view.module.css";
import drawerStyles from "drawers/channex_drawer.module.css";

const { Bookings } = store;

class BookingView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    booking: null,
    rawMessageDrawerVisible: false,
    rawMessage: null,
    issueDrawer: {
      visible: false,
      issueId: null,
    },
  };

  t = (word) => {
    const { t } = this.props;
    return t(`bookings_page:booking_view_dialog:${word}`);
  };

  componentDidMount() {
    this.loadBooking();
  }

  loadBooking = () => {
    const { bookingId, onLoad } = this.props;

    Bookings.find(bookingId, { relationships: "all" }).then((booking) => {
      this.setState({
        loading: false,
        booking,
      });

      onLoad(booking);
    });
  };

  handleOpenRawMessageView = (bookingRevision) => (event) => {
    event.preventDefault();

    const rawMessage = bookingRevision.raw_message;

    this.setState({
      rawMessage,
      rawMessageDrawerVisible: true,
    });
  };

  handleCloseRawMessageView = () => {
    this.setState({
      rawMessageDrawerVisible: false,
    });
  };

  handleIssueDrawerClose = () => this.setState({ issueDrawer: { visible: false, issueId: null } });

  handleIssueDrawerOpen = (issueId) => this.setState({ issueDrawer: { visible: true, issueId } });

  handleIssueUpdate = () => this.loadBooking();

  handleResend = (booking) => (event) => {
    event.preventDefault();
    Bookings.resend(booking.id).then((_result) => {
      const { t } = this.props;

      notification.success({
        message: t("bookings_page:booking_view_dialog:resend:success_title"),
        description: t("bookings_page:booking_view_dialog:resend:success_description"),
      });
    });
  };

  renderBookingIssues = () => {
    const { booking } = this.state;
    const { channel_events } = booking || {};
    const issues = Object.values(channel_events || {});
    const unresolvedIssues = issues.filter((issue) => issue.status !== "resolved");

    if (!unresolvedIssues.length) {
      return null;
    }

    return <BookingIssues issues={unresolvedIssues} onIssueClick={this.handleIssueDrawerOpen} />;
  };

  renderRevisionHeader = (revision) => {
    const { t } = this.props;

    return (
      <Row>
        <Col span={12} style={{ fontWeight: 600 }}>
          {this.t("system_id")}: {revision.system_id}
          <br />
          <small>{formatToLocal(revision.inserted_at, t("formats:date_time_with_seconds"))}</small>
        </Col>
        <Col span={12} className={styles.revision_title_type}>
          <BookingRevisionTags revision={revision} />
        </Col>
      </Row>
    );
  };

  buildRevisionPanelItem = (revision) => {
    const { booking } = this.state;
    const { rate_plans, room_types, channel, properties } = booking;
    const updatedRevision = { ...revision, channel, properties };

    return ({
      key: revision.id,
      label: this.renderRevisionHeader(revision),
      children: (
        <>
          <BookingRevision revision={updatedRevision} ratePlans={rate_plans} roomTypes={room_types} />
          <BookingRevision.RawLink onClick={this.handleOpenRawMessageView(revision)} />
        </>
      ),
    });
  };

  renderRevisions = () => {
    const { booking } = this.state;
    const { booking_revisions } = booking;

    const revisions = Object.values(booking_revisions);

    if (revisions.length === 0) {
      return <Empty />;
    }

    const items = revisions.map((revision, index) => this.buildRevisionPanelItem(revision, index));

    return (
      <Collapse items={items} />
    );
  };

  renderSubscribers = () => {
    const { booking } = this.state;
    const { booking_revisions, booking_acknowledgements } = booking;

    const bookingAcknowledgements = Object.values(booking_acknowledgements);
    const bookingRevision = Object.values(booking_revisions);

    if (bookingRevision.length === 0) {
      return <Empty />;
    }

    const acksByRevisions = bookingRevision.reduce((acc, revision) => {
      return [
        ...acc,
        {
          ...revision,
          acks: bookingAcknowledgements.filter((el) => el.booking_revision_id === revision.id),
        },
      ];
    }, []);

    return (
      <>
        <BookingRevision.ResendLink onClick={this.handleResend(booking)} />
        {acksByRevisions.map((revision) => (
          <RevisionCard key={revision.id} revision={revision} />
        ))}
      </>
    );
  };

  render() {
    const { loading, booking, rawMessageDrawerVisible, rawMessage, issueDrawer } = this.state;
    const { rate_plans, room_types, properties = {} } = booking || {};
    const { installedApplications, hiddenTabs = [], hiddenFields = [], hideActions = false } = this.props;

    const isMessagesEnabled = Object.keys(properties).some((propertyId) => {
      const filters = {
        applicationCode: appCodes.messages,
        propertyId,
      };

      return getEnabledApplications(installedApplications, filters).length;
    });

    if (loading) {
      return <Loading />;
    }

    const items = [{
      label: this.t("tab_keys:info"),
      key: "1",
      className: drawerStyles.noActionsInDrawer,
      children: (
        <div>
          {this.renderBookingIssues()}
          {booking && (
            <BookingRevision revision={booking} ratePlans={rate_plans} roomTypes={room_types} hiddenFields={hiddenFields} />
          )}
        </div>
      ),
    }];

    if (!hiddenTabs.includes("revisions") && booking) {
      items.push({
        label: this.t("tab_keys:revisions"),
        key: "2",
        className: drawerStyles.noActionsInDrawer,
        children: this.renderRevisions(),
      });
    }

    if (!hiddenTabs.includes("subscribers") && booking) {
      items.push({
        label: this.t("tab_keys:subscribers"),
        key: "3",
        className: drawerStyles.noActionsInDrawer,
        children: this.renderSubscribers(),
      });
    }

    if (!hiddenTabs.includes("messages") && isMessagesEnabled) {
      items.push({
        label: this.t("tab_keys:messages"),
        key: "4",
        className: [drawerStyles.noActionsInDrawer, drawerStyles.noPaddings],
        children: <Messages booking={booking} />,
      });
    }

    return (
      <>
        <Tabs items={items} />
        {!hideActions && (
          <>
            <RevisionMessageDrawer
              visible={rawMessageDrawerVisible}
              rawMessage={rawMessage}
              onClose={this.handleCloseRawMessageView}
            />
            <IssueDrawerControlled
              visible={issueDrawer.visible}
              issueId={issueDrawer.issueId}
              onResolve={this.handleIssueUpdate}
              onIgnore={this.handleIssueUpdate}
              onClose={this.handleIssueDrawerClose}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ applications }) => ({
  installedApplications: Object.values(applications.installed),
});

export default withTranslation()(withRouter(connect(mapStateToProps)(BookingView)));
