import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { FieldArray } from "formik";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormCheckbox from "components/forms/inputs/formik/form_checkbox";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import InputGroup from "components/forms/inputs/input_group/input_group";
import NoDataPlaceholder from "components/no_data_placeholder";

import styles from "./room_space_form.module.css";
import formInDrawerStyles from "styles/form_in_drawer.module.css";

const emptyBedDescription = {
  type: null,
  size: null,
  count: null,
};

const DEFAULT_PURPOSE = "bedroom";
const DEFAULT_TYPE = "bedroom";
const DEFAULT_HAS_PRIVATE_BATHROOM = false;

class RoomSpaceForm extends Component {
  constructor(props) {
    super(props);
    this.bedConfigurationsHelpers = null;
  }

  VALIDATION_SCHEMA = yup.object().shape({
    title: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    width: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    length: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    unit: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    purpose: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    type: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    bedConfigurationOptions: yup.array().of(yup.object().shape({
      bedDescriptions: yup.array().of(yup.object().shape({
        type: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
        size: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
        count: yup.number(errorMessages.number()).required(errorMessages.required()).typeError(errorMessages.number()),
      })),
    })),
  });

  DEFAULT_VALUE = {
    purpose: DEFAULT_PURPOSE,
    type: DEFAULT_TYPE,
    hasPrivateBathroom: DEFAULT_HAS_PRIVATE_BATHROOM,
    bedConfigurationOptions: [],
  };

  getBedSizeOptions = () => {
    const { t } = this.props;

    return [
      { value: "xs", representation: t("rooms_page:spaces:bed_sizes:xs") },
      { value: "s", representation: t("rooms_page:spaces:bed_sizes:s") },
      { value: "m", representation: t("rooms_page:spaces:bed_sizes:m") },
      { value: "l", representation: t("rooms_page:spaces:bed_sizes:l") },
      { value: "xl", representation: t("rooms_page:spaces:bed_sizes:xl") },
      { value: "xxl", representation: t("rooms_page:spaces:bed_sizes:xxl") },
      { value: "variable", representation: t("rooms_page:spaces:bed_sizes:variable") },
    ];
  };

  getBedTypeOptions = () => {
    const { t } = this.props;

    return [
      { value: "bunk_bed", representation: t("rooms_page:spaces:bed_types:bunk_bed") },
      { value: "full_bed", representation: t("rooms_page:spaces:bed_types:full_bed") },
      { value: "futon", representation: t("rooms_page:spaces:bed_types:futon") },
      { value: "king_bed", representation: t("rooms_page:spaces:bed_types:king_bed") },
      { value: "murphy_bed", representation: t("rooms_page:spaces:bed_types:murphy_bed") },
      { value: "queen_bed", representation: t("rooms_page:spaces:bed_types:queen_bed") },
      { value: "trundle_bed", representation: t("rooms_page:spaces:bed_types:trundle_bed") },
      { value: "twin_bed", representation: t("rooms_page:spaces:bed_types:twin_bed") },
      { value: "twin_xl_bed", representation: t("rooms_page:spaces:bed_types:twin_xl_bed") },
      { value: "water_bed", representation: t("rooms_page:spaces:bed_types:water_bed") },
      { value: "sofa_bed", representation: t("rooms_page:spaces:bed_types:sofa_bed") },
      { value: "crib", representation: t("rooms_page:spaces:bed_types:crib") },
      { value: "day_bed", representation: t("rooms_page:spaces:bed_types:day_bed") },
      { value: "rollaway_bed", representation: t("rooms_page:spaces:bed_types:rollaway_bed") },
    ];
  };

  getSizeUnitOptions = () => {
    const { t } = this.props;

    return [
      { value: "cm", representation: t("rooms_page:spaces:size_unit_types:cm") },
      { value: "meter", representation: t("rooms_page:spaces:size_unit_types:meter") },
      { value: "inch", representation: t("rooms_page:spaces:size_unit_types:inch") },
      { value: "feet", representation: t("rooms_page:spaces:size_unit_types:feet") },
    ];
  };

  getRoomSpaceTypeOptions = (purpose) => {
    const { t } = this.props;

    const typesByPurpose = {
      bedroom: [
        { value: "bedroom", representation: t("rooms_page:spaces:types:bedroom") },
      ],
      bathroom: [
        { value: "full_bathroom", representation: t("rooms_page:spaces:types:full_bathroom") },
        { value: "half_bathroom", representation: t("rooms_page:spaces:types:half_bathroom") },
      ],
      living_space: [
        { value: "backyard", representation: t("rooms_page:spaces:types:backyard") },
        { value: "basement", representation: t("rooms_page:spaces:types:basement") },
        { value: "common_space", representation: t("rooms_page:spaces:types:common_space") },
        { value: "common_spaces", representation: t("rooms_page:spaces:types:common_spaces") },
        { value: "dining_room", representation: t("rooms_page:spaces:types:dining_room") },
        { value: "entrance_to_home", representation: t("rooms_page:spaces:types:entrance_to_home") },
        { value: "entry", representation: t("rooms_page:spaces:types:entry") },
        { value: "exterior", representation: t("rooms_page:spaces:types:exterior") },
        { value: "front_yard", representation: t("rooms_page:spaces:types:front_yard") },
        { value: "family_room", representation: t("rooms_page:spaces:types:family_room") },
        { value: "hot_tub", representation: t("rooms_page:spaces:types:hot_tub") },
        { value: "garage", representation: t("rooms_page:spaces:types:garage") },
        { value: "gym", representation: t("rooms_page:spaces:types:gym") },
        { value: "kitchen", representation: t("rooms_page:spaces:types:kitchen") },
        { value: "kitchenette", representation: t("rooms_page:spaces:types:kitchenette") },
        { value: "laundry_room", representation: t("rooms_page:spaces:types:laundry_room") },
        { value: "living_room", representation: t("rooms_page:spaces:types:living_room") },
        { value: "office", representation: t("rooms_page:spaces:types:office") },
        { value: "other", representation: t("rooms_page:spaces:types:other") },
        { value: "outdoor_common_area", representation: t("rooms_page:spaces:types:outdoor_common_area") },
        { value: "outdoor_space", representation: t("rooms_page:spaces:types:outdoor_space") },
        { value: "patio", representation: t("rooms_page:spaces:types:patio") },
        { value: "pool", representation: t("rooms_page:spaces:types:pool") },
        { value: "recreation_area", representation: t("rooms_page:spaces:types:recreation_area") },
        { value: "study", representation: t("rooms_page:spaces:types:study") },
        { value: "studio", representation: t("rooms_page:spaces:types:studio") },
      ],
    };

    return typesByPurpose[purpose];
  };

  getRoomSpacePurposeOptions() {
    const { t } = this.props;

    return [
      { value: "bedroom", representation: t("rooms_page:spaces:purposes:bedroom") },
      { value: "living_space", representation: t("rooms_page:spaces:purposes:living_space") },
      { value: "bathroom", representation: t("rooms_page:spaces:purposes:bathroom") },
    ];
  }

  canDeleteBedDescription = (values) => {
    return values.bedConfigurationOptions.length > 1 || values.bedConfigurationOptions[0].bedDescriptions.length > 1;
  };

  hasBedConfigurations = (values) => {
    return values.purpose !== "bathroom";
  };

  handlePurposeChange = (value, { name }, { values, setValues }) => {
    // each purpose has own types
    const typeOptions = this.getRoomSpaceTypeOptions(value);
    // by default, we select first type
    const defaultType = typeOptions[0].value;

    // bathroom does not have private bathroom and bed configuration options
    // so, we need to reset them when changing from other room space purposes to bathroom
    let bathroomOverride = {};
    if (value === "bathroom") {
      bathroomOverride = {
        hasPrivateBathroom: DEFAULT_HAS_PRIVATE_BATHROOM,
        bedConfigurationOptions: [],
      };
    }

    setValues({
      ...values,
      [name]: value,
      type: defaultType,
      ...bathroomOverride,
    });
  };

  render() {
    const { t, componentRef, value, submitting, errors, onChange, onSubmit } = this.props;

    return (
      <>
        <ChannexForm
          defaultValue={this.DEFAULT_VALUE}
          value={value}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
        >
          {({ values, handleSubmit }) => (
            <Form onFinish={handleSubmit}>
              <legend>Room Space</legend>
              <FormikFormInput
                name="title"
                placeholder={t("rooms_page:spaces:title")}
                label={t("rooms_page:spaces:title")}
              />
              <InputGroup label={t("rooms_page:spaces:size")}>
                <FormikFormInput
                  className={styles.fullWidth}
                  grouped
                  placeholder="Width"
                  name="width"
                />
                <FormikFormInput
                  className={styles.fullWidth}
                  grouped
                  placeholder="Length"
                  name="length"
                />
                <FormikFormSelect
                  className={styles.fullWidth}
                  grouped
                  placeholder="Unit"
                  name="unit"
                  options={this.getSizeUnitOptions()}
                />
              </InputGroup>
              <FormikFormSelect
                name="purpose"
                placeholder={t("rooms_page:spaces:purpose")}
                label={t("rooms_page:spaces:purpose")}
                onChange={this.handlePurposeChange}
                options={this.getRoomSpacePurposeOptions()}
              />
              <FormikFormSelect
                name="type"
                placeholder={t("rooms_page:spaces:type")}
                label={t("rooms_page:spaces:type")}
                options={this.getRoomSpaceTypeOptions(values.purpose)}
              />
              {this.hasBedConfigurations(values) && (
                <FormikFormCheckbox
                  name="hasPrivateBathroom"
                  label={t("rooms_page:spaces:has_private_bathroom")}
                />
              )}
              {this.hasBedConfigurations(values) && (
                <>
                  <div className={styles.header}>
                    <p className={styles.title}>{t("rooms_page:spaces:bed_preferences")}</p>
                    <Button
                      data-cy="create_bed_preferences"
                      icon={<PlusCircleOutlined />}
                      type="link"
                      onClick={() => {
                        this.bedConfigurationsHelpers.push({
                          bedDescriptions: [{ ...emptyBedDescription }],
                        });
                      }}
                    >
                      {t("general:action:create")}
                    </Button>
                  </div>
                  <FieldArray
                    name="bedConfigurationOptions"
                    render={(arrayHelpers) => {
                      this.bedConfigurationsHelpers = arrayHelpers;

                      return (
                        <div>
                          {values.bedConfigurationOptions.length === 0 && (
                            <NoDataPlaceholder emptyMessage={t("rooms_page:spaces:empty_bed_preferences")} />
                          )}
                          {values.bedConfigurationOptions.map((bedConfiguration, bedConfigurationIndex) => (
                            <div className={styles.bedPreferenceRow} key={bedConfigurationIndex}>
                              <FieldArray
                                name={`bedConfigurationOptions[${bedConfigurationIndex}].bedDescriptions`}
                                render={(bedDescriptionsHelpers) => (
                                  <div>
                                    {bedConfiguration.bedDescriptions.map((bedDescription, bedDescriptionIndex) => (
                                      <div key={bedDescriptionIndex}>
                                        <InputGroup
                                          label={bedDescriptionIndex === 0 ? `${t("rooms_page:spaces:bed_configuration")} ${bedConfigurationIndex + 1}` : <span />}
                                          className={bedDescriptionIndex === 0 ? null : styles.emptyLabel}
                                        >
                                          <FormikFormSelect
                                            className={styles.fullWidth}
                                            grouped
                                            placeholder="Type"
                                            name={`bedConfigurationOptions[${bedConfigurationIndex}].bedDescriptions[${bedDescriptionIndex}].type`}
                                            options={this.getBedTypeOptions()}
                                          />
                                          <FormikFormSelect
                                            className={styles.fullWidth}
                                            grouped
                                            placeholder="Size"
                                            name={`bedConfigurationOptions[${bedConfigurationIndex}].bedDescriptions[${bedDescriptionIndex}].size`}
                                            options={this.getBedSizeOptions()}
                                          />
                                          <FormikFormInput
                                            style={{ width: "70px" }}
                                            grouped
                                            placeholder="Count"
                                            name={`bedConfigurationOptions[${bedConfigurationIndex}].bedDescriptions[${bedDescriptionIndex}].count`}
                                          />
                                          <div className={styles.rowActions}>
                                            <Button
                                              type="link"
                                              icon={<PlusCircleOutlined />}
                                              onClick={() => {
                                                bedDescriptionsHelpers.insert(bedDescriptionIndex + 1, { ...emptyBedDescription });
                                              }}
                                            />
                                            {this.canDeleteBedDescription(values) && (
                                              <Button
                                                type="link"
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                  bedDescriptionsHelpers.remove(bedDescriptionIndex);

                                                  if (bedConfiguration.bedDescriptions.length === 1) {
                                                    this.bedConfigurationsHelpers.remove(bedConfigurationIndex);
                                                  }
                                                }}
                                              />
                                            )}
                                          </div>
                                        </InputGroup>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              />
                            </div>
                          ))}
                        </div>
                      );
                    }}
                  />
                </>
              )}
            </Form>
          )}
        </ChannexForm>

        <div className={formInDrawerStyles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default withTranslation()(RoomSpaceForm);
