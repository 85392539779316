import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import withRouter from "routing/with_router";

import PropertyView from "./property_view";

class PropertyViewDrawer extends Component {
  render() {
    const { t, params } = this.props;
    const { propertyId } = params;
    const title = t("properties_page:view_dialog_header");

    return (
      <ChannexDrawerRoutable title={title}>
        {() => <PropertyView propertyId={propertyId} />}
      </ChannexDrawerRoutable>
    );
  }
}

export default withRouter(withTranslation()(PropertyViewDrawer));
