const addMappedRateToGroup = (ratesGroup, mappedRate) => {
  const { maxOccupancy = 0, ratePlans = [], isOccupancySet = null } = ratesGroup;

  const maxPossibleOccupancy = maxOccupancy < mappedRate.occupancy ? mappedRate.occupancy : maxOccupancy;
  const updatedSetOccupancy = mappedRate.primary_occ && mappedRate.occ_changed ? mappedRate.occupancy : isOccupancySet;
  const updatedRatesList = [...ratePlans, mappedRate];

  return {
    maxOccupancy: maxPossibleOccupancy,
    ratePlans: updatedRatesList,
    isOccupancySet: updatedSetOccupancy,
  };
};

const groupMappedRatesById = (mappings) => {
  return Object.keys(mappings).reduce((acc, rateId) => {
    const mapping = mappings[rateId] || [];

    mapping.filter(Boolean).forEach((rate) => {
      const ratesRoom = acc[rate.room_type_code] || {};
      const ratesGroup = ratesRoom[rate.rate_plan_code] || {};
      const updatedRatesGroup = addMappedRateToGroup(ratesGroup, rate);

      const updatedRatesRoom = { ...ratesRoom, [rate.rate_plan_code]: updatedRatesGroup };
      acc[rate.room_type_code] = updatedRatesRoom;
    });

    return acc;
  }, {});
};

const setPrimaryOccValue = (ratePlans, maxOccupancy) => {
  return ratePlans.map((rate) => {
    if (rate.occupancy === maxOccupancy && !rate.readonly) {
      return { ...rate, primary_occ: true };
    }

    return { ...rate, primary_occ: false };
  });
};

const updateRateGroup = (ratesGroups) => {
  return Object.keys(ratesGroups).reduce((acc, rateId) => {
    const { ratePlans, isOccupancySet, maxOccupancy } = ratesGroups[rateId];

    if (isOccupancySet) {
      acc[rateId] = ratePlans;

      return acc;
    }

    const updatedRates = setPrimaryOccValue(ratePlans, maxOccupancy);

    acc[rateId] = updatedRates;
    return acc;
  }, {});
};

const recalculatePrimaryOccValuePerRateGroup = (groupedExternalRates) => {
  return Object.keys(groupedExternalRates).reduce((acc, roomId) => {
    const assignedRateGroups = groupedExternalRates[roomId];

    const updatedRateGroups = updateRateGroup(assignedRateGroups);

    acc[roomId] = updatedRateGroups;
    return acc;
  }, {});
};

const updateMappedRates = (mappings, updatedExternalRatesById) => {
  return Object.keys(mappings).reduce((acc, rateId) => {
    const mapping = mappings[rateId] || [];

    acc[rateId] = mapping.map((externalRate) => {
      if (!externalRate) {
        return null;
      }

      const updatedExternalRoom = updatedExternalRatesById[externalRate.room_type_code];
      const updatedExternalRates = updatedExternalRoom[externalRate.rate_plan_code];

      const updatedRate = updatedExternalRates.find(
        (rate) => rate.occupancy === externalRate.occupancy,
      );

      return updatedRate;
    });

    return acc;
  }, {});
};

const updatePrimaryOccValues = (mappings) => {
  const mappedExternalRatesById = groupMappedRatesById(mappings);

  const updatedExternalRatesById = recalculatePrimaryOccValuePerRateGroup(mappedExternalRatesById);

  const mappingsWithUpdatedBComRates = updateMappedRates(mappings, updatedExternalRatesById);

  return mappingsWithUpdatedBComRates;
};

export default updatePrimaryOccValues;
