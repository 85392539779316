import { convertToSnakeCase } from "store/utils/case_converter";

import APP_MODES from "config/constants/app_modes";

const CUSTOMERLY_CONFIG = {
  direction: "left",
};

const customerly = ({ config }, user) => {
  const { customerly: customerlyInstance } = window;

  if (!customerlyInstance) {
    return;
  }

  const params = convertToSnakeCase({
    ...CUSTOMERLY_CONFIG,
    ...config,
    ...user,
  });

  const isAlreadyInitalized = customerlyInstance.loaded || !customerlyInstance.load;

  if (isAlreadyInitalized) {
    customerlyInstance.update(params);
    return;
  }

  customerlyInstance.load(params);
};

const intercom = ({ config }, user) => {
  const userData = user
    ? {
      name: user.name,
      email: user.email,
    }
    : {};

  window.Intercom("boot", {
    app_id: config.appId,
    ...userData,
  });
};

const fallback = () => {};

const appHandlers = {
  customerly,
  intercom,
  fallback,
};

const initSupportApp = (session, user, isDesktop) => {
  const { appSettings, appMode } = session;
  const isAppDisabled = appMode === APP_MODES.HEADLESS || !isDesktop;

  if (!appSettings || isAppDisabled) {
    return;
  }

  const { supportApp } = appSettings;
  const { [supportApp?.app]: appHandler = appHandlers.fallback } = appHandlers;

  appHandler(supportApp, user);
};

export default initSupportApp;
