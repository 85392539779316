import React from "react";
import * as Sentry from "@sentry/react";

import MissingChannelSettings from "./missing_channel_settings";
import MissingContentProperty from "./missing_content_property";

const COMPONENTS_BY_CODE = {
  required_for_property: MissingContentProperty,
  invalid_for_channel: MissingChannelSettings,
};

export default function MissingEntry({ propertyId, missingContent, onReload, onChannelSettingsNavigateClick }) {
  const Component = COMPONENTS_BY_CODE[missingContent.code];

  if (!Component) {
    Sentry.captureException(new Error(`MissingEntry: unknown missing content code ${missingContent.code}`));
    return null;
  }

  return (
    <Component
      propertyId={propertyId}
      missingContent={missingContent}
      onReload={onReload}
      onChannelSettingsNavigateClick={onChannelSettingsNavigateClick}
    />
  );
}
