import React from "react";
import { Outlet } from "react-router-dom";
import classNames from "classnames";

import ReviewList from "./reviews_list";

import styles from "./review_app.module.css";

export default function ReviewApp({ isMobile }) {
  const className = classNames(styles.container, {
    [styles.mobile]: isMobile,
    [styles.desktop]: !isMobile,
  });

  return (
    <div className={className}>
      {isMobile && (
        <>
          <Outlet context={{ isMobile }} />
          <ReviewList isMobile={isMobile} />
        </>
      )}
      {!isMobile && (
        <>
          <ReviewList isMobile={isMobile} />
          <Outlet context={{ isMobile }} />
        </>
      )}
    </div>
  );
}
