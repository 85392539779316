import React, { Component } from "react";
import { DatePicker, Form } from "antd";
import { Field } from "formik";

import dayjs from "utils/dayjs";

import getFieldLayout from "../utils/get_field_layout";

import styles from "styles/form_in_drawer.module.css";

const DEFAULT_FORMAT = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

class FormikFormRangepicker extends Component {
  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  }

  handleChange = (value, field, form) => {
    const { format = DEFAULT_FORMAT, onChange, name } = this.props;

    const formattedDate = value ? value.map((date) => date.format(format)) : value;

    return onChange
      ? onChange(formattedDate, field, form)
      : form.setFieldValue(name, formattedDate);
  };

  getValue = (fieldValue) => {
    return fieldValue ? fieldValue.map((date) => dayjs(date)) : undefined;
  };

  render() {
    const { name, label, view, errors, onBlur = () => {} } = this.props;
    const formItemLayout = getFieldLayout(view);

    return (
      <Field name={name}>
        {({ field, form }) => {
          return (
            <Form.Item
              {...formItemLayout}
              {...FormikFormRangepicker.validation(errors)}
              label={label}
              data-cy={`rangepicker_${name}`}
            >
              <RangePicker
                value={this.getValue(field.value)}
                onChange={(value) => this.handleChange(value, field, form)}
                onBlur={onBlur}
                className={styles.buttonGroup}
              />
            </Form.Item>
          );
        }}
      </Field>
    );
  }
}

export default FormikFormRangepicker;
