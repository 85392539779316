import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import store from "store";
import { getActiveProperty } from "store/storage/selectors/session_selector";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import useBoolState from "utils/use_bool_state";

import styles from "./score_statistics.module.css";

const { Scores } = store;

function ScoreStatistics({ isMobile }) {
  const { t } = useTranslation();
  const activeProperty = useSelector(getActiveProperty);
  const [loading, _setTrueLoading, setFalseLoading] = useBoolState(true);
  const [score, setScore] = useState(null);
  const { userAppRoutes } = useRouting();
  const pathname = pathBuilder(userAppRoutes.reviews.details, {});
  const match = useMatch({ path: userAppRoutes.reviews.review._path, end: false });
  const reviewId = match?.params?.review_id;

  useEffect(
    function loadScores() {
      if (activeProperty) {
        Scores.score(activeProperty).then(
          (response) => {
            setScore(response.attributes);
            setFalseLoading();
          },
          () => {
            setScore({ overallScore: null });
            setFalseLoading();
          },
        );
      } else {
        setScore({ overallScore: null });
        setFalseLoading();
      }
    },
    [activeProperty, setScore, setFalseLoading],
  );

  return isMobile === true && reviewId ? null : (
    <div className={styles.block}>
      <Row gutter={16}>
        <Col span={18} className={styles.score}>
          {t("reviews_page:scores:total_score")}:&nbsp;
          {loading && <LoadingOutlined />}
          {!loading && score.overallScore === null && "N/A"}
          {!loading && score.overallScore !== null && `${score.overallScore} / 10`}
        </Col>
        <Col span={6} className={styles.rightText}>
          <Link to={{ pathname }}>{t("reviews_page:scores:details")}</Link>
        </Col>
      </Row>
    </div>
  );
}

ScoreStatistics.propTypes = {};

export default ScoreStatistics;
