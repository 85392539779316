import React from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import store from "store";

import ActionBar from "components/event/action_bar";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import ReservationRequestActionButtons from "./reservation_request_action_buttons";

const { LiveFeedEvents } = store;

export default function ReservationRequestActions({ eventData, onClose }) {
  const { t } = useTranslation();

  const handleResolve = (resolution) => {
    return LiveFeedEvents.resolve(eventData.id, resolution)
      .then(onClose)
      .catch(showErrorMessageFromResponse);
  };

  const actions = eventData.payload.resolved ? (
    <div style={{ textAlign: "right" }}>
      <Tag color="success">{t("live_feed_event_drawer:resolved")}</Tag>
      {eventData.payload.comment ? (
        <>
          <br />
          {eventData.payload.comment}
        </>
      ) : null}
    </div>
  ) : (
    <ReservationRequestActionButtons onResolve={handleResolve} />
  );

  return (
    <ActionBar title={t(`live_feed_events_widget:events:${eventData.event}`)} actions={actions} />
  );
}
