import React, { Component } from "react";
import { Button } from "antd";

import UploadcareLoader from "components/forms/inputs/uploadcare";

import styles from "./photo_uploader.module.css";

class PhotoUploader extends Component {
  state = {
    loading: false,
  };

  uploadWidget = React.createRef();

  handleOpenClick = () => {
    const { current } = this.uploadWidget;
    const { loading } = this.state;

    if (current && !loading) {
      current.openDialog();
    }
  };

  handlePictureSelect = (uploadingFiles) => {
    const { onFileSelect } = this.props;

    this.setState({ loading: true });

    const promiseObject = Array.isArray(uploadingFiles)
      ? Promise.all(uploadingFiles)
      : uploadingFiles;

    promiseObject
      .then((files) => {
        onFileSelect(files);

        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  renderDefaultButton = () => {
    const { label } = this.props;

    return (
      <Button block className={styles.loadButton} size="large" type="primary">
        {label}
      </Button>
    );
  };

  render() {
    const { loading } = this.state;
    const { button, multiple } = this.props;

    const view = button || this.renderDefaultButton();
    const updatedButton = React.cloneElement(view, { onClick: this.handleOpenClick, loading });

    return (
      <>
        {updatedButton}

        <UploadcareLoader
          multiple={multiple}
          id="property_loader"
          onFileSelect={this.handlePictureSelect}
          inputRef={this.uploadWidget}
        />
      </>
    );
  }
}

export default PhotoUploader;
