import countries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import esCountries from "i18n-iso-countries/langs/es.json";
import ptCountries from "i18n-iso-countries/langs/pt.json";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import queryString from "query-string";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import AppStorageManager from "utils/app_storage_manager";

const DEFAULT_LANG = "en";

countries.registerLocale(enCountries);
countries.registerLocale(esCountries);
countries.registerLocale(ptCountries);

const isLanguageSupported = (lngCode) => {
  return SUPPORTED_LANGUAGES.find((language) => language.key === lngCode);
};

const query = queryString.parse(window.location.search);

if (isLanguageSupported(query.lng)) {
  AppStorageManager.setItem("CHANNEX_LANGUAGE", query.lng);
}

const getDefaultLanguage = () => {
  const sessionLang = AppStorageManager.getItem("CHANNEX_LANGUAGE");
  if (isLanguageSupported(sessionLang)) {
    return sessionLang;
  }

  const user = AppStorageManager.getItem("CHANNEX_USER");
  const userLang = user?.preferred_language;
  if (isLanguageSupported(userLang)) {
    return userLang;
  }

  const browserLanguage = window.navigator.language?.substring(0, 2);
  if (isLanguageSupported(browserLanguage)) {
    return browserLanguage;
  }

  return DEFAULT_LANG;
};

const i18nInit = () => i18next
  .use(HttpApi)
  .init({
    interpolation: { escapeValue: false },
    defaultNS: "common",
    lng: getDefaultLanguage(),
    fallbackLng: DEFAULT_LANG,
    keySeparator: ":",
    backend: {
      loadPath: "https://translations.fra1.digitaloceanspaces.com/translations/50ba2612-ef0a-4b7b-8c79-a1f4a6ce5fdc/{{lng}}.json",
    },
  });

export default i18nInit;
