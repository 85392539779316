import React from "react";
import { useTranslation } from "react-i18next";

import ContentContainer from "../../../../../../../_common/content_container";
import SectionHeader from "../../../../../../../_common/section_header/section_header";
import Text from "../../../../../../../_common/text";

import styles from "./public_feedback.module.css";

export default function PublicFeedback({ review }) {
  const { t } = useTranslation();

  const { attributes: { rawContent, content } } = review;

  const { publicReview, privateFeedback } = rawContent || {};

  const isFeedbackPresent = !!content;

  return (
    <ContentContainer className={styles.publicFeedback} border={!!privateFeedback} innerPadding={false}>
      <SectionHeader header={t("reviews_page:feedback:public_feedback")} />
      {isFeedbackPresent && <Text>{publicReview}</Text>}
      {!isFeedbackPresent && <Text disabled>{t("reviews_page:feedback:no_content")}</Text>}
    </ContentContainer>
  );
}
