import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";

import styles from "./publish_select.module.css";

class PublishSelect extends Component {
  state = {
    loading: false,
  };

  handlePublishClick = () => {
    const { onPublish } = this.props;

    this.setState({ loading: true });
    onPublish().finally(this.onStatusChangeComplete);
  };

  handleUnpublishClick = () => {
    const { onUnpublish } = this.props;

    this.setState({ loading: true });
    onUnpublish().finally(this.onStatusChangeComplete);
  };

  onStatusChangeComplete = () => {
    this.setState({ loading: false });
  };

  render() {
    const { t, published, disabled } = this.props;
    const { loading } = this.state;

    const items = [{
      key: "channels_page_form_listing_publish",
      onClick: this.handlePublishClick,
      label: (
        <div>
          {t("channels_page:form:listing:publish")}
        </div>
      ),
      disabled: published,
    }, {
      key: "channels_page_form_listing_unpublish",
      onClick: this.handleUnpublishClick,
      label: (
        <div>
          {t("channels_page:form:listing:unpublish")}
        </div>
      ),
      disabled: !published,
    }];

    const buttonText = published
      ? t("channels_page:form:listing:published")
      : t("channels_page:form:listing:unpublished");

    return (
      <div className={styles.selectContainer}>
        <Dropdown menu={{ items }} disabled={disabled} trigger={["click"]}>
          <Button disabled={disabled} type="link">
            {buttonText}
          </Button>
        </Dropdown>
        <div className={styles.loadingIcon}>{loading && <SyncOutlined spin />}</div>
      </div>
    );
  }
}

export default withTranslation()(PublishSelect);
