import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Col, Form, Input, Row, Switch } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

function RestrictionFields(props) {
  const { t, allowChange, errors, onFieldChange, onChangeAllowedRestriction, minStayType } = props;

  return (
    <Form.Item {...horizontalFormItemLayout} label={t("inventory_page:bulk_update:restrictions")}>
      <Row>
        <Col xs={{ span: 12 }}>
          <Checkbox data-cy="rate_checkbox" onChange={onChangeAllowedRestriction("rate")}>
            {t("general:restrictions:rate")}
          </Checkbox>
        </Col>
        <Col xs={{ span: 12 }}>
          <Form.Item
            data-cy="rate_value"
            validateStatus={errors && errors.rate ? "error" : null}
            help={errors && errors.rate}
          >
            <Input
              placeholder={t("general:restrictions:rate")}
              disabled={!allowChange.rate}
              onChange={onFieldChange("rate")}
              type="number"
              min="0"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }}>
          <Checkbox
            data-cy="stop_sell_checkbox"
            onChange={onChangeAllowedRestriction("stop_sell_manual")}
          >
            {t("general:restrictions:stop_sell")}
          </Checkbox>
        </Col>
        <Col xs={{ span: 12 }}>
          <Switch
            data-cy="stop_sell_value"
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={!allowChange.stop_sell_manual}
            onChange={onFieldChange("stop_sell_manual")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }}>
          <Checkbox
            data-cy="closed_to_arrival_checkbox"
            onChange={onChangeAllowedRestriction("closed_to_arrival")}
          >
            {t("general:restrictions:closed_to_arrival")}
          </Checkbox>
        </Col>
        <Col xs={{ span: 12 }}>
          <Switch
            data-cy="closed_to_arrival_value"
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={!allowChange.closed_to_arrival}
            onChange={onFieldChange("closed_to_arrival")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }}>
          <Checkbox
            data-cy="closed_to_departure_checkbox"
            onChange={onChangeAllowedRestriction("closed_to_departure")}
          >
            {t("general:restrictions:closed_to_departure")}
          </Checkbox>
        </Col>
        <Col xs={{ span: 12 }}>
          <Switch
            data-cy="closed_to_departure_value"
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={!allowChange.closed_to_departure}
            onChange={onFieldChange("closed_to_departure")}
          />
        </Col>
      </Row>
      {minStayType === "both" && (
        <>
          <Row>
            <Col xs={{ span: 12 }}>
              <Checkbox
                data-cy="min_stay_arrival_checkbox"
                onChange={onChangeAllowedRestriction("min_stay_arrival")}
              >
                {t("general:restrictions:min_stay_arrival")}
              </Checkbox>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item
                data-cy="min_stay_arrival_value"
                validateStatus={errors && errors.min_stay_arrival ? "error" : null}
                help={errors && errors.min_stay_arrival}
              >
                <Input
                  placeholder={t("general:restrictions:min_stay_arrival")}
                  disabled={!allowChange.min_stay_arrival}
                  onChange={onFieldChange("min_stay_arrival")}
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12 }}>
              <Checkbox
                data-cy="min_stay_through_checkbox"
                onChange={onChangeAllowedRestriction("min_stay_through")}
              >
                {t("general:restrictions:min_stay_through")}
              </Checkbox>
            </Col>
            <Col xs={{ span: 12 }}>
              <Form.Item
                data-cy="min_stay_through_value"
                validateStatus={errors && errors.min_stay_through ? "error" : null}
                help={errors && errors.min_stay_through}
              >
                <Input
                  placeholder={t("general:restrictions:min_stay_through")}
                  disabled={!allowChange.min_stay_through}
                  onChange={onFieldChange("min_stay_through")}
                  type="number"
                  min="1"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {minStayType === "arrival" && (
        <Row>
          <Col xs={{ span: 12 }}>
            <Checkbox
              data-cy="min_stay_arrival_checkbox"
              onChange={onChangeAllowedRestriction("min_stay_arrival")}
            >
              {t("general:restrictions:min_stay")}
            </Checkbox>
          </Col>
          <Col xs={{ span: 12 }}>
            <Form.Item
              data-cy="min_stay_arrival_value"
              validateStatus={errors && errors.min_stay_arrival ? "error" : null}
              help={errors && errors.min_stay_arrival}
            >
              <Input
                placeholder={t("general:restrictions:min_stay")}
                disabled={!allowChange.min_stay_arrival}
                onChange={onFieldChange("min_stay_arrival")}
                type="number"
                min="1"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {minStayType === "through" && (
        <Row>
          <Col xs={{ span: 12 }}>
            <Checkbox
              data-cy="min_stay_through_checkbox"
              onChange={onChangeAllowedRestriction("min_stay_through")}
            >
              {t("general:restrictions:min_stay")}
            </Checkbox>
          </Col>
          <Col xs={{ span: 12 }}>
            <Form.Item
              data-cy="min_stay_through_value"
              validateStatus={errors && errors.min_stay_through ? "error" : null}
              help={errors && errors.min_stay_through}
            >
              <Input
                placeholder={t("general:restrictions:min_stay")}
                disabled={!allowChange.min_stay_through}
                onChange={onFieldChange("min_stay_through")}
                type="number"
                min="1"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={{ span: 12 }}>
          <Checkbox data-cy="max_stay_checkbox" onChange={onChangeAllowedRestriction("max_stay")}>
            {t("general:restrictions:max_stay")}
          </Checkbox>
        </Col>
        <Col xs={{ span: 12 }}>
          <Form.Item
            data-cy="max_stay_value"
            validateStatus={errors && errors.max_stay ? "error" : null}
            help={errors && errors.max_stay}
          >
            <Input
              placeholder={t("general:restrictions:max_stay")}
              disabled={!allowChange.max_stay}
              onChange={onFieldChange("max_stay")}
              type="number"
              min="0"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }}>
          <Checkbox
            data-cy="max_availability_checkbox"
            onChange={onChangeAllowedRestriction("max_availability")}
          >
            {t("general:restrictions:max_availability")}
          </Checkbox>
        </Col>
        <Col xs={{ span: 12 }}>
          <Form.Item
            data-cy="max_availability_value"
            validateStatus={errors && errors.max_availability ? "error" : null}
            help={errors && errors.max_availability}
          >
            <Input
              placeholder={t("general:restrictions:max_availability")}
              disabled={!allowChange.max_availability}
              onChange={onFieldChange("max_availability")}
              type="number"
              min="0"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
}

RestrictionFields.propTypes = {
  t: PropTypes.func.isRequired,
  allowChange: PropTypes.object.isRequired,
  onChangeAllowedRestriction: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  minStayType: PropTypes.string,
};

export default RestrictionFields;
