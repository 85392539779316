import React from "react";
import { useTranslation } from "react-i18next";

import MappingSection from "components/mapping/mapping_section";

import RemovedRoom from "./removed_room";

export default function RemovedRates({ ratesTree, mappings, onChangeMapping }) {
  const { t } = useTranslation();

  const hasUnmappedRates = Object.keys(ratesTree).length;

  const onMappingDelete = (ratePlan) => {
    const { [ratePlan.id]: mappingsToUpdate } = mappings;

    const updatedMappings = mappingsToUpdate.map((mapping) => {
      const isMappingMatches = mapping
        && mapping.occupancy === ratePlan.occupancy
        && mapping.rate_plan_code === ratePlan.rate_plan_code
        && mapping.room_type_code === ratePlan.room_type_code;

      return isMappingMatches ? null : mapping;
    });

    onChangeMapping({ ...mappings, [ratePlan.id]: updatedMappings });
  };

  if (!hasUnmappedRates) {
    return null;
  }

  return (
    <MappingSection title={t("channels_page:form:removed_rates_header")}>
      {Object.keys(ratesTree).map((roomId) => (
        <RemovedRoom
          key={roomId}
          roomId={roomId}
          ratePlans={ratesTree[roomId]}
          onMappingDelete={onMappingDelete}
        />
      ))}
    </MappingSection>
  );
}
