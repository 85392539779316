import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

class PetsPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    pets_policy: yup.string().required(errorMessages.required()),
    pets_non_refundable_fee: yup
      .number()
      .nullable()
      .when("pets_policy", {
        is: (val) => val !== "not_allowed",
        then: yup
          .number()
          .min(0, errorMessages.greaterOrEqual(0))
          .required(errorMessages.required())
          .typeError(errorMessages.number()),
      }),
    pets_refundable_deposit: yup
      .number()
      .nullable()
      .when("pets_policy", {
        is: (val) => val !== "not_allowed",
        then: yup
          .number()
          .min(0, errorMessages.greaterOrEqual(0))
          .required(errorMessages.required())
          .typeError(errorMessages.number()),
      }),
  });

  DEFAULT_VALUE = {
    pets_policy: "not_allowed",
  };

  static FIELDS = ["pets_policy", "pets_non_refundable_fee", "pets_refundable_deposit"];

  getPetsPolicyOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "not_allowed",
        representation: t("policies:pets_policy:options:not_allowed"),
      },
      {
        value: "by_arrangements",
        representation: t("policies:pets_policy:options:by_arrangements"),
      },
      {
        value: "assistive_only",
        representation: t("policies:pets_policy:options:assistive_only"),
      },
      {
        value: "allowed",
        representation: t("policies:pets_policy:options:allowed"),
      },
    ];
  };

  isPetsAllowed = (values) => {
    const { pets_policy } = values;

    return pets_policy && pets_policy !== "not_allowed";
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={PetsPolicyForm.FIELDS}
        componentRef={componentRef}
        onChange={onChange}
      >
        {({ values, handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormSelect
              name="pets_policy"
              view="horizontal"
              placeholder={t("policies:pets_policy:title")}
              label={t("policies:pets_policy:title")}
              options={this.getPetsPolicyOptions()}
            />
            {this.isPetsAllowed(values) && (
              <>
                <FormikFormInput
                  name="pets_non_refundable_fee"
                  view="horizontal"
                  placeholder={t("policies:pets_policy:pets_fee")}
                  label={t("policies:pets_policy:pets_fee")}
                  addonAfter={value.currency}
                />
                <FormikFormInput
                  name="pets_refundable_deposit"
                  view="horizontal"
                  placeholder={t("policies:pets_policy:pets_deposit")}
                  label={t("policies:pets_policy:pets_deposit")}
                  addonAfter={value.currency}
                />
              </>
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

export default withTranslation()(PetsPolicyForm);
