const clickableEventTypes = [
  "booking_new",
  "booking_modification",
  "booking_cancellation",
  "sync_error",
  "booking_unmapped_room",
  "booking_unmapped_rate",
  "reservation_request",
  "message",
  "general_error",
  "alteration_request",
  "inquiry",
  "airbnb_notification",
];

export const SYNC_ERROR_EVENT_TYPE = "sync_error";
export const NEW_MESSAGE_EVENT_TYPE = "message";
export const RESERVATION_REQUEST_TYPE = "reservation_request";
export const ALTERATION_REQUEST_TYPE = "alteration_request";
export const INQUIRY_REQUEST_TYPE = "inquiry";
export const AIRBNB_NOTIFICATION = "airbnb_notification";

export const getIsEventClickable = (event) => {
  return clickableEventTypes.includes(event);
};

export const handleOpenEvent = (attributes, setBookingId, setLogOrEventId, setLiveFeedEventId) => {
  const isClickable = getIsEventClickable(attributes?.event);
  const bookingId = attributes?.payload?.bookingId;
  const logOrEventId = attributes?.payload?.logId || attributes?.payload?.channelEventId;
  const liveFeedEventId = attributes?.id;

  if (!isClickable) {
    return null;
  }
  if (bookingId) {
    return setBookingId(bookingId);
  }
  if (logOrEventId) {
    return setLogOrEventId(logOrEventId);
  }
  if (liveFeedEventId) {
    return setLiveFeedEventId(liveFeedEventId);
  }

  return null;
};
