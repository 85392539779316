import EventEmmiter from "utils/event_emmiter";

import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase } from "../utils/case_converter";
import relationshipsExtractor from "../utils/relationships_extractor";

import Properties from "./properties";

let transport;
let storage;

const ENDPOINT = "groups";

export default class Groups {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = {}) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.groupsLoad(response.data);

      return response;
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      storage.groupsAdd(response.data);

      return relationshipsExtractor(response.data);
    });
  }

  options(filter = {}) {
    return transport.send("GET", `${ENDPOINT}/options`, { filter }).then(({ data }) => {
      return attributesExtractor(data);
    });
  }

  setOptions(options) {
    storage.groupsOptionsLoad(options);
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { group: attrs }).then((response) => {
      EventEmmiter.trigger("group:created", response.data);
      storage.groupsAdd(response.data);
      return response;
    });
  }

  update(attrs) {
    return transport.send("PUT", `${ENDPOINT}/${attrs.id}`, { group: attrs }).then((response) => {
      EventEmmiter.trigger("group:updated", response.data);
      storage.groupsAdd(response.data);
      return response;
    });
  }

  remove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}`).then((response) => {
      EventEmmiter.trigger("group:deleted", attrs.id);

      storage.groupsDrop(attrs);
      return response;
    });
  }

  addProperty(groupId, propertyId) {
    return transport
      .send("POST", `${ENDPOINT}/${groupId}/properties/${propertyId}`, {})
      .then((response) => {
        EventEmmiter.trigger("group:properties_updated", groupId);

        Promise.all([
          this.find(groupId),
          new Properties({ transport, storage }).find(propertyId),
        ]).then(() => {
          return response;
        });
      });
  }

  removeProperty(groupId, propertyId) {
    return transport
      .send("DELETE", `${ENDPOINT}/${groupId}/properties/${propertyId}`)
      .then((response) => {
        EventEmmiter.trigger("group:properties_updated", groupId);

        Promise.all([
          this.find(groupId),
          new Properties({ transport, storage }).find(propertyId),
        ]).then(() => {
          return response;
        });
      });
  }

  bulkAssignProperties(groupId, propertiesIds) {
    return transport
      .send("POST", `${ENDPOINT}/${groupId}/property_membership`, {
        group_property_ids: propertiesIds,
      })
      .then(() => {
        EventEmmiter.trigger("group:properties_updated", groupId);

        return this.find(groupId);
      });
  }

  membershipRules(groupId) {
    return transport.send("GET", `${ENDPOINT}/${groupId}/property_membership/rules`)
      .then((response) => {
        return convertToCamelCase(response.data.attributes);
      });
  }
}
