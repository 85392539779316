import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { Form } from "formik";
import * as yup from "yup";

import channelFilterOptions from "config/constants/channels/channel_booking_filter";

import ChannexForm from "components/channex_form";
import FormikFormRangepicker from "components/forms/inputs/formik/form_rangepicker";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

import styles from "./advanced_search.module.css";

const restoreFromFilter = (filter) => {
  const output = {};

  if (filter.ota_name && typeof filter.ota_name === "string") {
    output.ota_name = filter.ota_name.split(",");
  } else {
    output.ota_name = [];
  }

  if (filter.status && typeof filter.status === "string") {
    output.status = filter.status.split(",");
  } else {
    output.status = [];
  }

  if (filter.arrival_date) {
    output.arrival_date = [filter.arrival_date.gte, filter.arrival_date.lte];
  } else {
    output.arrival_date = null;
  }

  if (filter.departure_date) {
    output.departure_date = [filter.departure_date.gte, filter.departure_date.lte];
  } else {
    output.departure_date = null;
  }

  if (filter.inserted_at) {
    output.booking_date = [filter.inserted_at.gte, filter.inserted_at.lte];
  } else {
    output.booking_date = null;
  }

  if (filter.has_unacked_revisions) {
    output.has_unacked_revisions = filter.has_unacked_revisions;
  } else {
    output.has_unacked_revisions = null;
  }

  return output;
};

const convertAdvancedSearchToFilters = (advancedSearchValue) => {
  const advancedFilter = {};

  if (advancedSearchValue.ota_name) {
    advancedFilter.ota_name = advancedSearchValue.ota_name.join(",");
  }

  if (advancedSearchValue.status) {
    advancedFilter.status = advancedSearchValue.status.join(",");
  }

  if (advancedSearchValue.arrival_date) {
    advancedFilter.arrival_date = {
      gte: advancedSearchValue.arrival_date[0],
      lte: advancedSearchValue.arrival_date[1],
    };
  }

  if (advancedSearchValue.departure_date) {
    advancedFilter.departure_date = {
      gte: advancedSearchValue.departure_date[0],
      lte: advancedSearchValue.departure_date[1],
    };
  }

  if (advancedSearchValue.booking_date) {
    advancedFilter.inserted_at = {
      gte: `${advancedSearchValue.booking_date[0]} 00:00:00`,
      lte: `${advancedSearchValue.booking_date[1]} 23:59:59`,
    };
  }

  if (advancedSearchValue.has_unacked_revisions) {
    advancedFilter.has_unacked_revisions = advancedSearchValue.has_unacked_revisions;
  }

  return advancedFilter;
};

export default function AdvancedSearch({
  advancedSearchApply,
  advancedSearchFilter,
  toggleVisibility,
}) {
  const [value, setValue] = useState(restoreFromFilter(advancedSearchFilter));
  const { t } = useTranslation();
  const VALIDATION_SCHEMA = yup.object().shape({});
  const DEFAULT_VALUE = {
    ota_name: [],
    status: [],
    has_unacked_revisions: null,
    arrival_date: null,
    departure_date: null,
    booking_date: null,
  };
  const componentRef = React.createRef();

  const onChange = (changes) => {
    setValue(changes);
  };

  const cancel = () => {
    toggleVisibility();
  };

  const reset = () => {
    setValue(DEFAULT_VALUE);
    advancedSearchApply(convertAdvancedSearchToFilters({}));
  };

  const apply = () => {
    advancedSearchApply(convertAdvancedSearchToFilters(value));
  };

  return (
    <div className={styles.advancedSearchContainer}>
      <ChannexForm
        onChange={onChange}
        validationSchema={VALIDATION_SCHEMA}
        defaultValue={DEFAULT_VALUE}
        value={value}
        componentRef={componentRef}
      >
        {({ _handleSubmit }) => (
          <Form>
            <Row>
              <Col span={8}>
                <FormikFormSelect
                  name="ota_name"
                  mode="multiple"
                  view="horizontal"
                  placeholder={t("bookings_page:advanced_search:ota_name")}
                  label={t("bookings_page:advanced_search:ota_name")}
                  options={channelFilterOptions}
                />
              </Col>
              <Col span={8}>
                <FormikFormSelect
                  name="has_unacked_revisions"
                  mode="single"
                  view="horizontal"
                  placeholder={t("bookings_page:advanced_search:has_unacked_revisions")}
                  label={t("bookings_page:advanced_search:has_unacked_revisions")}
                  options={[
                    {
                      value: "true",
                      representation: t("bookings_page:booking_view_dialog:has_unacked_revisions:true"),
                    },
                    {
                      value: "false",
                      representation: t("bookings_page:booking_view_dialog:has_unacked_revisions:false"),
                    },
                  ]}
                />
              </Col>
              <Col span={8}>
                <FormikFormSelect
                  name="status"
                  mode="multiple"
                  view="horizontal"
                  placeholder={t("bookings_page:advanced_search:status")}
                  label={t("bookings_page:advanced_search:status")}
                  options={[
                    {
                      value: "new",
                      representation: t("bookings_page:booking_view_dialog:status:new"),
                    },
                    {
                      value: "modified",
                      representation: t("bookings_page:booking_view_dialog:status:modified"),
                    },
                    {
                      value: "cancelled",
                      representation: t("bookings_page:booking_view_dialog:status:cancelled"),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormikFormRangepicker
                  name="arrival_date"
                  view="horizontal"
                  placeholder={t("bookings_page:advanced_search:arrival_date")}
                  label={t("bookings_page:advanced_search:arrival_date")}
                />
              </Col>
              <Col span={8}>
                <FormikFormRangepicker
                  name="departure_date"
                  view="horizontal"
                  placeholder={t("bookings_page:advanced_search:departure_date")}
                  label={t("bookings_page:advanced_search:departure_date")}
                />
              </Col>
              <Col span={8}>
                <FormikFormRangepicker
                  name="booking_date"
                  view="horizontal"
                  placeholder={t("bookings_page:advanced_search:booking_date")}
                  label={t("bookings_page:advanced_search:booking_date")}
                />
              </Col>
            </Row>
          </Form>
        )}
      </ChannexForm>
      <div className={styles.advancedSearchControls}>
        <Button onClick={cancel}>{t("general:crud_table:advanced_search:cancel")}</Button>
        &nbsp;
        <Button onClick={reset}>{t("general:crud_table:advanced_search:reset")}</Button>
        &nbsp;
        <Button onClick={apply} type="primary">
          {t("general:crud_table:advanced_search:search")}
        </Button>
      </div>
    </div>
  );
}

AdvancedSearch.filters = ["ota_name", "status", "arrival_date", "departure_date", "booking_date"];
