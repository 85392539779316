import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import CancellationPolicyForm from "components/cancellation_policy/forms/cancellation_policy_form";
import GuaranteePaymentForm from "components/cancellation_policy/forms/guarantee_payment_form";
import NonShowPolicyForm from "components/cancellation_policy/forms/non_show_policy_form";
import DrawerFormWrapper from "components/drawer_form_wrapper";
import Wizard from "components/wizard";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import formInDrawerStyles from "styles/form_in_drawer.module.css";

const { CancellationPolicies } = store;

class CancellationPolicyWizard extends Component {
  state = {
    formValue: {},
    errors: {},
    submitting: false,
  };

  wizardRef = React.createRef();

  componentDidMount() {
    const { currency } = this.props;

    this.setState(({ formValue }) => ({
      formValue: { ...formValue, currency },
    }));
  }

  handleFinish = (values) => {
    const { propertyId } = this.props;
    this.setState({ submitting: true });

    CancellationPolicies.create({ ...values, property_id: propertyId })
      .then((response) => {
        const { onCreate, onClose } = this.props;
        this.setState({ submitting: false });
        onCreate(response);
        onClose();
      })
      .catch((payload) => {
        const errors = parseApiErrors(payload);

        this.setState({ submitting: false, errors });
      });
  };

  beforeClose = () => {
    const { getIsWizardDirty } = this.wizardRef.current;

    const isDirty = getIsWizardDirty();
    return confirmDirtyStateSave(isDirty);
  };

  render() {
    const { t } = this.props;
    const { errors, formValue, submitting } = this.state;

    return (
      <DrawerFormWrapper>
        <Wizard
          initialStep="guaranteePayment"
          initialValues={formValue}
          errors={errors}
          ref={this.wizardRef}
          onFinish={this.handleFinish}
          loading={submitting}
          actionsClassName={formInDrawerStyles.actions}
        >
          <Wizard.StepWithChannexForm
            content={<GuaranteePaymentForm />}
            name="guaranteePayment"
            title={t("policies:guarantee_payment:title")}
          />
          <Wizard.StepWithChannexForm
            content={<CancellationPolicyForm />}
            name="cancellationPolicy"
            title={t("policies:cancellation_policy:title")}
          />
          <Wizard.StepWithChannexForm
            content={<NonShowPolicyForm />}
            name="nonShowPolicy"
            title={t("policies:non_show_policy:title")}
          />
        </Wizard>
      </DrawerFormWrapper>
    );
  }
}

export default withTranslation()(withComponentRef(CancellationPolicyWizard));
