import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import RadioInput from "components/forms/inputs/formik/radio";

import styles from "./cancellation_policy_form.module.css";

const DECIMAL_PART_LENGTH = 2;
const MAX_PERCENT_VALUE = 100;

class CancellationPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    cancellation_policy_logic: yup.string().required(errorMessages.required()),
    cancellation_policy_deadline: yup
      .number(errorMessages.number())
      .nullable()
      .when("cancellation_policy_logic", {
        is: "deadline",
        then: yup
          .number()
          .positive(errorMessages.positive())
          .integer(errorMessages.integer())
          .required(errorMessages.required())
          .typeError(errorMessages.number()),
      }),
    cancellation_policy_deadline_type: yup.string().required(errorMessages.required()),
    cancellation_policy_mode: yup.string().required(errorMessages.required()),
    cancellation_policy_penalty: yup
      .number()
      .nullable()
      .when("cancellation_policy_logic", {
        is: "deadline",
        then: yup
          .number()
          .required(errorMessages.required())
          .typeError(errorMessages.number())
          .when("cancellation_policy_mode", {
            is: "percent",
            then: yup
              .number()
              .positive(errorMessages.positive())
              .max(MAX_PERCENT_VALUE, errorMessages.lessOrEqual(MAX_PERCENT_VALUE))
              .test("isFloat", errorMessages.maxDecimalPlaces(DECIMAL_PART_LENGTH), (value = 0) => {
                const [, decimalPart = ""] = value.toString().split(".");
                return decimalPart.length <= DECIMAL_PART_LENGTH;
              })
              .typeError(errorMessages.number()),
            otherwise: yup
              .number()
              .positive(errorMessages.positive())
              .integer(errorMessages.integer())
              .typeError(errorMessages.number()),
          }),
      }),
  });

  DEFAULT_VALUE = {
    cancellation_policy_logic: "free",
    cancellation_policy_deadline_type: "days",
    cancellation_policy_mode: "percent",
  };

  static FIELDS = [
    "cancellation_policy_logic",
    "cancellation_policy_deadline",
    "cancellation_policy_deadline_type",
    "cancellation_policy_mode",
    "cancellation_policy_penalty",
  ];

  getCancellationTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "free",
        representation: t("policies:cancellation_policy:type_options:free"),
      },
      {
        value: "non_refundable",
        representation: t("policies:cancellation_policy:type_options:non_refundable"),
      },
      {
        value: "deadline",
        representation: t("policies:cancellation_policy:type_options:deadline_based"),
      },
    ];
  };

  getDeadlineOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "days",
        representation: t("policies:cancellation_policy:deadline_options:days"),
      },
      {
        value: "hours",
        representation: t("policies:cancellation_policy:deadline_options:hours"),
      },
    ];
  };

  getModeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "percent",
        representation: t("policies:cancellation_policy:mode_options:percent"),
      },
      {
        value: "nights",
        representation: t("policies:cancellation_policy:mode_options:nights"),
      },
    ];
  };

  getDeadlineUnitSelect = () => {
    const { t } = this.props;

    return (
      <FormikFormSelect
        className={styles.injectedSelect}
        showSearch={false}
        name="cancellation_policy_deadline_type"
        view="horizontal"
        placeholder={t("policies:cancellation_policy:deadline_options_label")}
        options={this.getDeadlineOptions()}
      />
    );
  };

  isCancellationDeadlineBased = ({ cancellation_policy_logic }) => {
    return cancellation_policy_logic && cancellation_policy_logic === "deadline";
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={CancellationPolicyForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormSelect
              name="cancellation_policy_logic"
              view="horizontal"
              placeholder={t("policies:cancellation_policy:type_label")}
              label={t("policies:cancellation_policy:type_label")}
              options={this.getCancellationTypeOptions()}
            />

            {this.isCancellationDeadlineBased(value) && (
              <>
                <FormikFormInput
                  name="cancellation_policy_deadline"
                  addonAfter={this.getDeadlineUnitSelect()}
                  view="horizontal"
                  placeholder={t("policies:cancellation_policy:deadline_label")}
                  label={t("policies:cancellation_policy:deadline_label")}
                />
                <RadioInput
                  name="cancellation_policy_mode"
                  label={t("policies:cancellation_policy:mode_label")}
                  options={this.getModeOptions()}
                />
                <FormikFormInput
                  name="cancellation_policy_penalty"
                  view="horizontal"
                  placeholder={t("policies:cancellation_policy:penalty_label")}
                  label={t("policies:cancellation_policy:penalty_label")}
                />
              </>
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

export default withTranslation()(CancellationPolicyForm);
