import React from "react";
import PropTypes from "prop-types";

import Hidden from "./content/hidden";
import NoReplied from "./content/no_replied";
import Replied from "./content/replied";

function Content({ review }) {
  const {
    attributes: { isHidden, reply },
  } = review;
  let mode;

  switch (`${isHidden},${reply === null}`) {
    case "true,true":
      mode = Hidden;
      break;

    case "true,false":
      mode = Hidden;
      break;

    case "false,false":
      mode = Replied;
      break;

    case "false,true":
      mode = NoReplied;
      break;

    default:
      mode = Replied;
      break;
  }

  return React.createElement(mode, { review });
}

Content.propTypes = {
  review: PropTypes.object,
};

export default Content;
