import { camelCase } from "lodash";

const parseApiErrors = (payload) => {
  const { errors: { details = {} } = {} } = payload;

  if (payload.errors.code === "resource_not_found") {
    return {
      general: "Resource not found",
    };
  }

  if (payload.errors.code === "internal_server_error") {
    return {
      general: "Something went wrong, please try again later",
    };
  }

  return Object.keys(details).reduce((errors, fieldName) => {
    const [errorText] = details[fieldName];

    return {
      ...errors,
      [fieldName]: errorText,
      [camelCase(fieldName)]: errorText,
    };
  }, {});
};

export default parseApiErrors;
