import React from "react";
import { UserOutlined } from "@ant-design/icons";

import styles from "./title.module.css";

export default function RateTitle({ className, rate = {} }) {
  const isRateOccupancyPresent = rate.occupancy !== null && rate.occupancy !== undefined;

  return (
    <span className={className}>
      <span data-cy="rate_title" className={styles.title}>
        {rate.title}
      </span>
      {isRateOccupancyPresent && (
        <>
          &nbsp;
          <UserOutlined />
          &nbsp;
          {rate.occupancy}
        </>
      )}
    </span>
  );
}
