import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";

const { Webhooks } = store;

class WebhooksLogs extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    columnsToHide: 0,
  };

  tableRef = React.createRef();

  loadData = (_query, pagination, order) => {
    const { webhookId } = this.props;

    return Webhooks.logs(webhookId, pagination, order);
  };

  columns = () => {
    const { t, routes } = this.props;

    let dataColumns = [
      {
        title: t("webhook_drawer:logs:columns:inserted_at"),
        dataIndex: "inserted_at",
        key: "inserted_at",
        sorter: false,
        render: (value) => formatToLocal(value, t("formats:date_time_with_seconds")),
      },
      {
        title: t("webhook_drawer:logs:columns:event"),
        dataIndex: "event",
        key: "event",
        sorter: false,
      },
      {
        title: t("webhook_drawer:logs:columns:result"),
        dataIndex: "success",
        key: "success",
        sorter: false,
        render: (value) => {
          return <Badge status={value === true ? "success" : "error"} />;
        },
      },
      {
        title: "",
        key: "action",
        align: "right",
        render: (value) => {
          const link = pathBuilder(routes.userAppRoutes.webhooks.edit.viewWebhookLog, {
            propertyId: value.property_id,
            webhookId: value.webhook_id,
            webhookLogId: value.id,
          });

          return <Link to={link}>{t("webhook_drawer:logs:columns:view")}</Link>;
        },
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return dataColumns;
  };

  emptyMessage() {
    const { t } = this.props;
    return t("webhook_drawer:logs:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  render() {
    const { data, closePath } = this.props;

    return (
      <>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <>
          <CRUDTable
            data={data}
            componentRef={this.tableRef}
            emptyMessage={this.emptyMessage()}
            showCreateMessage={false}
            columns={this.columns}
            loadData={this.loadData}
            topBar={false}
          />

          <Outlet context={{ closePath }} />
        </>
      </>
    );
  }
}

const mapStateToProps = ({ webhook_logs }) => {
  const { entities } = webhook_logs || { entities: null };

  return {
    data: entities ? Object.values(entities) : null,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(WebhooksLogs)));
