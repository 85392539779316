import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { getUserUiSettings } from "store/storage/selectors/session_selector";
import { convertToCamelCase } from "store/utils/case_converter";

import eventTypeOptions from "config/constants/event_type_options";

import { handleOpenEvent } from "pages/dashboard_page/components/live_feed_events_widget/events_list/common";

import BookingViewControlledDrawer from "drawers/booking_view_controlled_drawer";
import ChannelEventControlledDrawer from "drawers/channel_events_view_controlled_drawer";
import LiveFeedEventDrawerControlled from "drawers/live_feed_event_drawer_controlled";

import dayjs from "utils/dayjs";
import EventEmmiter from "utils/event_emmiter";

const EVENT_UNMAPPED_ROOM = "booking_unmapped_room";
const DEFAULT_NOTIFICATION_WIDTH = 450;
const DEFAULT_Z_INDEX_DRAWER = 1001;

function LiveFeedEventsListener() {
  const [eventData, setEventData] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [logOrEventId, setLogOrEventId] = useState(null);
  const [liveFeedEventId, setLiveFeedEventId] = useState(null);
  const uiSettings = useSelector(getUserUiSettings);
  const { showNotifications } = uiSettings;

  const { t } = useTranslation();

  const liveFeedEventCreated = useCallback(({ attributes }) => {
    setEventData(convertToCamelCase(attributes));
  }, []);

  useEffect(() => {
    EventEmmiter.bind("live_feed_event:created", liveFeedEventCreated);

    return () => EventEmmiter.unbind("live_feed_event:created", liveFeedEventCreated);
  }, [liveFeedEventCreated]);

  const resetBookingId = useCallback(() => {
    setEventData(null);
    setBookingId(null);
  }, []);

  const resetLogOrEventId = useCallback(() => {
    setEventData(null);
    setLogOrEventId(null);
  }, []);

  const resetLiveFeedEventId = useCallback(() => {
    setEventData(null);
    setLiveFeedEventId(null);
  }, []);

  const eventTitle = useMemo(() => {
    const findEventOption = eventTypeOptions.find((i) => i === eventData?.event);
    if (findEventOption) {
      return t(`live_feed_events_widget:events:${findEventOption}`);
    }
    return null;
  }, [t, eventData]);

  const isCancellation = useMemo(() => {
    return eventData?.payload.arrivalDate === null && eventData?.payload.countOfRooms === 0;
  }, [eventData]);

  const arrivalDateValue = useMemo(() => {
    const format = t("formats:date_time_with_weekday");
    if (isCancellation) {
      return t("live_feed_events_widget:cancellation");
    }
    if (eventData?.event === EVENT_UNMAPPED_ROOM) {
      dayjs(eventData?.insertedAt).format(format);
    }

    return dayjs(eventData?.payload?.arrivalDate).format(format);
  }, [t, eventData, isCancellation]);

  const description = useMemo(() => {
    const { payload = {} } = eventData || {};

    const { customerName, countOfRooms, countOfNights } = payload;
    const isDescriptionPresent = Boolean(customerName && countOfRooms && countOfNights);

    if (!isDescriptionPresent || isCancellation) {
      return "";
    }

    const nightPostFix = countOfNights > 1 ? "s" : "";
    const roomPostFix = countOfRooms > 1 ? "s" : "";
    return `${customerName} | ${countOfRooms} ${t("live_feed_events_widget:room")}${roomPostFix} x
      ${countOfNights} ${t("live_feed_events_widget:night")}${nightPostFix}`;
  }, [t, eventData, isCancellation]);

  const onOpenBooking = useCallback(() => {
    handleOpenEvent(eventData, setBookingId, setLogOrEventId, setLiveFeedEventId);
    notification.destroy();
  }, [eventData]);

  const openNotification = useCallback(() => {
    notification.open({
      key: eventData?.id,
      message: eventTitle,
      description: `${arrivalDateValue} ${description}`,
      onClick: onOpenBooking,
      style: {
        width: DEFAULT_NOTIFICATION_WIDTH,
        cursor: "pointer",
      },
    });
  }, [eventTitle, arrivalDateValue, description, eventData, onOpenBooking]);

  useEffect(
    function onOpenNotification() {
      if (eventData && showNotifications) {
        openNotification();
      }
    },
    [eventData, showNotifications, openNotification],
  );

  return (
    <>
      <BookingViewControlledDrawer
        bookingId={bookingId}
        visible={!!bookingId}
        onClose={resetBookingId}
        zIndex={DEFAULT_Z_INDEX_DRAWER}
      />
      <ChannelEventControlledDrawer
        actionId={logOrEventId}
        visible={!!logOrEventId}
        onClose={resetLogOrEventId}
        zIndex={DEFAULT_Z_INDEX_DRAWER}
      />
      <LiveFeedEventDrawerControlled
        eventId={liveFeedEventId}
        visible={!!liveFeedEventId}
        onClose={resetLiveFeedEventId}
        zIndex={DEFAULT_Z_INDEX_DRAWER}
      />
    </>
  );
}

export default LiveFeedEventsListener;
