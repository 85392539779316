import { v4 as uuidv4 } from "uuid";

import relationshipsExtractor from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "live_feed";

export default class LiveFeedEvents {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = { inserted_at: "desc" }) {
    const requestId = uuidv4();
    storage.liveFeedSetRequestId(requestId);

    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.liveFeedEventsLoad(response.data, response.meta, filter, requestId);
      return response;
    });
  }

  find(eventId) {
    return transport.send("GET", `${ENDPOINT}/${eventId}`).then(({ data }) => {
      return relationshipsExtractor(data);
    });
  }

  resolve(eventId, value) {
    const payload = {
      resolution: {
        accept: value,
      },
    };

    return transport.send("POST", `${ENDPOINT}/${eventId}/resolve`, payload);
  }

  addEvent(event) {
    storage.liveFeedEventAdd(event);
  }

  reset() {
    return storage.liveFeedEventsReset();
  }
}
