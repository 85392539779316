import appModes from "config/constants/app_modes";

import AppStorageManager from "utils/app_storage_manager";
import getFeatureFlagsConfig from "utils/get_feature_flags_config";

import getSessionParamsFromUrl from "../../utils/get_session_params_from_url";
import {
  CHOOSE_GROUP,
  CHOOSE_PROPERTY,
  SESSION_ADD,
  SET_ACTIVE_FEATURE_FLAGS,
  SET_ALLOWED_CHANNELS,
  SET_APP_MODE,
  SET_APP_SETTINGS,
  SET_UI_SETTINGS,
} from "../constants";

const CACHE_KEY = "CHANNEX_SESSION";

const { appMode, propertyId, groupId, allowedChannels, allowNotificationsEdit, readOnlyAvailability } = getSessionParamsFromUrl();
const activeFeatureFlags = getFeatureFlagsConfig();
const cache = AppStorageManager.getItem(CACHE_KEY);

const activeProperty = appMode !== appModes.HEADLESS ? cache?.activeProperty : propertyId;
const activeGroup = appMode !== appModes.HEADLESS ? cache?.activeGroup : groupId;

const initialState = {
  ...cache,
  appMode,
  activeProperty,
  activeGroup,
  activeFeatureFlags,
  allowedChannels,
  allowNotificationsEdit,
  readOnlyAvailability,
};

const ACTION_HANDLERS = {
  [SESSION_ADD]: (state, action) => {
    const newSession = {
      authToken: action.payload.token,
      refreshToken: action.payload.refresh_token,
    };

    return { ...state, ...newSession };
  },
  [CHOOSE_PROPERTY]: (state, action) => {
    let result = null;

    switch (state) {
      case null:
        result = state;
        break;

      default:
        result = { ...state, activeProperty: action.payload };
        break;
    }

    return result;
  },
  [CHOOSE_GROUP]: (state, action) => {
    let result = null;

    switch (state) {
      case null:
        result = state;
        break;

      default:
        result = { ...state, activeGroup: action.payload };
        break;
    }

    return result;
  },
  [SET_APP_MODE]: (state, action) => {
    return { ...state, appMode: action.payload };
  },
  [SET_APP_SETTINGS]: (state, action) => {
    return { ...state, appSettings: action.payload };
  },
  [SET_ALLOWED_CHANNELS]: (state, action) => {
    return { ...state, allowedChannels: action.payload };
  },
  [SET_UI_SETTINGS]: (state, action) => {
    return { ...state, uiSettings: action.payload };
  },
  [SET_ACTIVE_FEATURE_FLAGS]: (state, action) => {
    return { ...state, activeFeatureFlags: action.payload };
  },
};

export default function sessionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  const updated_state = handler ? handler(state, action) : state;

  AppStorageManager.setItem(CACHE_KEY, updated_state);

  return updated_state;
}
