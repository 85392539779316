import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import styles from "./state.module.css";

export default function State({ isLoading }) {
  if (isLoading) {
    return <Spin indicator={<LoadingOutlined className={styles.loadingIcon} />} />;
  }

  return null;
}
