import TriplaIntegration from "./integrations/tripla";
import AdminApplications from "./admin_applications";
import AdminBillingAccounts from "./admin_billing_accounts";
import AdminProperties from "./admin_properties";
import AdminUsers from "./admin_users";
import AirbnbRatePlans from "./airbnb_rate_plans";
import App from "./app";
import Applications from "./applications";
import ARI from "./ari";
import Auth from "./auth";
import BillingAccounts from "./billing_accounts";
import Bookings from "./bookings";
import CancellationPolicies from "./cancellation_policies";
import ChannelEvents from "./channel_events";
import ChannelMessagesThreads from "./channel_messages_threads";
import Channels from "./channels";
import CustomAvailabilityOffsets from "./custom_availability_offsets";
import CustomClosedToArrivals from "./custom_closed_to_arrivals";
import CustomClosedToDepartures from "./custom_closed_to_departures";
import CustomDerivedOptions from "./custom_derived_options";
import CustomMaxAvailabilities from "./custom_max_availabilities";
import CustomMaxSells from "./custom_max_sells";
import CustomMaxStays from "./custom_max_stays";
import CustomMinStayArrivals from "./custom_min_stay_arrivals";
import CustomMinStayThroughs from "./custom_min_stay_throughs";
import CustomRates from "./custom_rates";
import CustomStopSells from "./custom_stop_sells";
import EmailTemplates from "./email_templates";
import Geocoding from "./geocoding";
import GroupUsers from "./group_users";
import Groups from "./groups";
import HotelPolicies from "./hotel_policies";
import LiveFeedEvents from "./live_feed_events";
import Onboarding from "./onboarding";
import PCICards from "./pci_cards";
import Profiles from "./profiles";
import Properties from "./properties";
import PropertyFacilities from "./property_facilities";
import PropertyUsers from "./property_users";
import RateCategories from "./rate_categories";
import RatePlans from "./rate_plans";
import Raw from "./raw";
import Reviews from "./reviews";
import RoomFacilities from "./room_facilities";
import RoomSpaces from "./room_spaces";
import RoomTypes from "./room_types";
import Scores from "./scores";
import StateChanges from "./state_changes";
import Tasks from "./tasks";
import TaxSets from "./tax_sets";
import Taxes from "./taxes";
import UserApiKeys from "./user_api_keys";
import Webhooks from "./webhooks";
import WhiteLabelDomains from "./white_label_domains";
import WhiteLabelEmailSettings from "./white_label_email_settings";
import WhiteLabelPartners from "./white_label_partners";

export default {
  ARI,
  AdminApplications,
  AdminBillingAccounts,
  AdminProperties,
  AdminUsers,
  AirbnbRatePlans,
  App,
  Applications,
  Auth,
  BillingAccounts,
  Bookings,
  CancellationPolicies,
  ChannelEvents,
  ChannelMessagesThreads,
  Channels,
  CustomAvailabilityOffsets,
  CustomClosedToArrivals,
  CustomClosedToDepartures,
  CustomDerivedOptions,
  CustomMaxAvailabilities,
  CustomMaxSells,
  CustomMaxStays,
  CustomMinStayArrivals,
  CustomMinStayThroughs,
  CustomRates,
  CustomStopSells,
  EmailTemplates,
  Geocoding,
  GroupUsers,
  Groups,
  HotelPolicies,
  LiveFeedEvents,
  Onboarding,
  PCICards,
  Profiles,
  Properties,
  PropertyFacilities,
  PropertyUsers,
  RateCategories,
  RatePlans,
  Raw,
  Reviews,
  RoomFacilities,
  RoomSpaces,
  RoomTypes,
  Scores,
  StateChanges,
  Tasks,
  TaxSets,
  Taxes,
  TriplaIntegration,
  UserApiKeys,
  Webhooks,
  WhiteLabelDomains,
  WhiteLabelEmailSettings,
  WhiteLabelPartners,
};
