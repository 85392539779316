import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Typography } from "antd";
import _ from "lodash";
import store from "store";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/form_input";
import Loading from "components/loading";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import styles from "./shared.module.css";

const { Applications } = store;

const buildSettings = (roomTypes, ratePlans, installation) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    if (!acc[roomType.id]) {
      acc[roomType.id] = {
        code: roomType.id,
        title: roomType.title,
        ratePlans: {},
      };
    }

    acc[roomType.id].ratePlans = _.reduce(ratePlans[roomType.id], (accRP, ratePlan) => {
      if (!accRP[ratePlan.id]) {
        accRP[ratePlan.id] = {
          code: ratePlan.id,
          title: ratePlan.title,
          settings: {
            occupancy: ratePlan.occupancy,
            meal_type: ratePlan.meal_type || "none",
          },
        };
      }

      return accRP;
    }, acc[roomType.id].ratePlans);

    return acc;
  }, installation.settings || { hotelCode: null });
};

const buildFieldNames = (roomTypes, ratePlans) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    acc.push(`rooms.${roomType.id}.code`);

    _.forEach(ratePlans[roomType.id], (ratePlan) => {
      acc.push(`rooms.${roomType.id}.ratePlans.${ratePlan.id}.code`);
    });

    return acc;
  }, ["hotelCode"]);
};

export default function ShalomSettings({ installation, onClose }) {
  const { propertyId } = installation;

  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    setError,
    control,
  } = useForm();

  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, groupResults: true });
  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return;
    }

    const initialSettings = buildSettings(roomTypes, ratePlans, installation);

    reset(initialSettings);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading, installation, reset]);

  const formFieldNames = useMemo(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return [];
    }

    return buildFieldNames(roomTypes, ratePlans);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading]);

  const submit = (values) => {
    setSaveInProgress(true);

    Applications
      .update({ ...installation, settings: values })
      .then(onClose)
      .catch((error) => {
        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, formFieldNames);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, value);
        });
      })
      .finally(() => setSaveInProgress(false));
  };

  if (isRatePlansLoading || isRoomTypesLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors.global} />

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:busy_rooms:hotel_code")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.inputCol}>
            <Controller
              name="hotelCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  hookForm
                  view="fullWidth"
                  placeholder={t("applications_page:busy_rooms:hotel_code")}
                  errors={errors.hotelCode}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>

        <Row className={styles.columnHeaders}>
          <Col span={11}>{t("applications_page:busy_rooms:channel_manager")}</Col>
          <Col span={1}>&rarr;</Col>
          <Col span={12}>{t("applications_page:busy_rooms:busy_rooms")}</Col>
        </Row>

        {roomTypes.map((roomType) => (
          <div key={roomType.id}>
            <Row>
              <Col span={11} className={styles.roomTitle}>
                {roomType.title}
              </Col>
              <Col span={1} className={styles.arrow}>
                &rarr;
              </Col>
              <Col span={12} className={styles.inputCol}>
                <Controller
                  name={`${roomType.id}.code`}
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      hookForm
                      view="fullWidth"
                      placeholder={t("applications_page:busy_rooms:room_type_code")}
                      errors={errors[`${roomType.id}.code`]}
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>

            {!ratePlans[roomType.id] && <Typography.Text type="secondary">No Rate Plan</Typography.Text>}

            {ratePlans[roomType.id] && ratePlans[roomType.id].map((ratePlan) => (
              <Row key={ratePlan.id}>
                <Col span={11} className={styles.rateTitle}>
                  {ratePlan.title} {ratePlan.occupancy}
                </Col>
                <Col span={1} className={styles.arrow}>
                  &rarr;
                </Col>
                <Col span={12} className={styles.inputCol}>
                  <Controller
                    name={`${roomType.id}.ratePlans.${ratePlan.id}.code`}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        hookForm
                        view="fullWidth"
                        placeholder={t("applications_page:busy_rooms:rate_plan_code")}
                        errors={errors[`${roomType.id}.ratePlans.${ratePlan.id}.code`]}
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Row>
            ))}
          </div>
        ))}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={() => { clearErrors(); handleSubmit(submit)(); }} loading={saveInProgress}>
          {t("applications_page:busy_rooms:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
