import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import store from "store";

const { TaxSets } = store;

const DEFAULT_PRICE = "100";
const DEBOUNCE_TIME = 500;

const withLogic = (FormComponent) => {
  class HOC extends Component {
    state = {
      results: null,
      price: DEFAULT_PRICE,
      lastTaxesValue: "",
      loading: false,
      errors: null,
    };

    onPriceChangeCallback = _.debounce(() => {
      const { value } = this.props;
      const { taxes, currency } = value;

      if (taxes.length && currency) {
        this.calculate(taxes, currency);
      }
    }, DEBOUNCE_TIME);

    handlePriceChange = (price) => {
      this.setState({ price }, () => this.onPriceChangeCallback());
    };

    calculate = (taxes, currency) => {
      const { t } = this.props;
      const { price: raw_price } = this.state;

      this.setState({ loading: true });

      TaxSets.test({ taxes, raw_price, currency })
        .then((results) => this.setState({
          loading: false,
          results,
          errors: null,
        }))
        .catch(() => this.setState({
          loading: false,
          results: {},
          errors: [t("taxes:tax_set:form:tax_calculator:calculation_error")],
        }));
    };

    onChange = (newValue) => {
      const { lastTaxesValue } = this.state;
      const { value, onChange } = this.props;

      const { taxes, currency } = newValue;
      const { currency: oldCurrency } = value;

      const stringifiedTaxes = JSON.stringify(taxes);
      const hasTaxes = taxes.length;
      const dataChanged = stringifiedTaxes !== lastTaxesValue || currency !== oldCurrency;

      if (currency && hasTaxes && dataChanged) {
        this.onPriceChangeCallback.cancel();

        this.calculate(taxes, currency);

        this.setState({ lastTaxesValue: stringifiedTaxes });
      }

      onChange(newValue);
    };

    render() {
      const { price, results, loading, errors } = this.state;
      const {
        value: { currency },
      } = this.props;

      return (
        <FormComponent
          {...this.props}
          taxSetCalculatorProps={{
            price,
            errors,
            loading,
            results,
            currency,
            handlePriceChange: this.handlePriceChange,
          }}
          onChange={this.onChange}
        />
      );
    }
  }

  return withTranslation()(HOC);
};

export default withLogic;
