const updateMappedRatesReadonlyStatus = (mappedRates, externalRatesPerRoom) => {
  const updatedMappedRates = mappedRates.map((mappedRate) => {
    if (!mappedRate) {
      return mappedRate;
    }

    const { [mappedRate.room_type_code]: roomRelatedRates = [] } = externalRatesPerRoom;

    const originalRate = roomRelatedRates.find(
      (rate) => mappedRate.rate_plan_code === rate.id && mappedRate.occupancy === rate.occupancy,
    );

    if (!originalRate) {
      return mappedRate;
    }

    return { ...mappedRate, readonly: originalRate.readonly };
  });

  return updatedMappedRates;
};

const updateReadonlyStatuses = (mappings, externalRatesPerRoom) => {
  const updatedMappings = Object.keys(mappings).reduce((acc, key) => {
    const mappedRates = mappings[key];

    if (!Array.isArray(mappedRates)) {
      return { ...acc, [key]: mappedRates };
    }

    const updatedMappedRates = updateMappedRatesReadonlyStatus(mappedRates, externalRatesPerRoom);

    return { ...acc, [key]: updatedMappedRates };
  }, {});

  return updatedMappings;
};

export default updateReadonlyStatuses;
