import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import classnames from "classnames";
import _ from "lodash";

import MappingDirection from "components/mapping/mapping_direction";
import NoDataPlaceholder from "components/no_data_placeholder";

import transformMappingsToArray from "../../utils/transform_mappings_to_array";

import MappingRow from "./components/reverse_mapping_row";
import filterFlatTree from "./filter_flat_tree";

import styles from "./reverse_mapping.module.css";

const BASE_ITEM_SIZE = 28;

let listingRatesTree = {};

class ReverseMapping extends Component {
  state = {
    mappingPopup: {
      mappingDefinition: {},
      visible: "",
      rate: null,
      conflictValue: null,
    },
    mappingList: [],
    filteredMappingList: [],
  };

  cache = new CellMeasurerCache({
    defaultHeight: BASE_ITEM_SIZE,
    fixedWidth: true,
    minHeight: BASE_ITEM_SIZE,
  });

  componentDidMount() {
    this.buildMappingList();
  }

  componentDidUpdate({ orderedMappingSchema, mappingOptions, mappings, searchQuery }) {
    const {
      orderedMappingSchema: updatedMappingSchema,
      mappings: updatedMappings,
      mappingOptions: updatedMappingOptions,
      searchQuery: updatedSearchQuery,
    } = this.props;

    const mappingSchemaUpdated = !_.isEqual(orderedMappingSchema, updatedMappingSchema);
    const mappingsUpdated = updatedMappings !== mappings;
    const mappingOptionsUpdated = mappingOptions !== updatedMappingOptions;

    if (mappingSchemaUpdated || mappingsUpdated || mappingOptionsUpdated) {
      this.buildMappingList();
      listingRatesTree = {};
    }

    if (searchQuery !== updatedSearchQuery) {
      this.handleSearchChange(updatedSearchQuery);
    }
  }

  buildKey = (mappingDefinition) => {
    return _.reduce(mappingDefinition, (acc, value, key) => `${acc}_${key}_${value}`, "");
  };

  handleRateChange = (ids) => {
    const { ratePlans } = this.props;

    const rateId = ids.pop();
    const rate = ratePlans.find((rp) => rp.id === rateId);

    this.setState(
      ({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          rate,
        },
      }),
      this.updateList,
    );
  };

  openMappingDialog = (mappingDefinition, mappedRate) => {
    const key = this.buildKey(mappingDefinition);

    this.setState(
      {
        mappingPopup: {
          mappingDefinition,
          visible: key,
          prevRate: mappedRate,
          rate: mappedRate,
        },
      },
      this.updateList,
    );
  };

  groupConflictingMappings = (rates) => {
    const groupedRates = rates.reduce((acc, rate) => {
      const rateGroupId = `${rate.parent_rate_plan_id}${rate.property_id}${rate.room_type_id}`;

      const rateGroup = acc[rateGroupId] || [];
      rateGroup.push(rate);
      acc[rateGroupId] = rateGroup;

      return acc;
    }, {});

    return groupedRates;
  };

  openResolveConflictDialog = (mappingDefinition, mappedRates) => {
    const key = this.buildKey(mappingDefinition);
    const groupedRates = this.groupConflictingMappings(mappedRates);
    const conflictValue = Object.keys(groupedRates)[0];

    this.setState(
      {
        mappingPopup: {
          mappingDefinition,
          visible: key,
          prevRate: mappedRates,
          rate: mappedRates,
          groupedRates,
          conflictValue,
        },
      },
      this.updateList,
    );
  };

  closePopup = () => {
    this.setState(
      ({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          visible: "",
        },
      }),
      this.updateList,
    );

    // when popup closes there is an animation duration which shows form
    // we need to delay form values erase by that animation duration to prevent form being empty while popup is still closing
    setTimeout(() => {
      this.setState(({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          mappingDefinition: {},
          rate: null,
        },
      }));
    }, 200);
  };

  handleApplyConflictResolve = () => {
    const { onMappingDelete } = this.props;
    const { mappingPopup } = this.state;
    const { groupedRates, conflictValue } = mappingPopup;

    const { [conflictValue]: _resolvedGroup, ...groupsToRemove } = groupedRates;

    const deleteRequests = Object.keys(groupsToRemove).reduce((acc, groupId) => {
      const requestsPerGroup = groupsToRemove[groupId].map((mappedRate) => onMappingDelete(mappedRate.mappingId));

      return [...acc, ...requestsPerGroup];
    }, []);

    this.closePopup();
    return Promise.all(deleteRequests);
  };

  handleApplyMapping = () => {
    const { onMappingCreate, onMappingDelete } = this.props;
    const { mappingPopup } = this.state;

    if (!mappingPopup.rate) {
      return this.closePopup();
    }

    const prevRate = mappingPopup.prevRate;
    const rateId = mappingPopup.rate.id;
    const mappingDefinition = mappingPopup.mappingDefinition;
    const newMapping = {
      rate_plan_id: rateId,
      settings: mappingDefinition,
    };

    this.closePopup();

    if (prevRate) {
      return onMappingDelete(prevRate.mappingId).then(() => onMappingCreate(newMapping));
    }

    return onMappingCreate(newMapping);
  };

  handleConflictFormChange = (e) => {
    this.setState(
      ({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          conflictValue: e.target.value,
        },
      }),
      this.updateList,
    );
  };

  deleteMapping = (mappingDefinition) => {
    const { mappings, onMappingDelete } = this.props;

    const mappingConfigurations = Object.values(mappings);
    const matchedMappingConfigurations = mappingConfigurations.reduce((acc, mappingConf) => {
      mappingConf = Array.isArray(mappingConf) ? mappingConf : [mappingConf];

      const matchedMappings = mappingConf.filter((configuration) => {
        const mapping = _.omit(configuration, ["id"]);

        return _.isEqual(mapping, mappingDefinition);
      });

      return [...acc, ...matchedMappings];
    }, []);

    const mappingDeleteRequests = matchedMappingConfigurations
      .map((mapping) => onMappingDelete(mapping.id));

    return Promise.all(mappingDeleteRequests);
  };

  handleSearchChange = (searchQuery) => {
    const { mappingList } = this.state;
    const filteredMappingList = this.applyFilter(searchQuery, mappingList);

    this.setState({ filteredMappingList }, this.updateList);
  };

  applyFilter = (searchQuery, target) => {
    return filterFlatTree(target, searchQuery);
  };

  buildListingRatesTree = (listingId, levelRatesTree) => {
    const { filteredMappingList } = this.state;

    const listingMapping = filteredMappingList.find((mapping) => mapping.id === listingId);
    const mappedRate = listingMapping?.mappedRates[0];

    if (!mappedRate) {
      return levelRatesTree;
    }

    const mappedRoomTypeId = mappedRate.room_type_id;
    const mappedPropertyId = mappedRate.property_id;

    const levelProperty = levelRatesTree.find((property) => property.id === mappedPropertyId);
    const levelRoom = levelProperty.children.find((room) => room.id === mappedRoomTypeId);

    return [{
      ...levelProperty,
      children: [levelRoom],
    }];
  };

  getItem = ({ index, key, parent, style }) => {
    const { channel, ratesTree, mappings, mappingOptions } = this.props;
    const { filteredMappingList, mappingPopup } = this.state;

    const mapping = filteredMappingList[index];

    const className = index ? null : styles.firstElement;

    if (mapping.level > 1) {
      // on 1 mapping level we should filter available for mapping rate plans by mapping information in listing
      // rate plans can be mapped only to rate plans in room which is assosiated with mapped rate plan on listing level
      throw new Error("Unsupported mapping level, current implementation assumes level 0 is listing and level 1 is airbnb rate plans");
    }

    let levelRatesTree = ratesTree;

    // isListingProcessing is set when mapping row is rendered for airbnb rate plan and its parent listing
    // have isProcessing state, to prevent multiple mapping operations on the same listing
    let isListingProcessing = false;

    if (mapping.level === 1) {
      const listingId = mapping.mappingDefinition.listing_id;

      levelRatesTree = listingRatesTree[listingId] || this.buildListingRatesTree(listingId, levelRatesTree);

      const listing = mappingOptions.listing_id_dictionary.values.find((el) => el.id === listingId);
      isListingProcessing = listing?.isProcessing;
    }

    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ registerChild }) => (
          // 'style' attribute required to position cell (within parent List)
          <div ref={registerChild} style={style} className={className}>
            <MappingRow
              mapping={mapping}
              mappingPopupData={mappingPopup}
              channel={channel}
              ratesTree={levelRatesTree}
              mappings={mappings}
              isParentProcessing={isListingProcessing}
              handleRateChange={this.handleRateChange}
              handleOpenResolveConflictDialog={this.openResolveConflictDialog}
              handleOpenMappingDialog={this.openMappingDialog}
              handleDeleteMapping={this.deleteMapping}
              handleConflictFormChange={this.handleConflictFormChange}
              handleApplyMapping={this.handleApplyMapping}
              handleApplyConflictResolve={this.handleApplyConflictResolve}
              handleClosePopup={this.closePopup}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  updateList = () => {
    const { current } = this.listRef;

    if (current) {
      this.cache.clearAll();
      current.forceUpdateGrid();
    }
  };

  buildMappingList = () => {
    const { mappingOptions, orderedMappingSchema, searchQuery } = this.props;
    const mappingList = [];

    this.fillMappingList(mappingList, orderedMappingSchema, mappingOptions);

    const filteredMappingList = this.applyFilter(searchQuery, mappingList);

    this.setState({ mappingList, filteredMappingList }, this.updateList);
  };

  fillMappingList = (
    mappingList,
    orderedMappingSchema,
    mappingOptions = {},
    isParentDeleted = false,
    level = 0,
    mappingDefinition = {},
  ) => {
    const mappingParam = orderedMappingSchema[level];

    if (!mappingParam) {
      return;
    }

    const { id } = mappingParam;
    const hasChildren = Boolean(orderedMappingSchema[level + 1]);

    const mappingDictionary = mappingOptions[`${id}_dictionary`];
    const mappingValues = mappingDictionary ? mappingDictionary.values : [];

    for (let i = 0; i < mappingValues.length; i++) {
      const mapping = mappingValues[i];

      mappingDefinition = {
        ...mappingDefinition,
        [id]: mapping.id,
      };

      const mappedRates = this.getMappings(mappingDefinition);
      const mappingKey = this.buildKey(mappingDefinition);
      const mappedRatesCount = mappedRates.length;
      const mappedRatesOffsetMultiplier = mappedRatesCount && !hasChildren ? mappedRatesCount - 1 : 0;

      const nodeDeleted = isParentDeleted || mapping.isDeleted;
      const hasNoMappedRates = !mappedRatesCount || !this.isSubLevelHaveMappings(mappingDefinition);

      if (nodeDeleted && hasNoMappedRates) {
        // eslint-disable-next-line no-continue
        continue;
      }

      mappingList.push({
        ...mapping,
        level,
        hasChildren,
        mappedRates,
        isParentDeleted,
        mappingKey,
        mappingDefinition,
        mappedRatesOffsetMultiplier,
      });

      if (mappedRates.length !== 0) {
        this.fillMappingList(
          mappingList,
          orderedMappingSchema,
          mappingOptions,
          nodeDeleted,
          level + 1,
          mappingDefinition,
        );
      }
    }
  };

  isSubLevelHaveMappings = (mappingDefinition) => {
    const { mappings } = this.props;

    const filteredMappings = _.filter(mappings, _.matches(mappingDefinition));

    return !_.isEmpty(filteredMappings);
  };

  // returns one mapped rate plan + mapping id according to mapping definition
  // returned as array for compatibility with existing code, not sure if multiple mapped rates supported in airbnb, but existing
  // solution doesn't support changing rate plan for multiple mapped rates in UI
  getMappings = (mappingDefinition) => {
    const { ratePlans, mappings } = this.props;

    const ratePlansAsArray = transformMappingsToArray(mappings);

    const mappedRate = ratePlansAsArray.find((ratePlan) => {
      return _.isEqual(ratePlan.mapping, mappingDefinition);
    });

    if (!mappedRate) {
      return [];
    }

    const mappedRatePlan = ratePlans.find((ratePlan) => ratePlan.id === mappedRate.ratePlanId);

    return [{
      ...mappedRatePlan,
      mappingId: mappedRate.mappingId,
    }];
  };

  listRef = React.createRef();

  render() {
    const { t, channelTitle, mappingSettings, blockedListings } = this.props;
    const { mappingList, filteredMappingList } = this.state;
    const hasBlockedListings = blockedListings.length;
    const totalItemsCount = mappingList.length + hasBlockedListings;
    let { reverseMapping } = mappingSettings;

    if (channelTitle === "Airbnb" && reverseMapping === undefined) {
      reverseMapping = true;
    }

    if (!totalItemsCount) {
      return (
        <NoDataPlaceholder emptyMessage={t("channels_page:form:no_mapping_data_placeholder")} />
      );
    }

    const containerClassName = classnames(
      styles.virtualListContainer,
      hasBlockedListings && styles.virtualListContainerShort,
    );

    return (
      <div className={containerClassName}>
        <MappingDirection channelTitle={channelTitle} reverse={reverseMapping} />
        <div className={styles.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                className={styles.listInnerContainer}
                height={height}
                width={width}
                rowCount={filteredMappingList.length}
                ref={this.listRef}
                deferredMeasurementCache={this.cache}
                rowHeight={this.cache.rowHeight}
                rowRenderer={this.getItem}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ReverseMapping);
