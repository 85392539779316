import React from "react";
import classnames from "classnames";

import styles from "./icon_placeholder.module.css";

export default function IconPlaceholder({ Icon, text, className, dataCy }) {
  const contentClassName = classnames(styles.content, className);

  return (
    <div className={contentClassName} data-cy={dataCy}>
      <Icon className={styles.icon} />
      <br />
      {text}
    </div>
  );
}
