import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import RangePicker from "components/mobile/inventory/range_picker";

import style from "./title.module.css";

function Title({ dateFrom = null, dateTo = null, handleSelectDate }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={style.header__inner}>
        <div>{t("booking_sources_widget:header")}</div>

        <div>
          <RangePicker value={[dateFrom, dateTo]} onChange={handleSelectDate} />
        </div>
      </div>
    </div>
  );
}

Title.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
};

export default Title;
