import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { SendOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { Form, Formik } from "formik";

import FormikTextareaInput from "components/forms/inputs/formik/form_textarea";

import styles from "./chat.module.css";

const initialValues = {
  message: "",
};

const autosizeTextArea = {
  minRows: 1,
  maxRows: 10,
};

function ChatForm({ onSubmit, disabled }) {
  const { t } = useTranslation();

  const submit = (values, resetForm) => {
    if (values.message && !disabled) {
      onSubmit(values.message);
      resetForm();
    }
  };

  const onKeyPress = (event, callback) => {
    if (event.charCode === 13 && event.ctrlKey) {
      callback();
    }
  };

  const formClassName = classNames(styles.formWrapper, {
    [`${styles.formWrapper__disabled}`]: disabled,
  });

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        submit(values, resetForm);
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <Form className={formClassName}>
          <FormikTextareaInput
            name="message"
            className={styles.formTextArea}
            view="fullWidth"
            autosize={autosizeTextArea}
            onKeyPress={(e) => onKeyPress(e, handleSubmit)}
            placeholder={t("messages_page:dialog:form_placeholder")}
          />

          <SendOutlined onClick={handleSubmit} className={styles.formSendBtn} />
        </Form>
      )}
    </Formik>
  );
}

ChatForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ChatForm;
