import { useMemo, useState } from "react";

export default function useFilteredData(collection = [], { searchItem }) {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = useMemo(() => {
    if (!collection) {
      return [];
    }

    return collection.filter((item) => searchItem(item).includes(searchQuery));
  }, [collection, searchQuery, searchItem]);

  return {
    filteredData,
    searchQuery,
    setSearchQuery,
  };
}
