import React, { Component } from "react";
import { Form, Input } from "antd";

import getFieldLayout from "./utils/get_field_layout";

import styles from "./inputs.module.css";

const DEFAULT_AUTOSIZE = { minRows: 5, maxRows: 10 };
const DEFAULT_VIEW = "horizontal";

class FormTextarea extends Component {
  handleChange = (e) => {
    const { onChange, name } = this.props;
    onChange(name, e.target.value);
  };

  validation = (errors) => {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join ? errors.join(" ") : errors,
      };
    }
    return {};
  };

  render() {
    const {
      view = DEFAULT_VIEW,
      label,
      extra,
      errors,
      className,
      autosize = DEFAULT_AUTOSIZE,
      inputRef,
      ...rest
    } = this.props;

    const itemProps = {
      label,
      extra,
      className,
    };

    const formItemLayout = getFieldLayout(view);
    const formItemErrors = this.validation(errors);

    return (
      <Form.Item
        {...itemProps}
        {...formItemLayout}
        {...formItemErrors}
        data-cy={`textarea_${this.props.name}`}
      >
        <Input.TextArea
          {...rest}
          autoSize={autosize}
          className={styles.input}
          ref={inputRef}
          onChange={this.handleChange}
        />
      </Form.Item>
    );
  }
}

export default FormTextarea;
