import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { Modal } from "antd";
import store from "store";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

const { RoomSpaces } = store;

export default function useRoomSpacesRemoveMutation(roomTypeId) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const mutation = useMutation((roomSpaceId) => RoomSpaces.remove(roomTypeId, roomSpaceId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries("room_spaces");
    },
  });

  return {
    ...mutation,
    mutate: (id) => {
      Modal.confirm({
        title: t("general:remove_dialog:title"),
        content: t("general:remove_dialog:description"),
        onOk: async () => {
          try {
            await mutation.mutate(id);
          } catch (error) {
            showErrorMessageFromResponse(error);
          }
        },
      });
    },
  };
}
