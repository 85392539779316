import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import store from "store";

import addDeletedOptionsInMappings from "components/channel_management/components/mapping/utils/add_deleted_options_in_mappings";
import buildRatesTree from "components/channel_management/components/mapping/utils/build_rates_tree";
import RefreshButton from "components/forms/buttons/refresh_button";
import InputSearch from "components/forms/items/input_search";
import FormMessage from "components/forms/message/form_message";
import Layouts from "components/layouts";

import handleActionError from "utils/handle_action_error";
import useBoolState from "utils/use_bool_state";

import KnownMappings from "../known_mappings";

import BlockedListings from "./components/blocked_listings";
import ReverseMapping from "./components/reverse_mapping/reverse_mapping";
import transformMappingsToArray from "./utils/transform_mappings_to_array";

const { Channels, subscribe } = store;

const DEFAULT_MAPPING_SETTINGS = {};

const getListings = (mappingOptions) => mappingOptions?.listing_id_dictionary?.values || [];

const getMappedListingIds = (mappings) => {
  const mappedListingIds = transformMappingsToArray(mappings)
    .map((mapping) => mapping.mapping.listing_id);

  return _.uniq(mappedListingIds);
};

const getUnmappedListings = (mappingOptions, mappedListingIds) => {
  const listings = getListings(mappingOptions);

  return listings.filter((listing) => !mappedListingIds.includes(listing.id));
};

const getAvailableMappingOptions = (mappingOptions, unavailableListings) => {
  const listings = getListings(mappingOptions);

  const availableListings = listings.filter(
    ({ id }) => !unavailableListings.find((listing) => listing.id === id),
  );

  return {
    ...mappingOptions,
    listing_id_dictionary: { values: availableListings },
  };
};

const resetListingProcessingStatus = (mappingOptions, listingId, isExactListingProcessing) => {
  const listings = getListings(mappingOptions);

  const updatedListings = listings.map((listing) => {
    if (listing.id !== listingId) {
      return listing;
    }

    const updatedListing = _.cloneDeep(listing);
    updatedListing.synchronization_category = null;

    if (updatedListing.isExactListingProcessing) {
      if (isExactListingProcessing) {
        delete updatedListing.isExactListingProcessing;
        delete updatedListing.isProcessing;
      }
    } else {
      delete updatedListing.isProcessing;
    }

    return updatedListing;
  });

  const updatedMappingOptions = _.cloneDeep(mappingOptions);
  updatedMappingOptions.listing_id_dictionary.values = updatedListings;

  return updatedMappingOptions;
};

const setListingProcessingStatus = (mappingOptions, listingId, isExactListingProcessing) => {
  const listings = getListings(mappingOptions);

  const updatedListings = listings.map((listing) => {
    if (listing.id !== listingId) {
      return listing;
    }

    return {
      ...listing,
      isProcessing: true,
      isExactListingProcessing,
    };
  });

  const updatedMappingOptions = _.cloneDeep(mappingOptions);
  updatedMappingOptions.listing_id_dictionary.values = updatedListings;

  return updatedMappingOptions;
};

export default function AirbnbMapping(props) {
  const { t } = useTranslation();
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const {
    form,
    mappingStatus,
    ratePlans,
    roomTypes,
    mappingOptions,
    channelTitle,
    properties,
    onChangeMapping,
    onRefresh,
    onMappingOptionsChange,
    onKnownMappingsDelete,
  } = props;
  const mappingSettings = props.mappingSettings || DEFAULT_MAPPING_SETTINGS;
  const { orderedMappingSchema } = mappingSettings;
  const { values, setFieldValue } = form;
  const {
    mappings,
    known_mappings_list: knownMappings,
    properties: selectedProperties,
    id: channelId,
    rate_plans: mappedRatePlans,
  } = values;

  const mappingsRef = useRef(mappings);
  useEffect(() => { mappingsRef.current = mappings; }, [mappings]);

  const mappedRatePlansRef = useRef(mappedRatePlans);
  useEffect(() => { mappedRatePlansRef.current = mappedRatePlans; }, [mappedRatePlans]);

  const mappingOptionsRef = useRef(mappingOptions);
  useEffect(() => { mappingOptionsRef.current = mappingOptions; }, [mappingOptions]);

  const ratesTree = useMemo(() => {
    return buildRatesTree(form, properties, roomTypes, ratePlans);
  }, [form, properties, roomTypes, ratePlans]);

  const mappingOptionsWithDeleted = useMemo(() => {
    return addDeletedOptionsInMappings(orderedMappingSchema, mappings, mappingOptions);
  }, [orderedMappingSchema, mappings, mappingOptions]);

  const blockedListings = useMemo(() => {
    const mappedListingIds = getMappedListingIds(form.values.mappings);
    const unmappedListings = getUnmappedListings(mappingOptionsWithDeleted, mappedListingIds);

    return unmappedListings.filter((listing) => Boolean(listing?.synchronization_category));
  }, [mappingOptionsWithDeleted, form.values.mappings]);

  const availableMappingOptions = useMemo(() => {
    return getAvailableMappingOptions(mappingOptionsWithDeleted, blockedListings);
  }, [mappingOptionsWithDeleted, blockedListings]);

  const handleRefresh = useCallback(() => {
    setLoading();

    return onRefresh().finally(setLoadingComplete);
  }, [setLoading, setLoadingComplete, onRefresh]);

  const removeMappingById = (mappingId) => {
    const currentMappings = mappingsRef.current;

    const updatedMappings = Object.fromEntries(
      Object.entries(currentMappings).reduce((acc, [ratePlanId, mapping]) => {
        if (Array.isArray(mapping)) {
          const updatedMapping = mapping.filter(({ id }) => id !== mappingId);

          if (updatedMapping.length) {
            return [...acc, [ratePlanId, updatedMapping]];
          }

          return acc;
        }
        if (mapping.id !== mappingId) {
          return [...acc, [ratePlanId, mapping]];
        }

        return acc;
      }, []),
    );

    onChangeMapping(updatedMappings);
  };

  const processSuccessMappingCreate = (response, createdMapping) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);
    const existingMappingForDefinition = mappingsAsArray.find((item) => _.isEqual(item.mapping, createdMapping.settings));

    let updatedMappings = { ...mappings };

    if (existingMappingForDefinition) {
      // delete existing mapping configuration
      updatedMappings = Object.fromEntries(
        Object.entries(updatedMappings).reduce((acc, [ratePlanId, existingMapping]) => {
          if (Array.isArray(existingMapping)) {
            const updatedMapping = existingMapping.filter((item) => {
              const mappingDefinition = _.omit(item, "id");
              return _.isEqual(mappingDefinition, createdMapping.settings);
            });

            if (updatedMapping.length) {
              return [...acc, [ratePlanId, updatedMapping]];
            }

            return acc;
          }
          const mappingDefinition = _.omit(existingMapping, "id");

          if (_.isEqual(mappingDefinition, createdMapping.settings)) {
            return acc;
          }
          return [...acc, [ratePlanId, existingMapping]];
        }, []),
      );
    }

    // add new mapping
    const newMapping = { ...createdMapping.settings, id: response.id };

    let existingMappingForRatePlan = updatedMappings[createdMapping.rate_plan_id];

    if (existingMappingForRatePlan) {
      existingMappingForRatePlan = Array.isArray(existingMappingForRatePlan) ? existingMappingForRatePlan : [existingMappingForRatePlan];

      updatedMappings = {
        ...updatedMappings,
        [createdMapping.rate_plan_id]: [
          ...existingMappingForRatePlan,
          newMapping,
        ],
      };
    } else {
      updatedMappings = { ...updatedMappings, [createdMapping.rate_plan_id]: [newMapping] };
    }

    onChangeMapping(updatedMappings);
  };

  const updateChannelRatePlans = (response, mapping) => {
    let updatedRateList = [...mappedRatePlansRef.current];

    if (mapping) {
      const newRatePlan = {
        ...response,
        rate_plan_id: mapping.rate_plan_id,
      };

      updatedRateList.push(newRatePlan);
    } else {
      updatedRateList = updatedRateList.filter((item) => item.id !== response.id);
    }

    setFieldValue("rate_plans", updatedRateList);
  };

  const handleMappingCreate = (mapping) => {
    return Channels.createMapping(channelId, mapping)
      .then((response) => {
        processSuccessMappingCreate(response, mapping);
        updateChannelRatePlans(response, mapping);
      })
      .catch(handleActionError);
  };

  const handleMappingRemovedEvent = (payload) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);

    const deletedMapping = mappingsAsArray.find((item) => _.isEqual(item.mapping, payload));

    if (!deletedMapping) {
      return;
    }

    const deletedMappingId = deletedMapping.mappingId;

    removeMappingById(deletedMappingId);
    updateChannelRatePlans({ id: deletedMappingId }, null);

    const isListingOnlyMapping = payload.listing_id && !payload.airbnb_rate_plan_id;

    const updatedMappingOptions = resetListingProcessingStatus(mappingOptionsRef.current, deletedMapping.mapping.listing_id, isListingOnlyMapping);
    onMappingOptionsChange(updatedMappingOptions);
  };

  const handleMappingDelete = (mappingId) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);
    const deletedMapping = mappingsAsArray.find((mapping) => _.isEqual(mapping.mappingId, mappingId));

    const isListingOnlyMapping = deletedMapping.mapping.listing_id && !deletedMapping.mapping.airbnb_rate_plan_id;

    const updatedMappingOptions = setListingProcessingStatus(mappingOptionsRef.current, deletedMapping.mapping.listing_id, isListingOnlyMapping);
    onMappingOptionsChange(updatedMappingOptions);

    return Channels.deleteMapping(channelId, mappingId)
      .catch(handleActionError);
  };

  const handleListingDisconnect = (listingId) => {
    return Channels.airbnbDisconnectListing(channelId, listingId)
      .then(() => {
        const updatedMappingOptions = resetListingProcessingStatus(mappingOptionsRef.current, listingId);
        onMappingOptionsChange(updatedMappingOptions);
      })
      .catch(handleActionError);
  };

  useEffect(() => {
    const subscription = subscribe("airbnb:mapping_removed", handleMappingRemovedEvent);

    return () => {
      subscription.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toolbar = useMemo(() => {
    const total = getListings(mappingOptions).length;

    return (
      <>
        <InputSearch value={searchQuery} total={total} onChange={setSearchQuery} />
        <RefreshButton onClick={handleRefresh} />
      </>
    );
  }, [mappingOptions, searchQuery, handleRefresh]);

  if (mappingStatus === null) {
    return <FormMessage message={t("channels_page:form:message:fill_settings")} />;
  }

  if (mappingStatus === "error") {
    return <FormMessage icon="api" message={t("channels_page:form:message:service_unavailable")} />;
  }

  if (mappingStatus === "loaded" && selectedProperties.length === 0) {
    return <FormMessage message={t("channels_page:form:message:choose_property")} />;
  }

  return (
    <Layouts.WithToolbar toolbar={toolbar} loading={loading}>
      <ReverseMapping
        channelTitle={channelTitle}
        mappings={form.values.mappings}
        channel={form.values.channel}
        searchQuery={searchQuery}
        ratesTree={ratesTree}
        mappingOptions={availableMappingOptions}
        orderedMappingSchema={[...orderedMappingSchema]}
        ratePlans={ratePlans}
        blockedListings={blockedListings}
        mappingSettings={mappingSettings}
        onMappingCreate={handleMappingCreate}
        onMappingDelete={handleMappingDelete}
      />
      {!loading && (
        <KnownMappings
          knownMappings={knownMappings}
          mappingOptions={mappingOptions}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          onDelete={onKnownMappingsDelete}
        />
      )}
      <BlockedListings blockedListings={blockedListings} onDisconnect={handleListingDisconnect} />
    </Layouts.WithToolbar>
  );
}
