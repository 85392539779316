import React from "react";
import PropTypes from "prop-types";
import { MenuOutlined } from "@ant-design/icons";

import styles from "./logo.module.css";

function Logo({ handleToggleMenu }) {
  return (
    <div className={styles.logo__Wrapper} onClick={handleToggleMenu}>
      <img
        src="/assets/logo_small.svg"
        className={styles.logo}
        alt="Logo"
      />
      <MenuOutlined className={styles.toggle} />
    </div>
  );
}

Logo.propTypes = {
  handleToggleMenu: PropTypes.func,
};

export default Logo;
