import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import RateModificator from "components/forms/inputs/rate_modificator";
import ChooseRoomTypeMessage from "components/forms/items/choose_room_type_message";
import InputDerivedRate from "components/forms/items/input_derived_rate";
import InputPrimaryOccupancy from "components/forms/items/input_primary_occupancy";

function onAddModificator(model, el, onChange) {
  return (event) => {
    event.preventDefault();

    onChange(
      "options",
      model.options.map((option) => {
        if (option.occupancy === el.occupancy) {
          return {
            ...option,
            derived_option: {
              ...option.derived_option,
              rate: [...option.derived_option.rate, []],
            },
          };
        }

        return option;
      }),
    );
  };
}

function onChangeModificator(model, el, onChange) {
  return (index, column) => {
    return (value) => {
      const derivedOption = el.derived_option;
      const rate = derivedOption.rate;
      rate[index][column] = value;

      onChange(
        "options",
        model.options.map((option) => {
          if (option.occupancy === el.occupancy) {
            return {
              ...option,
              derived_option: derivedOption,
            };
          }

          return option;
        }),
      );
    };
  };
}

function onDropModificator(model, el, onChange) {
  return (index) => {
    return (event) => {
      event.preventDefault();
      const { derived_option: derivedOption } = el;
      const updatedRate = [...derivedOption.rate];
      updatedRate[index] = null;

      const updatedDerivedOption = {
        ...derivedOption,
        rate: updatedRate,
      };

      onChange(
        "options",
        model.options.map((option) => {
          if (option.occupancy === el.occupancy) {
            return {
              ...option,
              derived_option: updatedDerivedOption,
            };
          }

          return option;
        }),
      );
    };
  };
}

function rateOptionTemplate(args) {
  return (el) => {
    const { model, t, onChange } = args;

    const secondArgs = {
      t,
      modificators: el.derived_option.rate,
      onAddModificator: onAddModificator(model, el, onChange),
      onChangeModificator: onChangeModificator(model, el, onChange),
      onDropModificator: onDropModificator(model, el, onChange),
    };

    let result;

    if (model.primary_occupancy === el.occupancy) {
      result = (
        <Form.Item
          {...horizontalFormItemLayout}
          key={el.occupancy}
          label={t("rates_page:form:rate_logic_for", { count: el.occupancy })}
        >
          {t("rates_page:form:primary_occupancy_label")}
        </Form.Item>
      );
    } else {
      result = (
        <Form.Item
          {...horizontalFormItemLayout}
          key={el.occupancy}
          label={t("rates_page:form:rate_logic_for", { count: el.occupancy })}
        >
          <RateModificator {...secondArgs} />
        </Form.Item>
      );
    }

    return result;
  };
}

function optionSorter(optionA, optionB) {
  return optionA.occupancy - optionB.occupancy;
}

function GroupPerPersonDerivedRate(props) {
  const { t, model, hideRateInput } = props;
  const renderRateOption = rateOptionTemplate({ ...props });

  let result = <ChooseRoomTypeMessage t={t} />;

  if (model.room_type_id) {
    const options = model.options.sort(optionSorter).map(renderRateOption);

    const rateInput = model.inherit_rate && model.is_derived && <InputDerivedRate {...props} />;

    result = (
      <>
        <InputPrimaryOccupancy {...props} />
        {hideRateInput ? null : rateInput}
        {options}
      </>
    );
  }

  return result;
}

GroupPerPersonDerivedRate.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupPerPersonDerivedRate;
