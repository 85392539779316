import { useState } from "react";

export default function useDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState(null);

  return {
    isOpen,
    payload,
    open: (newPayload) => {
      setIsOpen(true);

      if (newPayload) {
        setPayload(newPayload);
      }
    },
    close: () => {
      setIsOpen(false);
      setPayload(null);
    },
  };
}
