import * as Sentry from "@sentry/react";

import extractRelationships from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "channels";

export default class Channels {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = {}) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.channelsLoad(response.data);

      return response;
    });
  }

  health(filter = {}, pagination = {}, order = {}) {
    return transport
      .send("GET", `${ENDPOINT}/health`, { filter, pagination, order })
      .then((response) => {
        storage.channelsHealthLoad(response.data, response.meta);
        return response;
      });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      try {
        if (response.data.attributes.channel === "AirBNB" && response.data.attributes.settings.rate_plans_enabled) {
          const airbnbRatePlanId = response.data.attributes.settings.mappingSettings.orderedMappingSchema.find((item) => {
            return item.id === "airbnb_rate_plan_id";
          });

          if (!airbnbRatePlanId) {
            response.data.attributes.settings.mappingSettings.orderedMappingSchema.push({
              id: "airbnb_rate_plan_id",
              title: "Airbnb Rate Plan",
              type: "integer",
            });
          }
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      storage.channelsAdd(response.data);
      return response;
    });
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { channel: attrs }).then((response) => {
      storage.channelsAdd(response.data);

      return response;
    });
  }

  update(attrs) {
    return transport.send("PUT", `${ENDPOINT}/${attrs.id}`, { channel: attrs }).then((response) => {
      storage.channelsAdd(response.data);

      return response;
    });
  }

  activate(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/activate`).then(() => {
      return storage.channelsActivate(id);
    });
  }

  deactivate(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/deactivate`).then(() => {
      return storage.channelsDeactivate(id);
    });
  }

  full_sync(channel_id) {
    return transport.send("POST", `${ENDPOINT}/${channel_id}/full_sync`, {}).then((response) => {
      return response;
    });
  }

  remove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}`).then((response) => {
      storage.channelsDrop(attrs);

      return response;
    });
  }

  availableToConnect() {
    return transport.send("GET", `${ENDPOINT}/list`).then((response) => {
      return response;
    });
  }

  getMappingDetails(attrs) {
    return transport.send("POST", `${ENDPOINT}/mapping_details`, attrs).then((response) => {
      return response;
    });
  }

  getConnectionsDetails(attrs) {
    return transport.send("POST", `${ENDPOINT}/connection_details`, attrs).then((response) => {
      return response.data;
    });
  }

  testConnection(attrs) {
    return transport.send("POST", `${ENDPOINT}/test_connection`, attrs).then((response) => {
      return response;
    });
  }

  checkReadiness(channelId) {
    return transport.send("POST", `${ENDPOINT}/${channelId}/check_readiness`).then(({ data }) => {
      return data;
    });
  }

  airbnbPublishListing(channelId, listing_id) {
    return transport
      .send("PUT", `${ENDPOINT}/${channelId}/execute/publish`, { listing_id })
      .then((response) => {
        return response.data;
      });
  }

  airbnbUnpublishListing(channelId, listing_id) {
    return transport
      .send("PUT", `${ENDPOINT}/${channelId}/execute/unpublish`, { listing_id })
      .then((response) => {
        return response.data;
      });
  }

  airbnbUpdateListingPricing(channelId, attrs) {
    return transport
      .send("PUT", `${ENDPOINT}/${channelId}/execute/update_pricing_setting`, attrs)
      .then((response) => {
        return response.data;
      });
  }

  airbnbLoadListingPricing(channelId, attrs) {
    return transport
      .send("POST", `${ENDPOINT}/${channelId}/execute/load_listing_price_settings`, attrs)
      .then(({ data }) => {
        return data;
      });
  }

  airbnbUpdateListingAvailability(channelId, attrs) {
    return transport
      .send("PUT", `${ENDPOINT}/${channelId}/execute/update_availability_rule`, attrs)
      .then((response) => {
        return response.data;
      });
  }

  airbnbLoadListingAvailability(channelId, attrs) {
    return transport
      .send("POST", `${ENDPOINT}/${channelId}/execute/load_listing_availability_rule`, attrs)
      .then(({ data }) => {
        return data;
      });
  }

  airbnbSyncListing(channelId, listingId) {
    return transport.send("POST", `meta/airbnb/${channelId}/full_sync_listing`, {
      listing_id: listingId,
    });
  }

  airbnbDisconnectListing(channelId, listingId) {
    return transport.send("POST", `meta/airbnb/${channelId}/disconnect_listing`, {
      listing_id: listingId,
    });
  }

  airbnbGetChannelRatePlan(channelRatePlanId) {
    return transport.send("GET", `channel_rate_plans/${channelRatePlanId}`).then((response) => {
      return response.data;
    });
  }

  airbnbGetOpportunities(channelId) {
    return transport
      .send("GET", `channels/${channelId}/action/get_opportunities`)
      .then((response) => {
        return response.data;
      });
  }

  airbnbInstallOpportunity(channelId, attrs) {
    return transport
      .send("POST", `channels/${channelId}/action/apply_opportunity`, attrs)
      .then((response) => {
        return response.data;
      });
  }

  airbnbCancelBooking(channelId, attrs) {
    return transport
      .send("POST", `channels/${channelId}/action/cancel_reservation`, attrs)
      .then((response) => {
        return response.data;
      });
  }

  airbnbEnableRatePlans(channelId) {
    return transport
      .send("POST", `channels/${channelId}/execute/enable_rate_plans`);
  }

  airbnbDisableRatePlans(channelId) {
    return transport
      .send("POST", `channels/${channelId}/execute/disable_rate_plans`);
  }

  removeKnownMapping(id) {
    return transport.send("DELETE", `known_mappings/${id}`).then((response) => {
      return response;
    });
  }

  createMapping(channelId, mapping) {
    return transport
      .send("POST", `${ENDPOINT}/${channelId}/mappings`, { mapping })
      .then(({ data }) => {
        return extractRelationships(data);
      });
  }

  updateMapping(channelId, mappingId, mapping) {
    return transport
      .send("PUT", `${ENDPOINT}/${channelId}/mappings/${mappingId}`, { mapping })
      .then(({ data }) => {
        return extractRelationships(data);
      });
  }

  deleteMapping(channelId, mappingId) {
    return transport.send("DELETE", `${ENDPOINT}/${channelId}/mappings/${mappingId}`);
  }

  vrboAuthenticate(attrs) {
    return transport.send("POST", "meta/vrbo/authenticate", attrs).then((response) => {
      return response.data;
    });
  }

  vrboRequestAuthCode(attrs) {
    return transport.send("POST", "meta/vrbo/request_mfa_code", attrs).then((response) => {
      return response.data;
    });
  }

  vrboVerifyCode(attrs) {
    return transport.send("POST", "meta/vrbo/verify_mfa_code", attrs).then((response) => {
      return response.data;
    });
  }
}
