import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";

import downloadReport from "./methods/download_report";

import styles from "./usage.module.css";

export default function Usage({ billingAccountId, usage = {} }) {
  const { t } = useTranslation();

  return Object.keys(usage).map((key) => {
    const snakeCaseKey = _.snakeCase(key);
    return (
      <div className="ant-row" key={key}>
        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          <div className={styles.label}>{t(`user_profile_page:subscription:${snakeCaseKey}`)}</div>
        </div>
        <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
          <div>
            {usage[key]}{" "}
            <span onClick={downloadReport(billingAccountId, snakeCaseKey)} className={styles.link}>
              <DownloadOutlined /> {t("user_profile_page:subscription:download")}
            </span>
          </div>

          <div className="ant-form-item-extra">
            {t(`user_profile_page:subscription:${snakeCaseKey}_description`)}
          </div>
        </div>
      </div>
    );
  });
}
