import React from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import dayjs from "utils/dayjs";

import styles from "./error_message.module.css";

const DATE_FORMAT = "YYYY-MM-DD";
const UI_DATE_FORMAT = "DD MMM YYYY";

export default function ErrorMessage({ errors, ratePlansById }) {
  const { t } = useTranslation();
  const { rate_plan_id: rateId } = errors[0];

  const ratePlan = ratePlansById[rateId];

  // TODO: Remove this once we have a better understanding of why this is happening
  if (!ratePlan) {
    Sentry.captureEvent({
      message: "No rate plan found on inventory error modal",
      level: "error",
      extra: {
        rateId,
        ratePlansById,
      },
    });
  }

  const title = ratePlan?.title || "Unknown Rate Plan";

  return (
    <div className={styles.rateSection}>
      <div>{`${title} (${rateId}):`}</div>
      <div className={styles.errorSection}>
        {errors.map((error) => (
          <div key={error.date} className={styles.errorContainer}>
            <div className={styles.date}>
              {dayjs(error.date, DATE_FORMAT).format(UI_DATE_FORMAT)}:
            </div>
            <div>
              {Object.keys(error.warning).map((warningKey) => (
                <div key={warningKey}>
                  <span>{`${t(`inventory_page:error_modal:error_types:${warningKey}`)} - `}</span>
                  <span>{error.warning[warningKey].join(" ")}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
