import React from "react";
import { useTranslation } from "react-i18next";
import { ExportOutlined } from "@ant-design/icons";

import styles from "./subscription.module.css";

export default function Billsby({ subscription }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="ant-row">
        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          <div className={styles.label}>{t("billing_account_drawer:subscription:provider")}</div>
        </div>
        <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
          <div>{subscription.subscription_provider}</div>
        </div>
      </div>

      <div className="ant-row">
        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          <div className={styles.label}>{t("billing_account_drawer:subscription:plan")}</div>
        </div>
        <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
          <div>{subscription.plan}</div>
        </div>
      </div>

      <div className="ant-row">
        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          <div className={styles.label}>{t("billing_account_drawer:subscription:is_active")}</div>
        </div>
        <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
          <div>{subscription.is_active ? "TRUE" : "FALSE"}</div>
        </div>
      </div>

      <div className="ant-row">
        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          <div className={styles.label}>
            {t("billing_account_drawer:subscription:customer_unique_id")}
          </div>
        </div>
        <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
          <div>
            <a
              href={`https://channexio.billsby.com/customers/${subscription.subscription_settings.customer_unique_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {subscription.subscription_settings.customer_unique_id} <ExportOutlined />
            </a>
          </div>
        </div>
      </div>

      <div className="ant-row">
        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          <div className={styles.label}>
            {t("billing_account_drawer:subscription:subscription_unique_id")}
          </div>
        </div>
        <div className="ant-col ant-form-item-control ant-col-xs-24 ant-col-sm-16">
          <div>
            <a
              href={`https://channexio.billsby.com/subscriptions/${subscription.subscription_settings.subscription_unique_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {subscription.subscription_settings.subscription_unique_id} <ExportOutlined />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
