import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import classNames from "classnames";

import ApplicationLinks from "./components/application_links";
import NavigationItem from "./components/navigation_item";
import Overlay from "./components/overlay";
import LINKS from "./nav_links";

import styles from "./navigation.module.css";

function Navigation({ isOpened, onToggleMenu, onCloseMenu }) {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const classNameWrapper = classNames(styles.appNavigation, {
    [`${styles.appNavigation__opened}`]: isOpened,
  });

  const navLinks = [...LINKS];

  const toggleMobileMode = (isMobileMode) => (matches) => matches && setIsMobile(isMobileMode);

  return (
    <div className={classNameWrapper}>
      <Media query="(max-width: 930px)" onChange={toggleMobileMode(true)} />
      <Media query="(min-width: 931px)" onChange={toggleMobileMode(false)} />
      <img
        src="/assets/logo_small.svg"
        onClick={onToggleMenu}
        className={styles.appNavigation__Logo}
        alt="Logo"
      />
      {navLinks.map(([url, code]) => (
        <NavigationItem
          key={url}
          to={url}
          title={t(`general:links:${code}`)}
          onClick={onCloseMenu}
        />
      ))}
      <ApplicationLinks handleCloseMenu={onCloseMenu} isMobile={isMobile} />

      <Overlay onClick={onToggleMenu} />
    </div>
  );
}

export default Navigation;
