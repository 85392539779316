function extractRelationshipsFromEntity(model) {
  Object.keys(model.relationships || {}).forEach((key) => {
    const { data } = model.relationships[key];

    if (Array.isArray(data)) {
      model.attributes[key] = data
        .map((el) => el.attributes)
        .reduce((acc, el) => {
          acc[el.id] = el;
          return acc;
        }, {});
    } else {
      model.attributes[`${key}_id`] = data && data.id;
      model.attributes[key] = data;
    }
  });

  return model.attributes;
}

function extractRelationships(models) {
  let result;

  if (Array.isArray(models)) {
    result = models.reduce((acc, el) => {
      acc[el.id] = extractRelationshipsFromEntity(el);
      return acc;
    }, {});
  } else {
    result = extractRelationshipsFromEntity(models);
  }

  return result;
}

export default extractRelationships;
