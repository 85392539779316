import React from "react";

import DoubleBlind from "./double_blind";
import Regular from "./regular";

export default function Body({ review }) {
  const { attributes: { isHidden } } = review;

  const ReviewBody = isHidden ? DoubleBlind : Regular;

  return (
    <ReviewBody review={review} />
  );
}
