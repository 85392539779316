import React from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowLeftOutlined } from "@ant-design/icons";

import channelCodes from "config/constants/channels/channel_codes";

import BookingViewControlledDrawer from "drawers/booking_view_controlled_drawer";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import dayjs from "utils/dayjs";
import useBoolState from "utils/use_bool_state";

import HeaderActions from "./header_actions";

import styles from "./review.module.css";

const DATE_FORMAT = "Do MMM YYYY";

const buildBookingInfo = (t, booking) => {
  if (!booking) {
    return null;
  }

  const { arrival_date, departure_date, rooms, properties } = booking;
  const arrivalDate = dayjs(arrival_date);
  const departureDate = dayjs(departure_date);

  const formattedArrival = arrivalDate.format(DATE_FORMAT);
  const lengthOfStay = departureDate.diff(arrivalDate, "day");
  const propertyTitles = properties
    ? Object.values(properties)
      .map((property) => property.title)
      .join(", ")
    : "";

  const lengthOfStayMessage = t("reviews_page:dialog:length_of_stay", { amount: lengthOfStay });
  const roomNumberMessage = t("reviews_page:dialog:room_amount", { amount: rooms?.length });

  return `${t(
    "reviews_page:dialog:arriving",
  )}: ${formattedArrival}, ${roomNumberMessage}, ${lengthOfStayMessage} (${propertyTitles})`;
};

function Header({ isMobile, bookingLoading, bookingParams, review, archive }) {
  const { t } = useTranslation();
  const [isOpenBooking, setOpenBooking, setCloseBooking] = useBoolState(false);
  const { userAppRoutes } = useRouting();
  const { relationships, attributes } = review;
  const { guestName, ota, isReplied } = attributes;
  const { booking } = relationships;
  const path = pathBuilder(userAppRoutes.reviews);
  const { title: channelTitle, icon } = channelCodes[ota];
  const bookingInfo = buildBookingInfo(t, bookingParams);

  return (
    <>
      {isMobile && (
        <NavLink to={`${path}?is_active=${!isReplied}`} className={styles.backLink}>
          <ArrowLeftOutlined />
          {t("reviews_page:dialog:back_link")}
        </NavLink>
      )}
      <div className={styles.header}>
        <div>
          <div className={styles.headerTitleWrapper}>
            <p className={styles.headerTitle}>
              {guestName || t("reviews_page:dialog:unknown_guest")}
            </p>
            <Avatar
              name={channelTitle}
              size={24}
              src={icon}
              className={styles.headerProviderLogo}
              round
            />
            <p className={styles.headerProvider}>{channelTitle}</p>
          </div>
          {bookingLoading === true && <p className={styles.bookingInfo}>{t("general:loading")}</p>}
          {!bookingInfo && bookingLoading === false && <p className={styles.bookingInfo}>N/A</p>}
          {bookingInfo && bookingLoading === false && (
            <p className={styles.bookingInfo}>{bookingInfo || t("general:loading")}</p>
          )}
        </div>
        {bookingInfo && bookingLoading === false && (
          <HeaderActions handleOpenBooking={booking && setOpenBooking} archive={archive} />
        )}
      </div>
      {booking && (
        <BookingViewControlledDrawer
          bookingId={booking.data.id}
          visible={isOpenBooking}
          onClose={setCloseBooking}
        />
      )}
    </>
  );
}

Header.propTypes = {
  review: PropTypes.object,
};

export default Header;
