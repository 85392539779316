import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col, Row, Statistic, Typography } from "antd";

import styles from "./shared.module.css";

const { Title } = Typography;

function Replied({ review }) {
  const { t } = useTranslation();

  const {
    attributes: { content, reply, scores },
  } = review;

  return (
    <>
      <div className={styles.scores}>
        <Row justify="space-between">
          {scores.map((score) => (
            <Col span={3} key={score.category} className={styles.score}>
              <Statistic
                title={t(`reviews_page:scores:${score.category}`)}
                value={score.score}
                suffix="/ 10"
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className={styles.feedbackContent}>
        <Title level={4}>{t("reviews_page:feedback:guest_subheader")}</Title>
        <p className={styles.feedback}>{content || "N/A"}</p>
        <Title level={4}>{t("reviews_page:feedback:response_subheader")}</Title>
        <p className={styles.feedback}>{reply.reply || "N/A"}</p>
      </div>
    </>
  );
}

Replied.propTypes = {
  review: PropTypes.object,
};

export default Replied;
