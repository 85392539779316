import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation, useMatch } from "react-router-dom";
import classnames from "classnames";
import store from "store";
import { getReviews, getReviewsMeta } from "store/storage/selectors/reviews_selector";
import { getActiveProperty } from "store/storage/selectors/session_selector";

import defaultPaginationVars from "config/constants/default_pagination_variables";

import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import useRouting from "routing/use_routing";

import calculateLimitFromRef from "utils/calculate_limit_from_ref";
import useBoolState from "utils/use_bool_state";

import Header from "./header";
import List from "./list";

import styles from "./reviews.module.css";

const DEFAULT_CACHE_PROPS = {
  fixedWidth: true,
  defaultHeight: 72,
  minHeight: 72,
};

const DEFAULT_FILTER_VALUE = false;

const getFilterValueFormUrl = (search) => {
  const urlParams = new URLSearchParams(search);
  return search ? urlParams.get("is_replied") === "true" : DEFAULT_FILTER_VALUE;
};

const { Reviews } = store;

function ReviewsList({ isMobile }) {
  const listRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();
  const match = useMatch({ path: userAppRoutes.reviews.review._path, end: false });
  const [isRepliedMode, setIsRepliedMode] = useState(null);
  const [currentRequestedPage, setCurrentRequestedPage] = useState(null);
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(false);
  const reviewsList = useSelector(getReviews);
  const reviewsMeta = useSelector(getReviewsMeta);
  const activeProperty = useSelector(getActiveProperty);
  const { search, pathname } = location;
  const reviewId = match?.params?.reviewId;

  const loadReviewsList = useCallback(
    (nextPage) => {
      setTrueLoading();

      if (nextPage === currentRequestedPage || currentRequestedPage > nextPage) {
        return;
      }

      setCurrentRequestedPage(nextPage);
      const limit = calculateLimitFromRef(listRef, DEFAULT_CACHE_PROPS.minHeight);

      const filter = {
        is_replied: isRepliedMode,
      };

      if (activeProperty) {
        filter.property_id = activeProperty;
      }

      const meta = { ...defaultPaginationVars, page: nextPage, limit };

      Reviews.list(filter, meta, {}, isRepliedMode).finally(setFalseLoading);
    },
    [
      listRef,
      activeProperty,
      isRepliedMode,
      setTrueLoading,
      setFalseLoading,
      currentRequestedPage,
      setCurrentRequestedPage,
    ],
  );

  useEffect(
    function handleFilterChange() {
      if (isRepliedMode === null) {
        return;
      }

      loadReviewsList(1);
    },
    [isRepliedMode, loadReviewsList],
  );

  useEffect(
    function handlePropertyChange() {
      setCurrentRequestedPage(null);
      Reviews.reset();
    },
    [activeProperty],
  );

  const loadPreselectedReview = useCallback((id, isActive) => {
    if (!id) {
      return Promise.resolve(isActive);
    }

    return Reviews.review(id)
      .then((data) => {
        const {
          attributes: { isReplied },
        } = data;

        return isReplied;
      })
      .catch(() => {
        return isActive;
      });
  }, []);

  useEffect(
    function initReviews() {
      if (isRepliedMode !== null || loading) {
        return;
      }

      setTrueLoading();
      const isRepliedFilterValue = getFilterValueFormUrl(search);

      Reviews.reset();

      loadPreselectedReview(reviewId, isRepliedFilterValue).then((isReplied) => {
        navigate(`${pathname}?is_replied=${isReplied}`);

        setIsRepliedMode(isReplied);
        setFalseLoading();
      });
    },
    [
      isRepliedMode,
      reviewId,
      search,
      navigate,
      loading,
      pathname,
      setTrueLoading,
      setFalseLoading,
      loadPreselectedReview,
    ],
  );

  const onSetFilter = useCallback(
    (val) => {
      if (val === isRepliedMode) {
        return;
      }

      setCurrentRequestedPage(null);
      Reviews.reset();

      setIsRepliedMode(val);
      navigate(`/reviews?is_replied=${val}`);
    },
    [navigate, isRepliedMode],
  );

  const renderContent = useMemo(() => {
    if (!reviewsList || loading) {
      return (
        <div className={styles.center}>
          <Loading />
        </div>
      );
    }

    if (!reviewsList?.length) {
      return (
        <div className={styles.center}>
          <NoDataPlaceholder emptyMessage={t("general:no_data_placeholder")} />
        </div>
      );
    }

    return (
      <List
        reviewsList={reviewsList}
        meta={reviewsMeta}
        loading={loading}
        loadMore={loadReviewsList}
        defaultCacheProps={DEFAULT_CACHE_PROPS}
      />
    );
  }, [t, loading, reviewsList, reviewsMeta, loadReviewsList]);

  const isReviewsHidden = isMobile && reviewId;
  const containerClass = classnames(
    styles.wrapper,
    isMobile && styles.mobile,
    isReviewsHidden && styles.hidden,
  );

  return (
    <div className={containerClass}>
      <Header isRepliedMode={isRepliedMode} handleSetFilter={onSetFilter} />
      <div ref={listRef} className={styles.content}>
        {renderContent}
      </div>
    </div>
  );
}

export default ReviewsList;
